import _ from 'lodash'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Theme from 'shared'

import Header from './Header'
import Body from './Body'
import Footer from './Footer'

import { layout } from './Cells'
import Provider, { useTable } from './context'

const WrapperPartial = styled.div`
  width: 100%;
`

const Box = styled.div`
  border-radius: calc(${props => props.theme.sizes.edge} * 1);
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  width: 100%;
  overflow: hidden;

  ${props => props.theme.medias.medium} {
    border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
  }
`
const Wrapper = styled(WrapperPartial)``

function Table ({ data, ...props }) {
  return (
    <Provider>
      <Content {...props} data={data} />
    </Provider>
  )
}

function Content ({ className, data, children }) {
  const { setData } = useTable()

  useEffect(() => {
    setData(data)
  }, [data, setData])

  return (
    <Wrapper
      className={className}
      data-component='table'
      data-theme={_.get(data, 'theme')}
    >
      <Box data-component='box'>
        <Header />
        <Body />
        <Footer />
        {children}
      </Box>
    </Wrapper>
  )
}

Table.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.any.isRequired,
        subtitle: PropTypes.any,
        weight: PropTypes.number,
        help: PropTypes.string,
        id: PropTypes.string
      })
    ),
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        cells: PropTypes.arrayOf(PropTypes.shape({}))
      })
    ),
    instructions: PropTypes.shape({
      onRowClick: PropTypes.func
    }),
    footer: PropTypes.oneOf(['more', 'more-loading', null]),
    theme: PropTypes.oneOf(Object.values(Theme.constants.table.theme)),
    isLoading: PropTypes.bool,
    expected: PropTypes.number,
    empty: PropTypes.number
  })
}

Table.defaultProps = {
  data: {
    columns: [],
    rows: [],
    instructions: {
      onRowClick: () => {}
    },
    footer: null,
    theme: Theme.constants.table.theme.classic,
    isLoading: true,
    empty: 0,
    expected: 1
  }
}

export { layout }
export default Table
