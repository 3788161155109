import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import IconWarning from '@material-ui/icons/WarningRounded'
import { useModal } from '../../../hooks'
import { modals } from '../../../constants'

const WrapperPartial = styled.div`
  position: relative;
  margin-right: calc(${props => props.theme.sizes.edge} * 1 / 2);
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: auto;
  height: 38px;
  width: 38px;
  border-radius: 19px;
  background-color: ${props => rgba(props.theme.colors.middle, 0.1)};
  border: 1px solid ${props => rgba(props.theme.colors.middle, 0.9)};
  color: ${props => rgba(props.theme.colors.middle, 0.9)};
  cursor: pointer;

  & > svg {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13pt;
  }

  &:hover,
  &:active {
    background-color: ${props => rgba(props.theme.colors.middle, 0.15)};
  }
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.medium} {
    pointer-events: none;
    position: relative;
    ${Box} {
      background-color: ${props => props.theme.colors.middle};
      position: absolute;
      z-index: 100;
      top: -40px;
      right: -70px;
      transform: scale(0.7);

      & > svg {
        color: ${props => props.theme.colors.white};
      }
      &:hover,
      &:active {
        background-color: ${props => props.theme.colors.middle};
      }
    }
  }
`

function Notice () {
  const { setOpen } = useModal(modals.risk)

  return (
    <Wrapper>
      <Box onClick={() => setOpen(true)}>
        <IconWarning />
      </Box>
    </Wrapper>
  )
}

export default Notice
