import React from 'react'
import styled from 'styled-components'
import Section from '../../../shared/Section'
import RestrictedNetwork from '../RestrictedNetwork'

const Content = styled.div`
  width: 100%;
`

function Hedge () {
  return (
    <Section
      title='Buy Options'
      description='Buy European, fully collateralized options and hedge your portfolio.'
    >
      <Content>
        <RestrictedNetwork />
      </Content>
    </Section>
  )
}

export default Hedge
