import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import IconSettings from '@material-ui/icons/SettingsRounded'
import { Button } from 'shared'
import {
  Card as CardPartial,
  Title as TitlePartial,
  Content as ContentPartial
} from '../atoms'

const Card = styled(CardPartial)``

const Title = styled(TitlePartial)`
  display: flex;
  align-items: center;

  & > svg {
    font-size: 13pt;
    color: ${props => props.theme.colors.dark};
    margin-right: 6px;
  }
`

const Content = styled(ContentPartial)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  flex: 1;
  padding-bottom: 16px;
  & > * {
    margin-bottom: 12px;
    &:last-child {
      margin: 0;
    }
  }
`

const ActionButton = styled(Button)`
  padding-top: 14px;
  padding-bottom: 14px;
  p {
    font-size: 10pt !important;
  }
`

function Actions ({ handleOnSave, handleOnRefresh }) {
  return (
    <Card>
      <Title>
        <IconSettings />
        Actions
      </Title>
      <Content>
        <ActionButton
          type={t => t.button}
          accent={a => a.dark}
          appearance={a => a.outline}
          title='Reset and Close'
          onClick={handleOnRefresh}
          isFullWidth
        />
        <ActionButton
          type={t => t.button}
          accent={a => a.primary}
          appearance={a => a.gradient}
          title={
            !_.isNil(handleOnSave) ? 'Save and Filter' : 'Configure filters'
          }
          onClick={!_.isNil(handleOnSave) ? handleOnSave : () => {}}
          isDisabled={_.isNil(handleOnSave)}
          isFullWidth
        />
      </Content>
    </Card>
  )
}

export default Actions
