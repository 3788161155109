import _ from 'lodash'
import BigNumber from 'bignumber.js'
import { macros } from '../constants'
import {
  OptionAMMFactoryABI,
  OptionAMMPoolABI,
  ERC20ABI,
  OptionHelperABI,
  ConfigurationManagerABI,
  CapProviderABI
} from '../contracts'
import { NOOP, getAddresses, zeroIfNaN } from '../utils'

/**
 * The constant values of an option pool
 * @typedef {object} OptionPoolData
 * @property {string} tokenA
 * @property {number} tokenADecimals
 * @property {string} tokenASymbol
 * @property {string} tokenB
 * @property {number} tokenBDecimals
 * @property {string} tokenBSymbol
 */

/**
 * Class representing a OptionPool
 * @class
 */
export default class OptionPool {
  /**
   * The address where the option is deployed
   * @type {string}
   * @private
   */
  #address

  /**
   * The constant values of an option pool
   * @type {OptionPoolData}
   * @private
   */
  #data = {}

  /**
   * Instance of web3
   * @type {Web3}
   * @private
   */
  #web3

  /**
   * Instance of Pool contract
   * @type {Contract}
   * @private
   */
  #pool

  /**
   * Instance of OptionHelper contract
   * @type {Contract}
   * @private
   */
  #helper

  /**
   * Instance of Option contract
   * @type {Contract}
   * @private
   */
  #option

  constructor (address, web3, option) {
    this.#address = address
    this.#web3 = web3
    this.#option = option
  }

  get address () {
    return this.#address
  }

  get tokenA () {
    return this.#data.tokenA
  }

  get tokenADecimals () {
    return this.#data.tokenADecimals
  }

  get tokenASymbol () {
    return this.#data.tokenASymbol
  }

  get tokenB () {
    return this.#data.tokenB
  }

  get tokenBDecimals () {
    return this.#data.tokenBDecimals
  }

  get tokenBSymbol () {
    return this.#data.tokenBSymbol
  }

  get priceProperties () {
    return this.#data.priceProperties
  }

  get cap () {
    return this.#data.cap
  }

  get supply () {
    return this.#data.supply
  }

  /**
   * Creates an instance of a option pool for an option
   * @param {Option} option
   * @param {Web3} web3
   * @return {Promise<OptionPool>}
   */
  static async fromOption (option, web3) {
    const networkId = await web3.eth.net.getId()
    const { optionAMMFactory } = getAddresses(networkId)
    const factory = new web3.eth.Contract(OptionAMMFactoryABI, optionAMMFactory)
    const poolAddress = await factory.methods.getPool(option.address).call()

    if (web3.utils.hexToNumberString(poolAddress) === '0') {
      return null
    }

    const pool = new OptionPool(poolAddress, web3, option)
    return pool.init()
  }

  async init () {
    const { Contract } = this.#web3.eth
    const networkId = await this.#web3.eth.net.getId()
    const { optionHelper } = getAddresses(networkId)
    this.#pool = new Contract(OptionAMMPoolABI, this.#address)
    this.#helper = new Contract(OptionHelperABI, optionHelper)

    const [tokenA, tokenADecimals, tokenB, tokenBDecimals] = await Promise.all([
      this.#pool.methods.tokenA().call(),
      this.#pool.methods
        .tokenADecimals()
        .call()
        .then(v => parseInt(v)),
      this.#pool.methods.tokenB().call(),
      this.#pool.methods
        .tokenBDecimals()
        .call()
        .then(v => parseInt(v))
    ])

    const [tokenASymbol, tokenBSymbol] = await Promise.all([
      new Contract(ERC20ABI, tokenA).methods.symbol().call(),
      new Contract(ERC20ABI, tokenB).methods.symbol().call()
    ])

    const priceProperties = await this.#pool.methods.priceProperties().call()

    this.#data = {
      tokenA,
      tokenADecimals,
      tokenASymbol,
      tokenB,
      tokenBDecimals,
      tokenBSymbol,
      priceProperties,
      cap: null,
      supply: null
    }

    this.#web3.eth.defaultAccount = await this._getDefaultAccount()

    this.#data.cap = await this.getCapSize()
    this.#data.supply = _.get(await this.getPoolLiquidity(), '[1]')

    return this
  }

  /**
   * Return the current amount of tokenA and tokenB deposited to a pool
   * @return {Promise<[number, number]>}
   */
  async getPoolLiquidity () {
    const [amountA, amountB] = Object.values(
      await this.#pool.methods.getPoolBalances().call()
    )

    return [
      zeroIfNaN(new BigNumber(amountA).dividedBy(10 ** this.tokenADecimals)),
      zeroIfNaN(new BigNumber(amountB).dividedBy(10 ** this.tokenBDecimals))
    ]
  }

  /**
   * Returns the current position => amount of tokenA and tokenB provided by the specific address to the pool
   * @param {string} wallet
   * @return {Promise<[number, number]>}
   */
  async getPoolPosition (wallet) {
    const [amountA, amountB] = Object.values(
      await this.#pool.methods
        .getRemoveLiquidityAmounts(
          new BigNumber(100).toString(),
          new BigNumber(100).toString(),
          wallet
        )
        .call()
    )

    return [
      new BigNumber(amountA || 0)
        .dividedBy(10 ** this.tokenADecimals)
        .toNumber(),
      new BigNumber(amountB || 0)
        .dividedBy(10 ** this.tokenBDecimals)
        .toNumber()
    ]
  }

  /**
   * Adds liquidity to a pool
   * @param {number} option
   * @param {number} amountA
   * @param {number} amountB
   * @param {function} callback
   * @return {Promise<void>}
   */
  async addLiquidity (option, amountA, amountB, callback = NOOP) {
    const amountABN = new BigNumber(amountA).multipliedBy(
      10 ** this.tokenADecimals
    )
    const amountBBN = new BigNumber(amountB).multipliedBy(
      10 ** this.tokenBDecimals
    )
    const owner = this.#web3.eth.defaultAccount

    await this.#helper.methods
      .addLiquidity(
        option,
        amountABN.toFixed(0).toString(),
        amountBBN.toFixed(0).toString()
      )
      .send(
        {
          from: owner
        },
        callback
      )
  }

  /**
   * Removes the liquidity provided to a pool
   * @param {number} precentA
   * @param {number} precentB
   * @param {function} callback
   * @return {Promise<void>}
   */
  async removeLiquidity (precentA, precentB, callback = NOOP) {
    const A = new BigNumber(precentA).toFixed(0).toString()
    const B = new BigNumber(precentB).toFixed(0).toString()

    const owner = this.#web3.eth.defaultAccount

    await this.#pool.methods.removeLiquidity(A, B).send(
      {
        from: owner
      },
      callback
    )
  }

  async getPriceProperties () {
    return this.#pool.methods.priceProperties().call()
  }

  /**
   * Fetch the token price of an exact amount of options bought
   * @param {number} optionAmount
   * @return {Promise<number>} Token amount
   */
  async getBuyingPrice (optionAmount) {
    try {
      const output = new BigNumber(optionAmount).multipliedBy(
        10 ** this.tokenADecimals
      )

      const {
        0: price,
        feesTokenA,
        feesTokenB
      } = await this.#pool.methods
        .getOptionTradeDetailsExactAOutput(output.toFixed(0).toString())
        .call()
      const value = new BigNumber(price).div(10 ** this.tokenBDecimals)

      const fees = new BigNumber(feesTokenA)
        .plus(new BigNumber(feesTokenB))
        .div(10 ** this.tokenBDecimals)

      if (new BigNumber(price).isZero()) throw new Error()

      return {
        price: value.toNumber(),
        fees: fees.toNumber()
      }
    } catch (error) {
      return {
        price: macros.RESTRICTED_PREMIUM,
        fees: null
      }
    }
  }

  /**
   * Fetch the token price of an exact amount of options sold
   * @param {number} optionAmount
   * @return {Promise<number>} Token amount
   */
  async getSellingPrice (optionAmount) {
    try {
      const input = new BigNumber(optionAmount).multipliedBy(
        10 ** this.tokenADecimals
      )
      const {
        0: price,
        feesTokenA,
        feesTokenB
      } = await this.#pool.methods
        .getOptionTradeDetailsExactAInput(input.toFixed(0).toString())
        .call()

      const value = new BigNumber(price).div(10 ** this.tokenBDecimals)
      const fees = new BigNumber(feesTokenA)
        .plus(new BigNumber(feesTokenB))
        .div(10 ** this.tokenBDecimals)

      if (new BigNumber(price).isZero()) throw new Error()

      return {
        price: value.toNumber(),
        fees: fees.toNumber()
      }
    } catch (error) {
      return {
        price: macros.RESTRICTED_PREMIUM,
        fees: null
      }
    }
  }

  /**
   * Fetch the price (theoretical BS) for 1 unit of option
   * @return {Promise<number>}
   */
  async getABPrice () {
    try {
      const price = await this.#pool.methods.getABPrice().call()

      const value = new BigNumber(price).div(10 ** this.tokenBDecimals)

      if (new BigNumber(price).isZero()) throw new Error()

      return {
        price: value.toNumber()
      }
    } catch (error) {
      return {
        price: macros.RESTRICTED_PREMIUM
      }
    }
  }

  /**
   * Fetch an estimation of options bought for a exact amount of tokens
   * @param {number} tokenAmount
   * @return {Promise<number>} Option amount
   */
  async getEstimatedBoughtOptions (tokenAmount) {
    try {
      const input = new BigNumber(tokenAmount).multipliedBy(
        10 ** this.tokenBDecimals
      )

      const {
        0: price,
        feesTokenA,
        feesTokenB
      } = await this.#pool.methods
        .getOptionTradeDetailsExactBInput(input.toFixed(0).toString())
        .call()
      const value = new BigNumber(price).div(10 ** this.tokenADecimals)
      const fees = new BigNumber(feesTokenA)
        .plus(new BigNumber(feesTokenB))
        .div(10 ** this.tokenBDecimals)

      if (new BigNumber(price).isZero()) throw new Error()

      return {
        price: value.toNumber(),
        fees: fees.toNumber()
      }
    } catch (error) {
      return {
        price: macros.RESTRICTED_PREMIUM,
        fees: null
      }
    }
  }

  /**
   * Fetch an estimation of options sold for a exact amount of tokens
   * @param {number} tokenAmount
   * @return {Promise<number>} Option amount
   */
  async getEstimatedSoldOptions (tokenAmount) {
    try {
      const output = new BigNumber(tokenAmount).multipliedBy(
        10 ** this.tokenBDecimals
      )

      const {
        0: price,
        feesTokenA,
        feesTokenB
      } = await this.#pool.methods
        .getOptionTradeDetailsExactBOutput(output.toFixed(0).toString())
        .call()
      const value = new BigNumber(price).div(10 ** this.tokenADecimals)
      const fees = new BigNumber(feesTokenA)
        .plus(new BigNumber(feesTokenB))
        .div(10 ** this.tokenBDecimals)

      if (new BigNumber(price).isZero()) throw new Error()
      return {
        price: value.toNumber(),
        fees: fees.toNumber()
      }
    } catch (error) {
      return {
        price: macros.RESTRICTED_PREMIUM,
        fees: null
      }
    }
  }

  /**
   * Swap tokens for a exact amount of options
   * @param {number} optionAmount
   * @param {number} maxTokenAmount
   * @param {number} [deadline]
   * @return {Promise<void>}
   */
  async buyExactOptions (
    optionAmount,
    maxTokenAmount,
    deadline = null,
    callback = NOOP
  ) {
    if (!deadline) {
      const currentBlock = await this.#web3.eth.getBlock('latest')
      deadline = currentBlock.timestamp + macros.DEFAULT_TIMEOUT
    }
    const input = new BigNumber(maxTokenAmount).multipliedBy(
      10 ** this.tokenBDecimals
    )
    const output = new BigNumber(optionAmount).multipliedBy(
      10 ** this.tokenADecimals
    )
    const owner = this.#web3.eth.defaultAccount

    const {
      1: iv
    } = await this.#pool.methods
      .getOptionTradeDetailsExactAOutput(output.toFixed(0).toString())
      .call()

    await this.#helper.methods
      .buyExactOptions(
        this.tokenA,
        output.toFixed(0).toString(),
        input.toFixed(0).toString(),
        new BigNumber(deadline).toFixed(0).toString(),
        new BigNumber(iv).toFixed(0).toString()
      )
      .send(
        {
          from: owner
        },
        callback
      )
  }

  /**
   * Swap an exact amount of tokens for options
   * @param {number} tokenAmount
   * @param {number} minOptionAmount
   * @param {number} [deadline]
   * @param {function} [callback]
   * @return {Promise<void>}
   */
  async buyEstimatedOptions (
    tokenAmount,
    minOptionAmount,
    deadline = null,
    callback = NOOP
  ) {
    if (!deadline) {
      const currentBlock = await this.#web3.eth.getBlock('latest')
      deadline = new BigNumber(currentBlock.timestamp)
        .plus(macros.DEFAULT_TIMEOUT)
        .toString()
    }

    const input = new BigNumber(tokenAmount).multipliedBy(
      10 ** this.tokenBDecimals
    )

    const output = new BigNumber(minOptionAmount).multipliedBy(
      10 ** this.tokenADecimals
    )

    const owner = this.#web3.eth.defaultAccount

    const { 1: iv } = await this.#pool.methods
      .getOptionTradeDetailsExactBInput(input.toFixed(0).toString())
      .call()

    await this.#helper.methods
      .buyOptionsWithExactTokens(
        this.tokenA,
        output.toFixed(0).toString(),
        input.toFixed(0).toString(),
        new BigNumber(deadline).toFixed(0).toString(),
        new BigNumber(iv).toFixed(0).toString()
      )
      .send(
        {
          from: owner
        },
        callback
      )
  }

  /**
   * Mints an exact amount of options and then sell for tokens
   * @param {number} optionAmount
   * @param {number} minTokenAmount
   * @param {number} [deadline]
   * @param {function} callback
   * @return {Promise<void>}
   */
  async mintAndSellExactOptions (
    optionAmount,
    minTokenAmount,
    deadline = null,
    callback = NOOP
  ) {
    if (!deadline) {
      const currentBlock = await this.#web3.eth.getBlock('latest')
      deadline = currentBlock.timestamp + macros.DEFAULT_TIMEOUT
    }
    const input = new BigNumber(optionAmount).multipliedBy(
      10 ** this.tokenADecimals
    )
    const output = new BigNumber(minTokenAmount).multipliedBy(
      10 ** this.tokenBDecimals
    )
    const owner = this.#web3.eth.defaultAccount

    const { 1: iv } = await this.#pool.methods
      .getOptionTradeDetailsExactAInput(input.toFixed(0).toString())
      .call()

    await this.#helper.methods
      .mintAndSellOptions(
        this.tokenA,
        input.toFixed(0).toString(),
        output.toFixed(0).toString(),
        new BigNumber(deadline).toFixed(0).toString(),
        new BigNumber(iv).toFixed(0).toString()
      )
      .send(
        {
          from: owner
        },
        callback
      )
  }

  /**
   * Mints an exact amount of options
   * @param {number} optionAmount
   * @param {number} [deadline]
   * @param {function} callback
   * @return {Promise<any>}
   */
  async mintExactOptions (optionAmount, deadline = null, callback = NOOP) {
    if (!deadline) {
      const currentBlock = await this.#web3.eth.getBlock('latest')
      deadline = currentBlock.timestamp + macros.DEFAULT_TIMEOUT
    }
    const input = new BigNumber(optionAmount).multipliedBy(
      10 ** this.tokenADecimals
    )
    const owner = this.#web3.eth.defaultAccount

    return this.#helper.methods
      .mint(this.tokenA, input.toFixed(0).toString())
      .send(
        {
          from: owner
        },
        callback
      )
  }

  /**
   * Mints an exact amount of options and then sell for tokens
   * @param {number} optionAmount
   * @param {number} minTokenAmount
   * @param {number} [deadline]
   * @param {function} callback
   * @return {Promise<void>}
   */
  async sellExactOptions (
    optionAmount,
    minTokenAmount,
    deadline = null,
    callback = NOOP
  ) {
    if (!deadline) {
      const currentBlock = await this.#web3.eth.getBlock('latest')
      deadline = currentBlock.timestamp + macros.DEFAULT_TIMEOUT
    }
    const input = new BigNumber(optionAmount).multipliedBy(
      10 ** this.tokenADecimals
    )
    const output = new BigNumber(minTokenAmount).multipliedBy(
      10 ** this.tokenBDecimals
    )
    const owner = this.#web3.eth.defaultAccount

    const { 1: iv } = await this.#pool.methods
      .getOptionTradeDetailsExactAInput(input.toFixed(0).toString())
      .call()

    await this.#helper.methods
      .sellExactOptions(
        this.tokenA,
        input.toFixed(0).toString(),
        output.toFixed(0).toString(),
        new BigNumber(deadline).toFixed(0).toString(),
        new BigNumber(iv).toFixed(0).toString()
      )
      .send(
        {
          from: owner
        },
        callback
      )
  }

  async getCapSize () {
    const networkId = await this.#web3.eth.net.getId()
    const { configurationManager } = getAddresses(networkId)

    if (_.isNil(configurationManager)) return new BigNumber(0)

    const managerContract = new this.#web3.eth.Contract(
      ConfigurationManagerABI,
      configurationManager
    )

    const capProviderAddress = await managerContract.methods
      .getCapProvider()
      .call()

    if (_.isNil(capProviderAddress)) return new BigNumber(0)

    const capContract = new this.#web3.eth.Contract(
      CapProviderABI,
      capProviderAddress
    )

    const capSize = await capContract.methods.getCap(this.#address).call()

    if (_.isNil(capProviderAddress)) return new BigNumber(0)

    return capSize
  }

  async _getDefaultAccount () {
    try {
      const accounts = await this.#web3.eth.getAccounts()
      return accounts[0]
    } catch (e) {
      console.error('Account', e)
      return null
    }
  }
}
