import _ from 'lodash'
import { useMemo } from 'react'
import Theme from 'shared'
import { useParams } from 'react-router-dom'
import { layout } from '../../components/shared/Table'
import Transaction from '../../models/Transaction'
import { useNetworkId, useOptionTransactionHistory } from '../../hooks'

export default function useOwnOptionHistoryTable (force = null) {
  const networkId = useNetworkId()
  const params = useParams()
  const option = useMemo(() => force || _.get(params, 'id'), [params, force])

  const {
    transactions: source,
    isLoading,
    isFinished
  } = useOptionTransactionHistory(option)

  const transactions = useMemo(
    () =>
      source.map(item => {
        const transaction = new Transaction(item, networkId)
        return transaction
      }),
    [networkId, source]
  )

  const columns = useMemo(
    () => [
      {
        title: 'Transaction',
        subtitle: 'Details',
        layout: layout.TransactionHeader
      },
      {
        title: 'Amount',
        layout: layout.TransactionAmount,
        weight: 2
      },
      {
        title: 'Date',
        layout: layout.Text
      }
    ],
    []
  )

  const instructions = useMemo(
    () => ({
      onRowClick: () => {}
    }),
    []
  )
  const rows = useMemo(
    () =>
      transactions.map(transaction => {
        return {
          id: _.get(transaction, 'id'),
          cells: [
            {
              type: _.get(transaction, 'data.type'),
              title: _.get(transaction, 'preview.title'),
              value: _.get(transaction, 'preview.title'),

              underlyingAssetSymbol: _.get(
                transaction,
                'preview.underlyingAssetSymbol'
              ),
              strikeAssetSymbol: _.get(
                transaction,
                'preview.strikeAssetSymbol'
              ),
              stableAssetSymbol: _.get(
                transaction,
                'preview.stableAssetSymbol'
              ),

              strikePrice: _.get(transaction, 'preview.strikePrice'),
              expiration: _.get(transaction, 'preview.expiration')
            },
            {
              value: _.get(transaction, 'preview.amountTransacted'),
              subtitle: _.get(transaction, 'preview.amountTransactedInfo')
            },
            {
              value: _.get(transaction, 'preview.timestamp')
            }
          ]
        }
      }),
    [transactions]
  )

  const isHidden = useMemo(() => {
    if (isLoading) return true
    if (source.length === 0 && isFinished) return true
    return false
  }, [source, isLoading, isFinished])

  return {
    data: {
      columns,
      rows,
      instructions,
      /** Cosmetics */
      isLoading: _.isNil(networkId) || isLoading,
      expected: 2,
      empty: 4,
      theme: Theme.constants.table.theme.historyLight
    },
    isHidden
  }
}
