import { ETH_ADDRESS, ENVIRONMENT, networks } from './atoms'

const production = {
  [networks.mainnet]: {
    eth: ETH_ADDRESS,
    dai: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    usdc: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    adai: '0xfC1E690f61EFd961294b3e1Ce3313fBD8aa4f85d',
    ausdc: '0x9bA00D6856a4eDF4665BcA2C2309936572473B7E',
    wbtc: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    weth: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    link: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
    configurationManager: '',
    optionFactory: '',
    options: [],
    archive: []
  },
  [networks.kovan]: {
    eth: ETH_ADDRESS,
    dai: '0xFf795577d9AC8bD7D90Ee22b6C1703490b6512FD',
    usdc: '0xe22da380ee6b445bb8273c81944adeb6e8450422',
    adai: '0xdCf0aF9e59C002FA3AA091a46196b37530FD48a8',
    ausdc: '0xe12AFeC5aa12Cf614678f9bFeeB98cA9Bb95b5B0',
    wbtc: '0x0094e8cf72acf138578e399768879cedd1ddd33c',
    weth: '0x824B1E309c4eB33501fB49f5de9Cb7481686a799',
    link: '0xbA74882beEe5482EbBA7475A0C5A51589d4ed5De',
    optionFactory: '0x81e84cEC28267F159a63E838B6175AFf85ade8a6',
    optionHelper: '0x188674dbD22c6E32cED8f2Dce40cc45D0873F68E',
    optionAMMFactory: '0x6F23e5f46022A612E86C24F05Aa80Ce2B42d96ab',
    faucet: '0x3E8dAA2a5c2a95F764b09c554646757c27B7d6BE',
    configurationManager: '0x11dB0eDFe06Cd06C9AFE96b9813043e0853e7926',
    options: [
      '0x0C9A67a8C88176c99d9588d0a7684Dd9f10034c5',
      '0xfD66cf9A49221065b46a4De98cFe75CE5655533e',
      '0x298179D4aBE26441FEAD264f2cD3131CD0064767',
      '0x8aC8Fd04A4cbc3bE5C9c3D97132d4E6A6a258fC6',
      '0xFdea2F7E2526DFAea1a349bBCf2f7b6b2eFDe939'
    ],
    archive: []
  },
  [networks.matic]: {
    eth: ETH_ADDRESS,
    usdc: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    weth: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    wbtc: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    wmatic: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    dai: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    amdai: '0xE0b22E0037B130A9F56bBb537684E6fA18192341',
    amusdc: '0x9719d867A500Ef117cC201206B8ab51e794d3F82',
    link: '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
    optionFactory: '0x35601f9C2458172D8d9f4B3Fa2CC33620af632f3',
    optionHelper: '0xB7dEF03006eC72Ef409C41fAEE2619e4bFdB547F',
    optionAMMFactory: '0x7B4401FD0070cA78C7C93aa335D6000Ee0D629F7',
    configurationManager: '0x2971c946d793f3b846A5AEC2Ac9EC945763a82a9',
    options: [
      '0x6e20F296E79Cc7a62737fEDCF9A87Fa32F373864',
      '0xaBD65b1B125e12AbB2F7bDeaE57E62A6272e8797'
    ],
    archive: []
  },
  [networks.mumbai]: {
    eth: ETH_ADDRESS,
    dai: '0x001B3B4d0F3714Ca98ba10F6042DaEbF0B1B7b6F',
    usdc: '0x2058A9D7613eEE744279e3856Ef0eAda5FCbaA7e',
    amdai: '0x639cB7b21ee2161DF9c882483C9D55c90c20Ca3e',
    amusdc: '0x2271e3Fef9e15046d09E1d78a8FF038c691E9Cf9',
    wbtc: '0x41Ec38673f377D8A8c28359CBEfc132791A6efC5',
    weth: '0x2f9374157Ef337620b19a720019A6FDB0593d20B',
    wmatic: '0xfe7f1ef1386e6df3d462e30aa5709fb5ef647ec9',
    optionFactory: '0x91299b4CC119C3DC0b0FF5f8584E4E3625339274',
    optionHelper: '0xB4b8D4444F89C10c6669747827b4803811887648',
    optionAMMFactory: '0x3D1E4Ed4C250914CfF28ef6B756C761e1784A205',
    faucet: '0x55c66Bff1d33057AEf184A7BB68167E8f7bBFd12',
    configurationManager: '0x0eBd48C1CdC5ec82E60d7AD8C41D3f31BD5a82fE',
    options: [
      '0xE629fBbB0B77923A419EfB00401F0d7db183c6bB',
      '0x1aD825b2e95aA98BEBe22B3c8a1c91c8A0F8409e',
      '0x7220D4BFb769266F2198dccB608D504d923b5Bf3',
      '0xb67e48FD81F594fcF07d5B5CA7ee2Dc93923DfdD',
      '0xbF0f7073f8893Ae68a7FE1E208Be1dEc5590F5cA',
      '0xFa6384Fd10A7d88E08C998A32B1AE727AabA56A5',
      '0x5A3cAc63cE9F1af025c4C4232C225A43c5f46557'
    ],
    archive: []
  },
  [networks.local]: {
    eth: ETH_ADDRESS,
    dai: '',
    usdc: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
    adai: '',
    ausdc: '',
    link: '',
    wbtc: '',
    weth: '',
    optionFactory: '',
    optionHelper: '',
    optionAMMFactory: '',
    options: [],
    archive: []
  }
}

const development = {
  [networks.mainnet]: {
    eth: ETH_ADDRESS,
    dai: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    usdc: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    adai: '0xfC1E690f61EFd961294b3e1Ce3313fBD8aa4f85d',
    ausdc: '0x9bA00D6856a4eDF4665BcA2C2309936572473B7E',
    wbtc: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    weth: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    link: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
    configurationManager: '',
    optionFactory: '',
    options: [],
    archive: []
  },
  [networks.kovan]: {
    eth: ETH_ADDRESS,
    dai: '0xFf795577d9AC8bD7D90Ee22b6C1703490b6512FD',
    usdc: '0xe22da380ee6b445bb8273c81944adeb6e8450422',
    adai: '0xdCf0aF9e59C002FA3AA091a46196b37530FD48a8',
    ausdc: '0xe12AFeC5aa12Cf614678f9bFeeB98cA9Bb95b5B0',
    wbtc: '0x0094e8cf72acf138578e399768879cedd1ddd33c',
    weth: '0x824B1E309c4eB33501fB49f5de9Cb7481686a799',
    link: '0xbA74882beEe5482EbBA7475A0C5A51589d4ed5De',
    optionFactory: '0x81e84cEC28267F159a63E838B6175AFf85ade8a6',
    optionHelper: '0x188674dbD22c6E32cED8f2Dce40cc45D0873F68E',
    optionAMMFactory: '0x6F23e5f46022A612E86C24F05Aa80Ce2B42d96ab',
    faucet: '0x3E8dAA2a5c2a95F764b09c554646757c27B7d6BE',
    configurationManager: '0x11dB0eDFe06Cd06C9AFE96b9813043e0853e7926',
    options: [
      '0x0C9A67a8C88176c99d9588d0a7684Dd9f10034c5',
      '0xfD66cf9A49221065b46a4De98cFe75CE5655533e',
      '0x298179D4aBE26441FEAD264f2cD3131CD0064767',
      '0x8aC8Fd04A4cbc3bE5C9c3D97132d4E6A6a258fC6',
      '0xFdea2F7E2526DFAea1a349bBCf2f7b6b2eFDe939'
    ],
    archive: []
  },
  [networks.matic]: {
    eth: ETH_ADDRESS,
    usdc: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    weth: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    wbtc: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    wmatic: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    dai: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    amdai: '0xE0b22E0037B130A9F56bBb537684E6fA18192341',
    amusdc: '0x9719d867A500Ef117cC201206B8ab51e794d3F82',
    link: '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
    optionFactory: '0x35601f9C2458172D8d9f4B3Fa2CC33620af632f3',
    optionHelper: '0xB7dEF03006eC72Ef409C41fAEE2619e4bFdB547F',
    optionAMMFactory: '0x7B4401FD0070cA78C7C93aa335D6000Ee0D629F7',
    configurationManager: '0x2971c946d793f3b846A5AEC2Ac9EC945763a82a9',
    options: [
      '0x6e20F296E79Cc7a62737fEDCF9A87Fa32F373864',
      '0xaBD65b1B125e12AbB2F7bDeaE57E62A6272e8797'
    ],
    archive: []
  },
  [networks.mumbai]: {
    eth: ETH_ADDRESS,
    dai: '0x001B3B4d0F3714Ca98ba10F6042DaEbF0B1B7b6F',
    usdc: '0x2058A9D7613eEE744279e3856Ef0eAda5FCbaA7e',
    amdai: '0x639cB7b21ee2161DF9c882483C9D55c90c20Ca3e',
    amusdc: '0x2271e3Fef9e15046d09E1d78a8FF038c691E9Cf9',
    wbtc: '0x41Ec38673f377D8A8c28359CBEfc132791A6efC5',
    weth: '0x2f9374157Ef337620b19a720019A6FDB0593d20B',
    wmatic: '0xfe7f1ef1386e6df3d462e30aa5709fb5ef647ec9',
    optionFactory: '0x91299b4CC119C3DC0b0FF5f8584E4E3625339274',
    optionHelper: '0xB4b8D4444F89C10c6669747827b4803811887648',
    optionAMMFactory: '0x3D1E4Ed4C250914CfF28ef6B756C761e1784A205',
    faucet: '0x55c66Bff1d33057AEf184A7BB68167E8f7bBFd12',
    configurationManager: '0x0eBd48C1CdC5ec82E60d7AD8C41D3f31BD5a82fE',
    options: [
      '0xE629fBbB0B77923A419EfB00401F0d7db183c6bB',
      '0x1aD825b2e95aA98BEBe22B3c8a1c91c8A0F8409e',
      '0x7220D4BFb769266F2198dccB608D504d923b5Bf3',
      '0xb67e48FD81F594fcF07d5B5CA7ee2Dc93923DfdD',
      '0xbF0f7073f8893Ae68a7FE1E208Be1dEc5590F5cA',
      '0xFa6384Fd10A7d88E08C998A32B1AE727AabA56A5',
      '0x5A3cAc63cE9F1af025c4C4232C225A43c5f46557'
    ],
    archive: []
  },
  [networks.local]: {
    eth: ETH_ADDRESS,
    dai: '',
    usdc: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
    adai: '',
    ausdc: '',
    link: '',
    wbtc: '',
    weth: '',
    optionFactory: '',
    optionHelper: '',
    optionAMMFactory: '',
    options: [],
    archive: []
  }
}

export const addresses = ENVIRONMENT.isProduction() ? production : development
