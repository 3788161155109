import React from 'react'
import styled from 'styled-components'
import Position from '../../../../../shared/Position'
import PositionStatus from '../../../../../shared/PositionStatus'
import { Structure, Section } from '../../../../../shared/Tabs'

import { tabs, types } from '../../../../../../constants'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`

function Overview () {
  return (
    <Structure hash={tabs.hedge.overview}>
      <Wrapper>
        <Section title='Your position'>
          <Position action={types.action.hedge} />
        </Section>
        <Section title='Status'>
          <PositionStatus action={types.action.hedge} />
        </Section>
      </Wrapper>
    </Structure>
  )
}

export default Overview
