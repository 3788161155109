import _ from 'lodash'
import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import useAtomicMachine from './atoms'
import * as guards from './guards'
import { useToasts } from 'react-toast-notifications'
import { useHardRefresh, useModal } from '../hooks'
import { modals } from '../constants'
import { toQuantity } from '../utils'

export function useMachine () {
  const { addToast, removeAllToasts } = useToasts()
  const { refresh } = useHardRefresh()
  const { setOpen, updateData } = useModal(modals.transaction)

  const onPrepare = useCallback(async ({ context }) => {
    const state = _.get(context, 'payload.state')

    const form = await guards.booleanize(() =>
      guards.isFormValid({ value: state, soft: true })
    )

    if (!form) return false

    return true
  }, [])

  const onValidate = useCallback(
    async ({ context }) => {
      const state = _.get(context, 'payload.state')

      const form = await guards.interpret(
        () =>
          guards.isFormValid({
            value: state
          }),
        addToast
      )

      if (form[0] === false) throw new Error(form[1])

      const collateral = await guards.interpret(
        () =>
          guards.isAmountValid({
            value: _.get(state, 'collateral.value'),
            max: _.get(state, 'collateral.max')
          }),
        addToast
      )

      if (collateral[0] === false) throw new Error(collateral[1])
    },
    [addToast]
  )

  const onProcess = useCallback(
    async ({ context }) => {
      const option = _.get(context, 'payload.option')
      const state = _.get(context, 'payload.state')
      const setup = _.get(context, 'payload.setup')

      const optionsValue = new BigNumber(
        _.get(context, 'payload.state.underlying.value')
      ).toNumber()

      const optionLabel = toQuantity(optionsValue, 'option')
      const modalLabel = `${optionLabel} of ${_.get(
        option,
        'underlyingAssetSymbol'
      ).toUpperCase()}:${_.get(option, 'strikeAssetSymbol').toUpperCase()} Put`

      try {
        setOpen(true, {
          state: 'loading',
          tx: null,
          info: `Preparing to mint ${modalLabel}. Checking allowances...`
        })

        const allowance = await setup.getTokenAllowance({
          ...setup,
          tokenAddress: _.get(option, 'strikeAsset'),
          amount: _.get(state, 'collateral.value')
        })

        if (_.isNil(allowance) || _.get(allowance, 'isAllowed') === false) {
          updateData({
            state: 'warning',
            info:
              'It seems that our app is having trouble figuring out your ERC20 allowance. Please reload the page to fix this problem. Thank you for your help and sorry for the inconvenince!'
          })
          return
        }

        updateData({
          info: `Minting ${modalLabel}.`
        })

        let rejected = null

        const transaction = option
          .getPool()
          .mintExactOptions(optionsValue, null, (error, transactionHash) => {
            if (error) rejected = error
            updateData({
              tx: transactionHash
            })
          })

        await transaction

        if (!_.isNil(rejected)) throw rejected

        refresh()

        updateData({
          state: 'success',
          info: `Minted ${modalLabel}.`
        })

        addToast(`${optionLabel} successfully minted!`, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
      } catch (e) {
        console.error('Mint', e)
        removeAllToasts()

        updateData({
          state: 'error',
          info: `Attempted to mint ${modalLabel}.`
        })

        addToast('Transaction cancelled', {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
        throw e
      }
    },
    [addToast, removeAllToasts, refresh, setOpen, updateData]
  )

  const machine = useAtomicMachine({
    id: 'mint',
    onPrepare,
    onValidate,
    onProcess
  })

  return machine
}

export default {
  useMachine
}
