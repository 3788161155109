import _ from 'lodash'
import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Helper } from 'shared'
import SpecialButton from '../../../../../shared/SpecialButton'
import { Structure, Section, Summary, Extra } from '../../../../../shared/Tabs'
import {
  Form as FormPartial,
  Step,
  Label,
  Input
} from '../../../../../shared/Form'

import { tabs, tokens } from '../../../../../../constants'
import reducers from '../../../../../../reducers'
import machines, { guards } from '../../../../../../machines'
import {
  useOwnBalance,
  useOptionData,
  useOptionSellPrice,
  useOptionUnitPrice,
  useFormValidator,
  useFormAssetAllowance,
  useTokenAllowanceForLater
} from '../../../../../../hooks'
import { analytics } from '../../../../../../vendors'
import { toSignificantInput, toNumeralPrice } from '../../../../../../utils'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }

  div[data-step='actions'] {
    & > div:first-child {
      min-width: 220px;
    }
  }
`
const Form = styled(FormPartial)``

const Cell = styled.div`
  display: flex;
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  &[data-step='2'] {
    & > ${Cell} {
      &:first-child {
        padding-right: ${props => props.theme.sizes.edge};
        width: 256px;
        p,
        input {
          text-align: left !important;
          overflow-x: hidden;
        }
      }
      &:last-child {
        flex: 1;
      }
    }
  }

  ${props => props.theme.medias.small} {
    &[data-step='2'] {
      & > ${Cell} {
        &:first-child,
        &:last-child {
          width: auto !important;
          flex: 1;
        }
      }
    }
  }
`

function initialize ({
  balance,
  tokenUnderlying,
  tokenCollateral,
  elements,
  dispatch,
  tokenPremium
}) {
  dispatch([], 'RESET', [elements.allowance])
  dispatch([
    elements.options,
    {
      token: [
        _.get(tokenUnderlying, 'symbol'),
        _.get(tokenCollateral, 'symbol')
      ],
      max: balance
    }
  ])
  dispatch([
    elements.premiumToken,
    {
      value: _.get(tokenPremium, 'symbol')
    }
  ])
}

function Resell () {
  const {
    tokenUnderlying,
    tokenCollateral,
    tokenPremium,
    formattedStrike,
    times,
    item: option,
    isLoading: isOptionLoading,
    version
  } = useOptionData()

  const { value: balance, isLoading: isBalanceOptionsLoading } = useOwnBalance(
    _.get(option, 'address')
  )
  const { check: safeComputePremium } = useOptionSellPrice()
  const unitPrice = useOptionUnitPrice({ option, check: safeComputePremium })
  const { elements, state, dispatch } = reducers.resell.useReducer()
  const machine = machines.resell.useMachine()
  const setup = useTokenAllowanceForLater()

  /**
   *
   * Form setters and state updaters
   *
   */

  const doAllowanceUpdate = useCallback(
    ({ isAllowed, isLoading }) =>
      dispatch([elements.allowance, { options: isAllowed, isLoading }]),
    [elements, dispatch]
  )

  const doPremiumUpdate = useCallback(
    _.debounce(
      ({
        unitPrice,
        values,
        elements,
        dispatch,
        option,
        safeComputePremium
      }) => {
        ;(async () => {
          dispatch([
            elements.premiumCalculated,
            { value: null, isLoading: true }
          ])
          if (values === [null, null]) return

          const premium = await safeComputePremium(
            option,
            new BigNumber(values.options).toNumber()
          )

          const value = _.get(premium, 'value')
          const fees = _.get(premium, 'fees')

          dispatch([
            elements.premiumCalculated,
            {
              value: toSignificantInput(value || 0, BigNumber.ROUND_DOWN),
              isLoading: false,
              warning:
                _.toString(value) !== ''
                  ? guards.isPremiumValid({
                    value
                  })
                  : null
            }
          ])

          dispatch([elements.fees, { value: fees }])
        })()
      },
      300
    ),
    []
  )

  const onChangeAmount = useCallback(
    (options = null) => {
      dispatch([
        elements.options,
        {
          value: options,
          warning:
            _.toString(options) !== ''
              ? guards.isAmountValid({
                value: options,
                max: balance
              })
              : null
        }
      ])

      doPremiumUpdate({
        values: {
          options
        },
        unitPrice,
        elements,
        dispatch,
        option,
        state,
        safeComputePremium
      })
    },
    [
      unitPrice,
      dispatch,
      elements,
      option,
      doPremiumUpdate,
      state,
      balance,
      safeComputePremium
    ]
  )

  const onTransact = useCallback(() => {
    analytics.track(e => e.transactionResellTrigger)
    machine.send(machine.events.save, {
      payload: {
        state,
        option,
        collateralTokenAddress: _.get(tokenCollateral, 'address'),
        premiumTokenAddress: _.get(tokenPremium, 'address'),
        setup
      }
    })
  }, [machine, state, option, tokenPremium, tokenCollateral, setup])

  /**
   *
   * Automated effects
   *
   */

  const {
    doApprove: doAllowanceApprove,
    doRefresh: doAllowanceRefresh
  } = useFormAssetAllowance({
    amount: _.get(state, 'options.value'),
    tokenAddress: _.get(option, 'address'),
    onUpdate: doAllowanceUpdate
  })

  useEffect(() => {
    if (!isOptionLoading && !isBalanceOptionsLoading) {
      initialize({
        balance,
        elements,
        dispatch,
        tokenCollateral,
        tokenUnderlying,
        tokenPremium
      })
      doAllowanceRefresh()
    }
  }, [
    balance,
    tokenCollateral,
    tokenUnderlying,
    tokenPremium,
    elements,
    dispatch,
    version,
    doAllowanceRefresh,

    isOptionLoading,
    isBalanceOptionsLoading
  ])

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  return (
    <Structure hash={tabs.invest.resell}>
      <Wrapper>
        <Section
          title='Sell existing options'
          isContained
          isLoading={isOptionLoading}
          isDisabled={[
            machine.states.validate,
            machine.states.process
          ].includes(machine.current.value)}
        >
          <Form>
            <Step>
              <Label>Step 1. Options to resell</Label>
              <Input.Amount
                {...state.options}
                placeholder='Enter amount'
                onChange={e => onChangeAmount(_.get(e, 'target.value'))}
              />
            </Step>
            <Step>
              <Label>Step 2. Expected premium</Label>
              <Row data-step='2'>
                <Cell>
                  <Input.Amount
                    placeholder='0'
                    value={state.premiumCalculated.value}
                    warning={state.premiumCalculated.warning}
                    isLoading={state.premiumCalculated.isLoading}
                    isViewOnly
                  />
                </Cell>
                <Cell>
                  <Input.Token
                    source={Object.values(tokens.keys)}
                    value={state.premiumToken.value}
                    warning={state.premiumToken.warning}
                    isViewOnly
                    placeholder='Premium token'
                    onChange={() => {}}
                  />
                </Cell>
              </Row>
              <Extra>
                <Helper value='The fees will be paid to the liquidity providers, based on the size and price impact of your trade. This amount is included in the premium value.'>
                  <div data-component='pill'>
                    <p>Included fees: </p>
                    <span>
                      {!_.isNaN(_.get(state, 'fees.value')) &&
                        !_.isNil(_.get(state, 'fees.value')) &&
                        toNumeralPrice(_.get(state, 'fees.value'))}
                    </span>
                  </div>
                </Helper>
              </Extra>
            </Step>
            <Summary
              index={3}
              context={tabs.invest.resell}
              data={{ state, strike: formattedStrike, times, tokenUnderlying }}
              allow={
                <SpecialButton.AllowToken
                  amount={_.get(state, 'options.value')}
                  title='Allow option tokens'
                  isAllowed={_.get(state, 'allowance.options')}
                  isLoading={_.get(state, 'allowance.isLoading')}
                  onApprove={doAllowanceApprove}
                />
              }
              transact={
                <SpecialButton.Transact
                  title='Resell Options'
                  isDisabled={!isFormValid}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </Section>
      </Wrapper>
    </Structure>
  )
}

export default Resell
