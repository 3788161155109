import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Theme from 'shared'
import { HelmetProvider } from 'react-helmet-async'
import { ToastProvider } from 'react-toast-notifications'
import { ApolloProvider } from 'react-apollo'

import { ENVIRONMENT, pages } from '../constants'
import { Toast } from '../components/atoms'
import { client } from '../apollo/client'

import Dashboard from './Dashboard'
import Hedge from './Hedge'
import Invest from './Invest'
import History from './History'
import Faucet from './Faucet'
import Restricted from './Restricted'
import Pool from './Pool'
import TransactionInvest from './Transaction/TransactionInvest'
import TransactionHedge from './Transaction/TransactionHedge'
import TransactionPool from './Transaction/TransactionPool'

import Web3Provider from '../contexts/Web3'
import OptionProvider from '../contexts/Option'
import PriceProvider from '../contexts/Price'
import UIProvider from '../contexts/UI'
import PreferencesProvider from '../contexts/Preferences'
import TransactionHistoryProvider from '../contexts/TransactionHistory'

import Watcher from './Watcher'

function Wrapper ({ children }) {
  return (
    <ApolloProvider client={client}>
      <Web3Provider>
        <UIProvider>
          <PreferencesProvider>
            <PriceProvider>
              <TransactionHistoryProvider>
                <OptionProvider>{children}</OptionProvider>
              </TransactionHistoryProvider>
            </PriceProvider>
          </PreferencesProvider>
        </UIProvider>
      </Web3Provider>
    </ApolloProvider>
  )
}

function Manager ({ children }) {
  return (
    <Theme.Provider>
      <Theme.PlatformGlobalStyle />
      <HelmetProvider>
        <ToastProvider
          components={{ Toast: Toast.Element, ToastContainer: Toast.Container }}
        >
          <BrowserRouter>{children}</BrowserRouter>
        </ToastProvider>
      </HelmetProvider>
    </Theme.Provider>
  )
}

function Routes () {
  return (
    <Switch>
      <Route path={pages.dashboard.route} component={Dashboard} exact />
      <Route path={pages.hedge.route} component={Hedge} exact />
      <Route path={pages.invest.route} component={Invest} exact />
      <Route path={pages.history.route} component={History} exact />
      <Route path={pages.pool.route} component={Pool} exact />
      <Route path={pages.faucet.route} component={Faucet} exact />
      <Route path={pages.restricted.route} component={Restricted} exact />

      <Route
        path={pages.transactionHedge.route}
        component={TransactionHedge}
        exact
      />
      <Route
        path={pages.transactionInvest.route}
        component={TransactionInvest}
        exact
      />
      <Route
        path={pages.transactionPool.route}
        component={TransactionPool}
        exact
      />

      <Redirect to={pages.dashboard.route} />
    </Switch>
  )
}

function App () {
  console.log(
    `%cWelcome to the Pods dashboard! #${String(ENVIRONMENT.current).substr(
      0,
      1
    )}`,
    'color: #C41857'
  )
  return (
    <Manager>
      <Wrapper>
        <Watcher />
        <Routes />
      </Wrapper>
    </Manager>
  )
}

export default App
