import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const WrapperPartial = styled(Link)`
  overflow: hidden;
`

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: span 1;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.sizes.edge};
  min-height: 100%;
  overflow: hidden;
  transition: border 150ms;

  & > * {
    z-index: 10;
    position: relative;
  }
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
  background-image: ${props => props.theme.styles.backgroundGradientLoader};
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms 100ms;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.platform};
  flex-shrink: 0;
  & > svg {
    font-size: 18pt;
    color: ${props => props.theme.colors.dark};
  }
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  padding: calc(${props => props.theme.sizes.edge} * 3 / 2) 0;
`

const Title = styled.p`
  font-size: 11pt;
  font-weight: 500;
  color: ${props => props.theme.colors.dark};
  margin: 0;
  line-height: 1.5;
  &[data-short='true'] {
    display: none;
  }

  b {
    font-weight: 700;
  }
`

const Value = styled.p`
  margin: 0;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1 / 2);
  max-width: 192px;
  ${props => props.theme.extensions.ellipsis}
  font-size: 16pt;
  font-weight: 700;
  background: ${props => props.theme.gradients.primary};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ValueRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 6px;
  flex-wrap: wrap;
  width: 100%;
`

const ValueItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.platform};

  & > p {
    margin: 0;
    ${props => props.theme.extensions.ellipsis}
    font-size: 11pt;
    font-weight: 700;
    background: ${props => props.theme.gradients.primary};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const Wrapper = styled(WrapperPartial)`
  &[data-wallet='true'] {
    ${IconWrapper} {
      background-color: ${props => props.theme.colors.white};
      box-shadow: ${props => props.theme.styles.boxShadowInset};
      & > svg {
        color: ${props => props.theme.colors.middle};
      }
    }
  }

  &[data-loading='true'] {
    ${Overlay} {
      pointer-events: all;
      opacity: 1;
      transition: opacity 250ms 100ms;
    }
  }
  &[data-loading='false'] {
    cursor: pointer;
    ${Box} {
      &:hover,
      &:active {
        border: 1px solid ${props => props.theme.colors.borderMedium};
        transition: border 150ms;
      }
    }
  }

  ${props => props.theme.medias.medium} {
    ${Box} {
      align-items: center;
      flex-direction: row;
      padding: calc(${props => props.theme.sizes.edge} * 1);

      min-height: auto;
      border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
    }
    ${Title} {
      display: none;
      &[data-short='true'] {
        color: ${props => props.theme.colors.dark};
        display: flex;
        font-size: 12pt;
        margin: 0;
      }
    }
    ${Content} {
      padding: calc(${props => props.theme.sizes.edge} * 1 / 3) 0;
    }
    ${Value} {
      font-size: 12pt;
      margin: 0;
    }

    ${ValueRow} {
      margin-left: auto;
      justify-content: flex-end;
      ${ValueItem} {
        padding: 4px;
        & > p {
          font-size: 10pt;
        }
      }
    }

    ${IconWrapper} {
      height: 48px;
      width: 48px;
      margin-right: calc(${props => props.theme.sizes.edge} * 1);
    }
  }
`

function Card ({
  Icon,
  title,
  titleShort,
  value,
  isLoading,
  isWallet,
  to,
  onClick
}) {
  return (
    <Wrapper
      to={to}
      as={to === null ? 'div' : null}
      onClick={onClick}
      data-wallet={isWallet}
      data-loading={isLoading}
    >
      <Box>
        <Overlay />
        <IconWrapper>
          <Icon />
        </IconWrapper>
        <Content>
          <Title>{title}</Title>
          <Title data-short='true'>{titleShort || title}</Title>
        </Content>
        {_.isArray(value) ? (
          <ValueRow>
            {value.map(item => (
              <ValueItem key={_.get(item, 'tokenKey')}>
                <p>
                  {`${_.get(item, 'tokenKey')} ${_.get(item, 'tokenBalance')}`}
                </p>
              </ValueItem>
            ))}
          </ValueRow>
        ) : (
          <Value title={value}>{value}</Value>
        )}
      </Box>
    </Wrapper>
  )
}

Card.propTypes = {
  Icon: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  titleShort: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  isLoading: PropTypes.bool,
  isWallet: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func
}

Card.defaultProps = {
  isLoading: true,
  isWallet: false,
  titleShort: null,
  to: null,
  onClick: () => {}
}

export default Card
