import React from 'react'
import styled from 'styled-components'
import Helmet from '../../components/specific/Helmet'
import Restricted from '../../components/specific/Restricted'
import Sections from '../../components/specific/Dashboard'
import { Structure, ToolbarBase } from '../../components/shared'
import { useSupportedNetwork } from '../../hooks'

const Canvas = styled.div`
  width: 100%;
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 4);
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${props => props.theme.medias.medium} {
    & > * {
      margin-bottom: calc(${props => props.theme.sizes.edge} * 2);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

function Dashboard () {
  const supported = useSupportedNetwork()

  return (
    <Structure>
      <Helmet.Dashboard />
      <ToolbarBase />
      {supported ? (
        <>
          <Canvas>
            <Sections.Overview />
            <Sections.Hedge />
            <Sections.Invest />
            <Sections.Pool />
          </Canvas>

          <Sections.Assets />
        </>
      ) : (
        <Canvas>
          <Restricted.Dashboard />
        </Canvas>
      )}
    </Structure>
  )
}

export default Dashboard
