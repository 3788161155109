import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const WrapperPartial = styled.div``

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: span 1;
  border-radius: calc(${props => props.theme.sizes.edge});
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding: calc(${props => props.theme.sizes.edge} * 1.5);
  min-height: 100%;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
`

const Gain = styled.p`
  padding: 6px 8px;
  border-radius: 4px;
  background: ${props => props.theme.gradients.primary};
  text-align: center;
  font-size: 9pt;
  font-weight: 800;
  color: ${props => props.theme.colors.white};
  margin: 0;
  margin-left: ${props => props.theme.sizes.edge};
`

const Title = styled.p`
  font-size: 12pt;
  font-weight: 700;
  flex: 1;
  color: ${props => props.theme.colors.dark};
  text-transform: uppercase;
  margin: 0;
  line-height: 1.5;
`

const Value = styled.p`
  margin: 0;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  font-size: 20pt;
  font-weight: 700;
  background: ${props => props.theme.gradients.primary};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Description = styled.p`
  font-size: 11pt;
  font-weight: 600;
  color: ${props => props.theme.colors.contentMedium};
  margin: 0;
  line-height: 1.5;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
  background-image: ${props => props.theme.styles.backgroundGradientLoader};
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms 100ms;
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.medium} {
    ${Box} {
      padding: calc(${props => props.theme.sizes.edge} * 1);
      border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
    }
  }

  &[data-loading='true'] {
    ${Overlay} {
      pointer-events: all;
      opacity: 1;
      transition: opacity 250ms 100ms;
    }
  }
`

function Card ({ title, description, value, gain, isLoading }) {
  return (
    <Wrapper data-loading={isLoading}>
      <Box>
        <Overlay />
        <Header>
          <Title>{title}</Title>
          {gain && <Gain>{gain}</Gain>}
        </Header>
        <Value>{value}</Value>
        <Description>{description}</Description>
      </Box>
    </Wrapper>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  gain: PropTypes.string,
  isLoading: PropTypes.bool
}
Card.defaultProps = {
  gain: null,
  isLoading: true
}

export default Card
