export default {
  table: {
    theme: {
      classic: 'classic',
      slim: 'slim',
      history: 'history',
      historyLight: 'historyLight',
      clean: 'clean'
    }
  }
}
