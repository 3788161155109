import _ from 'lodash'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { prefs } from '../../constants'
import { useLocalStorage } from '../../hooks'

export const PreferencesContext = createContext(null)

export default function Provider ({ children }) {
  const value = usePreferences()

  return (
    <PreferencesContext.Provider value={value}>
      {children}
    </PreferencesContext.Provider>
  )
}

export function usePreferencesContext () {
  return useContext(PreferencesContext)
}

function usePreferences () {
  const initial = {
    [prefs.isTermsOfServiceAccepted]: false,
    [prefs.isDarkTheme]: false,
    [prefs.isAnalyticsEnabled]: false
  }

  const [storedPreferences, setStoredPreferences] = useLocalStorage(
    'preferences',
    initial
  )

  const [livePreferences, setLivePreferences] = useState(
    _.isObject(storedPreferences) &&
      Object.keys(initial).every(key =>
        Object.keys(storedPreferences).includes(key)
      )
      ? storedPreferences
      : initial
  )

  const updatePreferences = useCallback(
    (key, value = null) => {
      const result = { ...livePreferences }

      if (_.isString(key) && _.has(result, key)) {
        result[key] = value
      }

      if (_.isObject(key)) {
        Object.keys(key).forEach(innerKey => {
          const innerValue = _.get(key, innerKey)
          if (_.has(result, innerKey)) result[innerKey] = innerValue
        })
      }

      setLivePreferences(result)
      setStoredPreferences(result)
    },
    [livePreferences, setLivePreferences, setStoredPreferences]
  )

  return {
    value: livePreferences,
    update: updatePreferences
  }
}
