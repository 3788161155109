import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useUI } from '../../../../hooks'

const WrapperPartial = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
  border: 1px solid ${props => props.theme.colors.platform};
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  padding: ${props => props.theme.sizes.edge};
  transition: box-shadow 150ms, border 150ms;
  cursor: pointer;
`

const IconWrapper = styled.div`
  margin-right: calc(${props => props.theme.sizes.edge});
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    font-size: 16pt;
    fill: ${props => props.theme.colors.content};
  }
`

const Title = styled.div`
  font-size: 11pt;
  font-weight: 700;
  color: ${props => props.theme.colors.dark};
  margin: 0;
`

const Wrapper = styled(WrapperPartial)`
  &.active {
    cursor: default;
    box-shadow: 0 15px 48px -12px rgba(0, 0, 0, 0.1);
    transition: box-shadow 250ms, border 250ms;
    & > ${IconWrapper} > svg{
          fill: url("${props => props.theme.fills.primary}") ${props =>
  props.theme.colors.secondary};

      }

  }

  &:not(.active){
    &:hover, &:active{
      box-shadow: 0 15px 48px -12px rgba(0, 0, 0, 0.1);
      border: 1px solid ${props => props.theme.colors.border};
      transition: box-shadow 250ms, border 250ms;
    }
  }

  ${props => props.theme.medias.medium} {
    padding: calc(${props => props.theme.sizes.edge} * 1);
    border-radius: calc(${props => props.theme.sizes.edge} * 1 / 3);
    &:hover, &:active{
      border: 1px solid ${props => props.theme.colors.platform};
    }
    ${Title}{
      font-weight: 600;
    }

  }
`

function MenuItem ({ title, Icon, route }) {
  const { closeDrawer } = useUI()

  return (
    <Wrapper exact to={route} onClick={closeDrawer}>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <Title>{title}</Title>
    </Wrapper>
  )
}

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired,
  isActive: PropTypes.bool
}

MenuItem.defaultProps = {
  isActive: false
}

export default MenuItem
