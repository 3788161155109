import _ from 'lodash'
import Rollbar from 'rollbar'
import { constants } from 'shared'
import { ENVIRONMENT } from 'shared/src/constants'

let instance = null

export default {
  init: () => {
    if (_.isNil(instance) && !constants.ENVIRONMENT.isDevelopment()) {
      instance = new Rollbar({
        accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        verbose: constants.ENVIRONMENT.isDevelopment(),
        payload: {
          environment: ENVIRONMENT.current
        }
      })
    }
  },
  log: payload => {
    if (instance) instance.log(payload)
    else console.log(payload)
  },
  info: payload => {
    if (instance) instance.info(payload)
    else console.info(payload)
  },
  error: (payload, print = false) => {
    if (print) console.error(payload)
    if (instance) instance.error(payload)
  }
}
