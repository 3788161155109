import _ from 'lodash'
import React, { useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import Position from '../../../../../shared/Position'
import PositionStatus from '../../../../../shared/PositionStatus'
import SpecialButton from '../../../../../shared/SpecialButton'
import { Structure, Section, Summary } from '../../../../../shared/Tabs'
import {
  Form as FormPartial,
  Step,
  Label,
  Input
} from '../../../../../shared/Form'

import { tabs } from '../../../../../../constants'
import reducers from '../../../../../../reducers'
import machines from '../../../../../../machines'
import { useOptionData, useOwnWithdrawbleAmount } from '../../../../../../hooks'
import { analytics } from '../../../../../../vendors'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Form = styled(FormPartial)`
  max-width: 500px;
  &[data-disabled='true'] {
    filter: grayscale(100%);
    opacity: 0.8;
  }
`

function initialize ({
  balanceWithdrawable,
  tokenUnderlying,
  tokenCollateral,
  elements,
  dispatch
}) {
  dispatch([], 'RESET', [elements.allowance])
  dispatch([
    elements.collateral,
    {
      token: _.get(tokenCollateral, 'symbol'),
      value: _.get(balanceWithdrawable, '[1]')
    }
  ])

  dispatch([
    elements.underlying,
    {
      token: _.get(tokenUnderlying, 'symbol'),
      value: _.get(balanceWithdrawable, '[0]')
    }
  ])
}

function Withdraw () {
  const {
    item: option,
    isLoading: isOptionLoading,
    tokenCollateral,
    tokenUnderlying,
    times,
    version
  } = useOptionData()
  const { isExercised, isExpired } = times
  const isExercising = useMemo(() => isExpired && !isExercised, [
    isExpired,
    isExercised
  ])

  const {
    value: balanceWithdrawable,
    isLoading: isBalanceWithdrawableLoading
  } = useOwnWithdrawbleAmount(option)

  const isLoading = useMemo(
    () => isOptionLoading || isBalanceWithdrawableLoading,
    [isOptionLoading, isBalanceWithdrawableLoading]
  )

  const { elements, state, dispatch } = reducers.withdraw.useReducer()
  const machine = machines.withdraw.useMachine()

  /**
   *
   * Automated effects
   *
   */

  useEffect(() => {
    if (!isLoading) {
      initialize({
        elements,
        dispatch,
        tokenCollateral,
        tokenUnderlying,
        balanceWithdrawable,
        version
      })
    }
  }, [
    elements,
    dispatch,
    tokenCollateral,
    tokenUnderlying,
    balanceWithdrawable,
    version,
    isLoading
  ])

  /**
   *
   * Form setters and state updaters
   *
   */

  const onTransact = useCallback(() => {
    analytics.track(e => e.transactionWithdrawTrigger)
    machine.send(machine.events.save, {
      payload: {
        state,
        option
      }
    })
  }, [machine, state, option])

  return (
    <Structure hash={tabs.invest.withdraw}>
      <Wrapper>
        <Section title='Your position'>
          <Position />
        </Section>
        <Section title='Status'>
          <PositionStatus />
        </Section>
        <Section
          title='Manage position'
          isContained
          isLoading={isLoading}
          isDisabled={[
            machine.states.validate,
            machine.states.process
          ].includes(machine.current.value)}
        >
          <Form
            data-disabled={
              isExercising ||
              _.toArray(balanceWithdrawable).every(amount => amount === 0)
            }
          >
            <Step>
              <Label>Step 1. Collateral to unlock</Label>
              <Input.Amount
                placeholder='Loading Amount...'
                token={state.collateral.token}
                value={state.collateral.value}
                isLoading={isLoading || isBalanceWithdrawableLoading}
                isViewOnly
              />
              <Input.Amount
                placeholder='Loading Amount...'
                token={state.underlying.token}
                value={state.underlying.value}
                isLoading={isLoading || isBalanceWithdrawableLoading}
                isViewOnly
              />
            </Step>

            <Summary
              index={2}
              context={tabs.invest.withdraw}
              data={{
                tokenCollateral,
                tokenUnderlying
              }}
              transact={
                <SpecialButton.Transact
                  token={state.collateral.token}
                  title='Withdraw'
                  isDisabled={isExercising}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </Section>
      </Wrapper>
    </Structure>
  )
}

export default Withdraw
