import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { usePriceContext } from '../contexts/Price'
import { useOptions, useOptionAddressParam } from './option'
import { pages } from '../constants'

export function useMarketPrices () {
  return usePriceContext()
}

export function useOptionPrices ({ context = pages.hedge.title } = {}) {
  const { list: options } = useOptions()
  const [prices, setPrices] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const fetchPrices = useCallback(async () => {
    setIsLoading(true)
    const funcArray = options.map(async option => {
      let price = null

      try {
        if (context === pages.invest.title) {
          price = (await option?.getPool().getSellingPrice(1)).price
        } else price = (await option?.getPool().getBuyingPrice(1)).price
      } catch {}

      return price
    })
    const optionPrices = {}
    await Promise.all(funcArray).then(resultArray => {
      resultArray.forEach((premium, index) => {
        Object.assign(optionPrices, {
          [options[index].address]: { price: premium }
        })
      })
      setPrices(optionPrices)
    })

    setIsLoading(false)
  }, [context, options, setIsLoading])

  useEffect(() => {
    if (options) {
      fetchPrices()
    }
  }, [options, fetchPrices])

  return {
    list: prices,
    fetch: fetchPrices,
    isLoading
  }
}

export function useOptionPrice (force = null, haystack = null) {
  const { list: premiums, isLoading } = useOptionPrices()

  const address = useOptionAddressParam(force)
  const list = useMemo(() => haystack || premiums, [haystack, premiums])

  const item = useMemo(() => {
    const key = Object.keys(list).find(
      lk => String(lk).toLowerCase() === address
    )
    if (key) return _.get(list, key)
    return null
  }, [list, address])

  return {
    item,
    isLoading
  }
}

function useAtomicPrice (checker, { option, amount = 1, slippage = 1 }) {
  const [price, setPrice] = useState({
    fees: null,
    value: null,
    isLoading: false
  })

  const checkPrice = useCallback(
    async (option, amount, slippage = 1) => {
      if (_.isFinite(amount) && !new BigNumber(amount).isZero()) {
        setPrice({ value: null, isLoading: true, fees: null })
        try {
          const sanitized = new BigNumber(amount).toString()
          const result = await option?.getPool()[checker](sanitized)
          const value = new BigNumber(result.price).times(slippage).toNumber()
          setPrice({
            fees: result.fees,
            value,
            isLoading: false
          })
          return { value, fees: result.fees }
        } catch (error) {
          console.error('Price', { error })
          setPrice({ value: null, isLoading: null, fees: null })
          return { value: null, fees: null }
        }
      }
    },
    [setPrice, checker]
  )

  useEffect(() => {
    if (option) {
      checkPrice(option, amount, slippage)
    }
  }, [option, amount, slippage, checkPrice])

  return {
    value: price.value,
    fees: price.fees,
    isLoading: price.isLoading,
    check: checkPrice
  }
}

export function useOptionSellPrice ({ option, amount, slippage } = {}) {
  return useAtomicPrice('getSellingPrice', {
    option,
    amount,
    slippage
  })
}

export function useOptionBuyPrice ({ option, amount, slippage } = {}) {
  return useAtomicPrice('getBuyingPrice', {
    option,
    amount,
    slippage
  })
}

export function useOptionEstimatedBuy ({ option, amount, slippage } = {}) {
  return useAtomicPrice('getEstimatedBoughtOptions', {
    option,
    amount,
    slippage
  })
}

export function useOptionABPrice ({ option, slippage } = {}) {
  return useAtomicPrice('getABPrice', {
    option,
    amount: 1,
    slippage
  })
}

export function useOptionUnitPrice ({ option, check }) {
  const [price, setPrice] = useState(null)
  useEffect(() => {
    ;(async () => {
      try {
        if (_.isNil(option) || _.isNil(check)) return
        const { value: result } = await check(
          option,
          new BigNumber(0.1).toNumber()
        )
        setPrice(result)
      } catch (e) {
        setPrice(null)
      }
    })()
  }, [check, option])

  return price
}

export function useImpactUpdate ({ elements, dispatch }) {
  return useCallback(
    ({ unitPrice, amount, premium }) => {
      const basis = new BigNumber(unitPrice)
        .times(new BigNumber(amount))
        .times(10)

      if (
        basis.isNaN() ||
        new BigNumber(premium).isNaN() ||
        _.isNilOrEmptyString(unitPrice) ||
        _.isNilOrEmptyString(amount) ||
        _.isNilOrEmptyString(premium)
      ) {
        dispatch([elements.impact, { value: null }])
      }

      dispatch([
        elements.impact,
        {
          value: basis.minus(new BigNumber(premium)).toNumber()
        }
      ])
    },
    [elements, dispatch]
  )
}
