import _ from 'lodash'
import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import BigNumber from 'bignumber.js'
import numeral from 'numeral'
import ArrowDown from '@material-ui/icons/KeyboardArrowDown'
import IconForced from '@material-ui/icons/SearchRounded'
import { Link, useParams } from 'react-router-dom'
import { Step, Label } from '../../Form'
import { pages, tabs } from '../../../../constants'
import { useToken } from '../../../../hooks'
import { analytics } from '../../../../vendors'

const Cell = styled.div`
  display: flex;
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 460px;
  & > ${Cell} {
    max-width: 264px;
    &:first-child {
      padding-right: ${props => props.theme.sizes.edge};
      min-width: 200px;
    }
    &:last-child {
      flex: 1;
    }
  }

  ${props => props.theme.medias.small} {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    & > ${Cell} {
      &:first-child {
        max-width: 100%;
        width: 100%;
        padding: 0;
      }
      &:last-child {
        margin-top: ${props => props.theme.sizes.edge};
        max-width: 100%;
        width: 100%;
      }
    }
  }
`

const Toggle = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-left: 8px;
  transform: rotate(0deg);
  transition: transform 200ms;
  cursor: pointer;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  transition: background-color 150ms;

  & > svg {
    font-size: 12pt;
    color: ${props => props.theme.colors.dark};
  }

  &[data-active='true'] {
    transform: rotate(-180deg);
    transition: transform 200ms;
  }

  &[data-forced='true'] {
    cursor: default;
    transform: rotate(0deg) !important;
    transition: transform 200ms;
  }
  &:not([data-forced='true']) {
    &:hover,
    &:active {
      background-color: ${props => props.theme.colors.contentLight};
      transition: background-color 150ms;
    }
  }
`

const Explainer = styled.div`
  width: 100%;
  max-height: 0;
  transition: max-height 400ms;
  overflow: hidden;
  & > * {
    opacity: 0;

    transition: opacity 200ms;
  }

  &[data-active='true'] {
    max-height: 300px;
    transition: max-height 400ms;
    & > * {
      opacity: 1;
      transition: opacity 200ms;
    }
  }
`

const ExplainerContent = styled.div`
  margin: 0;
  padding-bottom: calc(${props => props.theme.sizes.edge} * 1.5);
  padding-top: calc(${props => props.theme.sizes.edge} * 1.5);
  margin-top: calc(${props => props.theme.sizes.edge} * -1.5);

  font-size: 11pt !important;
  font-weight: 500;
  color: ${props => props.theme.colors.contentMedium};
  line-height: 1.8;

  b {
    font-weight: 700;
  }

  div {
    display: inline-flex;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }
`

const InlineToken = styled.span`
  display: inline-block;
  font-weight: 700;
  &:before {
    display: inline-block;
    content: '';
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-image: url(${props => props.source || ''});
    background-repeat: no-repeat;
    background-size: 19px;
    margin-bottom: -5px;
    margin-right: 3px;
    margin-left: 1px;
  }
`

const LearnMoreAnimation = keyframes`
  0{
    opacity: 0;
    transform: translateX(-20px);
  }
  3%, 42%{
    opacity: 1;
    transform: translateX(0);
  }
  45%, 100%{
    opacity: 0;
    transform: translateX(-20px);
  }

`

const LearnMore = styled.div`
  position: relative;
  padding-left: 6px;
  animation-name: ${LearnMoreAnimation};
  animation-fill-mode: forwards;
  animation-duration: 16s;
  animation-delay: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  opacity: 0;
  transform: translateX(-20px);

  height: 24px;
  & > p {
    display: flex;
    align-items: center;
    position: absolute;
    left: -12px;
    top: 0;
    padding-right: 6px;
    padding-left: 18px;
    margin: 0;
    white-space: nowrap;
    height: 24px;
    border-radius: 2px;
    font-size: 9pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    text-align: center;
    background-color: ${props => props.theme.colors.white};
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &[data-active='true'] {
    animation-play-state: paused;
  }
`

function Token ({ t }) {
  return (
    <InlineToken source={_.get(t, 'icon')}>{_.get(t, 'symbol')}</InlineToken>
  )
}

function interpret ({ id, data, context, resolve }) {
  switch (context) {
    case tabs.invest.write: {
      const underlying = resolve(_.get(data, 'state.underlying.token'))
      const underlyingAmount = numeral(
        _.get(data, 'state.underlying.value')
      ).format('0,0.[0000]')
      const collateral = resolve(_.get(data, 'state.collateral.token'))
      const collateralAmount = numeral(
        _.get(data, 'state.collateral.value')
      ).format('0,0.[0000]')

      const premiumAmount = _.get(data, 'state.premiumCalculated.isLoading')
        ? '~'
        : numeral(_.get(data, 'state.premiumCalculated.value')).format(
          '0,0.[0000]'
        )

      const strike = _.get(data, 'strike')
      const expiration = _.get(data, 'times.expiration')

      return (
        <>
          You are selling Put options on <b>{_.get(underlying, 'symbol')}</b>.
          In order to do that you will lock <Token t={collateral} />{' '}
          <b>{collateralAmount}</b> as collateral. You are willing to exchange
          these into (max) <Token t={underlying} /> <b>{underlyingAmount}</b> at
          a strike price of <b>{strike}</b>, in return for a risk premium of at
          least <b>${premiumAmount}</b>.
          <br />
          The collateral will be locked until the exercise window is completed
          (24h after the expiration date of {expiration}). If you want to leave
          your position before expiration, you will have to (1){' '}
          <Link to={pages.transactionHedge.builder(id, tabs.hedge.buy)}>
            buy
          </Link>{' '}
          the same amount of options, at market price (balancing your position)
          and (2){' '}
          <Link to={pages.transactionPool.builder(id, tabs.pool.unwind)}>
            unmint
          </Link>{' '}
          those to unlock your collateral.
        </>
      )
    }
    case tabs.invest.withdraw: {
      const underlying = _.get(data, 'tokenUnderlying')
      const collateral = _.get(data, 'tokenCollateral')

      return (
        <>
          Withdrawing will be active after the exercise window (usually 24h
          after expiration). The amount of collateral you are able to unlock
          will vary based on how many options are exercised by the buyers (who
          will exchange your <Token t={collateral} /> for their{' '}
          <Token t={underlying} />
          ).
        </>
      )
    }

    case tabs.hedge.buy: {
      const underlying = resolve(_.get(data, 'state.underlying.token'))
      const underlyingAmount = numeral(
        _.get(data, 'state.underlying.value')
      ).format('0,0.[00000]')

      const premium = resolve(_.get(data, 'state.premium.token'))
      const premiumAmount = _.get(data, 'state.premium.isLoading')
        ? '~'
        : numeral(_.get(data, 'state.premium.value')).format('0,0.[00000]')
      const strikeAmount = _.get(data, 'strike')
      const collateral = _.get(data, 'tokenCollateral')

      const expiration = _.get(data, 'times.expiration')

      return (
        <>
          You're buying a Put option for <Token t={underlying} />{' '}
          <b>{underlyingAmount}</b>. This means that you can exercise your right
          to sell <b>{_.get(underlying, 'symbol')}</b> for{' '}
          <Token t={collateral} /> <b>{strikeAmount}</b> per asset. You will do
          that during the exercise window (24h after the expiration date of{' '}
          {expiration}). For this, the upfront premium payment to the market is{' '}
          <Token t={premium} /> <b>{premiumAmount}</b>.
        </>
      )
    }

    case tabs.hedge.exercise: {
      const underlying = _.get(data, 'tokenUnderlying')
      const max = numeral(_.get(data, 'max')).format('0,0.[0000]') || '~'

      const strikeAmount = _.get(data, 'strike')
      const collateral = _.get(data, 'tokenCollateral')

      return (
        <>
          You own cover for a maximum amount of <Token t={underlying} />{' '}
          <b>{max}</b>. This means that you can exercise your right to sell the
          underlying asset for <Token t={collateral} /> with a locked price of{' '}
          <Token t={collateral} /> <b>{strikeAmount}</b> per asset.
        </>
      )
    }

    case tabs.invest.resell: {
      const underlying = resolve(_.get(data, 'tokenUnderlying.symbol'))

      const premiumAmount = _.get(data, 'state.premiumCalculated.isLoading')
        ? '~'
        : numeral(_.get(data, 'state.premiumCalculated.value')).format(
          '0,0.[0000]'
        )

      return (
        <>
          You are re-selling Put options on <b>{_.get(underlying, 'symbol')}</b>{' '}
          that you've previously bought or minted. In return you'll receive a
          risk premium of at least <b>${premiumAmount}</b>.
        </>
      )
    }

    case tabs.pool.add: {
      const underlying = _.get(data, 'tokenUnderlying')
      const collateral = _.get(data, 'tokenCollateral')

      return (
        <>
          In order to become a Liquidity Provider and earn you have to supply
          assets to the pool. You will be able to add balanced amounts of{' '}
          <Token t={underlying} /> <b>:</b> <Token t={collateral} /> options and{' '}
          <Token t={collateral} />. To acquire options, you have to either mint
          them (3rd tab) or buy-back some of the ones you sold.
        </>
      )
    }

    case tabs.pool.remove: {
      const underlying = _.get(data, 'tokenUnderlying')
      const collateral = _.get(data, 'tokenCollateral')

      const optionAmount = numeral(
        _.get(data, 'state.options.exposure')
      ).format('0,0.[0000]')

      const collateralAmount = numeral(
        _.get(data, 'state.collateral.exposure')
      ).format('0,0.[0000]')

      const isWithoutPosition =
        (_.isNil(_.get(data, 'position[0]')) &&
          _.isNil(_.get(data, 'position[1]'))) ||
        (new BigNumber(_.get(data, 'position[0]')).isZero() &&
          new BigNumber(_.get(data, 'position[1]')).isZero())

      const optionPercent = `${numeral(
        _.get(data, 'state.options.value')
      ).format('0')}%`

      const collateralPercent = `${numeral(
        _.get(data, 'state.collateral.value')
      ).format('0')}%`

      if (isWithoutPosition) {
        return (
          <>
            You don't have a position in this <Token t={underlying} /> <b>:</b>{' '}
            <Token t={collateral} /> series. Removing liquidity is disabled.
          </>
        )
      }

      return (
        <>
          You are removing <b>{collateralPercent}</b> shares of your deposit's
          initial exposure. Considering the pool’s activity, this translates
          into a current position of <Token t={underlying} /> <b>:</b>{' '}
          <Token t={collateral} /> <b>{optionAmount}</b> option tokens (
          <b>{optionPercent}</b>) and <Token t={collateral} />{' '}
          <b>{collateralAmount}</b> (<b>{collateralPercent}</b>). This is what
          you’ll receive with this transaction.
          <br /> Bear in mind that it could include an impermanent loss or
          impermanent gain. If you are removing a position of options tokens
          that is smaller then what you initially provided, those have been
          traded by the AMM as if you had sold these options. To offset this
          balance, you can use any stablecoins that you remove to buy back those
          options (and unmint/unlock the collateral if necessary).
          <br />
        </>
      )
    }

    case tabs.pool.mint: {
      const underlying = _.get(data, 'tokenUnderlying')
      const collateral = _.get(data, 'tokenCollateral')
      const underlyingAmount = numeral(
        _.get(data, 'state.underlying.value')
      ).format('0,0.[0000]')
      const collateralAmount = numeral(
        _.get(data, 'state.collateral.value')
      ).format('0,0.[0000]')

      return (
        <>
          To become a Liquidity Provider of options you need to mint them. This
          action will lock collateral and create option tokens without selling
          them on the market (no risk of providing cover). To mint, you will
          lock <Token t={collateral} /> <b>{collateralAmount}</b> and receive{' '}
          <Token t={underlying} /> <b>{underlyingAmount}</b> in return.
        </>
      )
    }

    case tabs.pool.unwind: {
      return (
        <>
          After the expiration and exercise window passes you can unlock the
          collateral used to mint options. The amount will vary based on how
          many options you <b>minted</b> and how many you <b>re-bought</b> from
          the market. Remember to remove liquidity first from the pool to gain
          access to your provided options.
        </>
      )
    }

    default:
      return null
  }
}

function Summary ({
  className,
  index,
  data,
  allow,
  transact,
  context,
  isForced
}) {
  const { get: resolve } = useToken()
  const { id } = useParams()
  const interpreted = useMemo(() => interpret({ id, data, context, resolve }), [
    id,
    data,
    context,
    resolve
  ])

  const [isActive, setIsActive] = useState(isForced)
  const toggle = useCallback(() => {
    if (isForced) return
    setIsActive(prev => !prev)
    analytics.track(e => e.transactionSummary)
  }, [isForced, setIsActive])

  return (
    <Step className={className} isLast>
      <Label onClick={toggle}>
        Step {index}. Summary and confirmation{' '}
        <Toggle data-active={isActive} data-forced={isForced}>
          {isForced ? <IconForced /> : <ArrowDown />}
        </Toggle>
        <LearnMore data-active={isActive}>
          <p>Learn More</p>
        </LearnMore>
      </Label>
      <Explainer data-active={isActive}>
        <ExplainerContent>{interpreted}</ExplainerContent>
      </Explainer>
      <Row data-step='actions'>
        {allow && <Cell>{allow}</Cell>}
        {transact && <Cell>{transact}</Cell>}
      </Row>
    </Step>
  )
}

Summary.propTypes = {
  index: PropTypes.number,
  allow: PropTypes.node,
  transact: PropTypes.node,
  data: PropTypes.shape({}),
  context: PropTypes.oneOf([
    ...Object.values(tabs.hedge),
    ...Object.values(tabs.invest),
    ...Object.values(tabs.pool)
  ]),
  isForced: PropTypes.bool
}

Summary.defaultProps = {
  index: 3,
  allow: null,
  transact: null,
  data: {},
  isForced: false
}

export default Summary
