import _ from 'lodash'
import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import numeral from 'numeral'
import DecoratorPrimary from '../../../assets/decorators/pods_tablets_primary.png'
import DecoratorDark from '../../../assets/decorators/pods_tablets_dark.png'
import { tabs, types } from '../../../constants'
import {
  useToken,
  useOwnInvestment,
  useOwnHedge,
  useOptionData,
  useOwnWithdrawbleAmount
} from '../../../hooks'

const WrapperPartial = styled.div``

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
  border: 1px solid ${props => props.theme.colors.border};
  overflow: hidden;
  min-height: 67px;
`

const Asset = styled.div`
  margin-left: -32px;
  max-height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  & > img {
    height: 120px;
    width: 120px;
    object-fit: contain;
  }
`

const Content = styled.div`
  padding: calc(${props => props.theme.sizes.edge} * 1)
    calc(${props => props.theme.sizes.edge} * 1.5);
  min-height: calc(17px + ${props => props.theme.sizes.edge} * 1);
  flex: 1;
  line-height: 1.5;
  p {
    font-size: ${props => props.theme.sizes.text};
    font-weight: 500;
    margin: 0;
    color: ${props => props.theme.colors.dark};
    line-height: 1.5;
  }

  b {
    font-weight: 700;
    margin: 0;
    color: ${props => props.theme.colors.dark};
  }
`

const Explainer = styled.p`
  padding: calc(${props => props.theme.sizes.edge} * 0.5) 0;
`

const SuggestionContent = styled.p`
  font-size: ${props => props.theme.sizes.text};
  color: ${props => props.theme.colors.dark};
  margin: 0;
  line-height: 1.5;
  font-weight: 500;
  & > b {
    font-weight: 700;
  }
  & > a,
  & > span {
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
  }
`

const Loader = styled.div`
  height: calc(17px + ${props => props.theme.sizes.edge} * 1);

  width: 100%;
  border-radius: 4px;
  background-image: linear-gradient(
    -90deg,
    ${props => props.theme.colors.platform} 50%,
    ${props => props.theme.colors.white}
  );
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.medium} {
    width: 100%;

    ${Asset} {
      margin-left: -28px;
      & > img {
        height: 80px;
        width: 80px;
      }
    }
  }
`

function SuggestionInvest ({ times }) {
  const location = useLocation()
  const history = useHistory()
  return (
    <Wrapper>
      <SuggestionContent>
        On this pair, you have no active options.{' '}
        {!_.get(times, 'isExpired') && (
          <>
            You should check the{' '}
            <span
              onClick={() => {
                history.replace({
                  pathname: location.pathname,
                  hash: tabs.invest.write
                })
              }}
            >
              Write
            </span>{' '}
            tab.
          </>
        )}
      </SuggestionContent>
    </Wrapper>
  )
}

function SuggestionHedge ({ times }) {
  const location = useLocation()
  const history = useHistory()
  return (
    <Wrapper>
      <SuggestionContent>
        On this pair, you have no available options.{' '}
        {!_.get(times, 'isExpired') && (
          <>
            You should acquire some in the{' '}
            <span
              onClick={() =>
                history.replace({
                  pathname: location.pathname,
                  hash: tabs.hedge.buy
                })}
            >
              Buy
            </span>{' '}
            tab.
          </>
        )}
      </SuggestionContent>
    </Wrapper>
  )
}

function PositionInvest () {
  const { get: getToken } = useToken()
  const { item: option, isLoading } = useOwnInvestment()
  const { times } = useOptionData(_.get(option, 'address'))

  const underlying = useMemo(
    () => getToken(_.get(option, 'underlyingAssetSymbol')),
    [option, getToken]
  )

  const collateral = useMemo(
    () => getToken(_.get(option, 'strikeAssetSymbol')),
    [option, getToken]
  )

  const { value: withdrawable } = useOwnWithdrawbleAmount(option)

  const amountUnderlying = new BigNumber(_.get(withdrawable, '[0]'))
  const amountCollateral = new BigNumber(_.get(withdrawable, '[1]'))

  if (
    !isLoading &&
    (_.isNil(option) ||
      (amountUnderlying.isZero() && amountCollateral.isZero()))
  ) {
    return <SuggestionInvest times={times} />
  }

  return (
    <Wrapper>
      <Box>
        <Asset>
          <img src={DecoratorPrimary} alt='' />
        </Asset>
        <Content>
          {isLoading ? (
            <Loader />
          ) : (
            <Explainer>
              You can currently withdraw from your{' '}
              <b>
                {_.get(underlying, 'symbol')}:{_.get(collateral, 'symbol')}
              </b>{' '}
              position:{' '}
              <b>
                {numeral(amountCollateral.toNumber()).format('0,0.[0000]')}{' '}
                {_.get(collateral, 'symbol')}
              </b>{' '}
              and{' '}
              <b>
                {numeral(amountUnderlying.toNumber()).format('0,0.[0000]')}{' '}
                {_.get(underlying, 'symbol')}
              </b>
              .
            </Explainer>
          )}
        </Content>
      </Box>
    </Wrapper>
  )
}

function PositionHedge () {
  const { get: getToken } = useToken()
  const { item: option, isLoading } = useOwnHedge()
  const { times } = useOptionData(_.get(option, 'address'))

  const underlying = useMemo(
    () => getToken(_.get(option, 'underlyingAssetSymbol')),
    [option, getToken]
  )

  const collateral = useMemo(
    () => getToken(_.get(option, 'strikeAssetSymbol')),
    [option, getToken]
  )

  if (
    !isLoading &&
    (_.isNil(option) || new BigNumber(_.get(option, 'balance')).isZero())
  ) {
    return <SuggestionHedge times={times} />
  }

  return (
    <Wrapper>
      <Box>
        <Asset>
          <img src={DecoratorDark} alt='' />
        </Asset>
        <Content>
          {isLoading ? (
            <Loader />
          ) : (
            <Explainer>
              Protected{' '}
              <b>
                {numeral(_.get(option, 'balance')).format('0,0.[0000]')}{' '}
                {_.get(underlying, 'symbol')}
              </b>{' '}
              with options of{' '}
              <b>
                {_.get(underlying, 'symbol')}:{_.get(collateral, 'symbol')}
              </b>
              .
            </Explainer>
          )}
        </Content>
      </Box>
    </Wrapper>
  )
}

function Position ({ action, ...props }) {
  return action === types.action.invest ? (
    <PositionInvest {...props} />
  ) : (
    <PositionHedge {...props} />
  )
}

Position.propTypes = {
  action: PropTypes.oneOf([types.action.hedge, types.action.invest]),
  data: PropTypes.oneOfType([
    PropTypes.shape({
      locked: PropTypes.number,
      collateral: PropTypes.string,
      balance: PropTypes.number,
      premium: PropTypes.number
    }),
    PropTypes.shape({
      underlying: PropTypes.string,
      balance: PropTypes.number,
      premium: PropTypes.number
    })
  ]).isRequired
}

Position.defaultProps = {
  action: types.action.invest,
  data: {
    locked: 0,
    balance: 0,
    premium: 0
  }
}

export default Position
