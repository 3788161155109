import _ from 'lodash'
import React, { useMemo } from 'react'
import numeral from 'numeral'
import Theme from 'shared'
import IconMarket from '@material-ui/icons/TimelineRounded'
import { useHistory } from 'react-router-dom'
import { layout } from '../../components/shared/Table'
import { types, pages } from '../../constants'
import {
  interpretTimeWindows,
  toQuantity,
  sortOptionsTableByExpiration
} from '../../utils'

import { useOwnPools } from '../option'
import { useMarketPrices } from '../price'

export default function useOwnPoolsTable () {
  const history = useHistory()

  const { list: options, count, isLoading } = useOwnPools(null, true)
  const { list: prices } = useMarketPrices()

  const columns = useMemo(
    () => [
      {
        title: 'Option Token',
        layout: layout.PodPair,
        subtitle: 'Asset #1',
        weight: 2
      },
      {
        title: 'Stable Token',
        layout: layout.PodAsset,
        subtitle: 'Asset #2',
        weight: 2
      },
      {
        title: 'Strike',
        layout: layout.Price,
        subtitle: (
          <>
            Market Price <IconMarket />
          </>
        ),
        weight: 2
      },
      {
        title: 'Expiration',
        layout: layout.Timestamp,
        weight: 2
      },
      {
        title: 'Your Liquidity',
        layout: layout.Text,
        weight: 2
      },
      {
        title: '',
        layout: layout.Actions,
        weight: 2
      }
    ],
    []
  )

  const instructions = useMemo(
    () => ({
      onRowClick: params => {
        if (_.get(params, 'id')) { history.push(pages.transactionPool.builder(_.get(params, 'id'))) }
      }
    }),
    [history]
  )

  const rows = useMemo(
    () =>
      options
        .map(option => {
          const underlying = _.get(
            option,
            'underlyingAssetSymbol'
          ).toUpperCase()
          const collateral = _.get(option, 'strikeAssetSymbol').toUpperCase()
          const times = interpretTimeWindows(option)
          const market = _.get(prices, underlying)

          return {
            id: _.get(option, 'address'),
            cells: [
              {
                value: [underlying, collateral],
                type: types.action.pool
              },
              {
                value: collateral,
                type: types.action.pool
              },
              {
                value: numeral(_.get(option, 'strikePrice')).format(
                  '$0.[0000]'
                ),
                market: numeral(market).format('$0.[00]')
              },
              {
                value: _.get(option, 'expiration')
              },
              {
                value: [
                  toQuantity(
                    numeral(_.get(option, 'poolData.position[0]')).format(
                      '0,0.[000]'
                    ),
                    'option'
                  ),
                  `${numeral(_.get(option, 'poolData.position[1]')).format(
                    '0,0.[000]'
                  )} ${collateral}`
                ]
              },
              {
                times,
                value: _.get(option, 'address'),
                type: types.action.pool,
                source: pages.dashboard.builder()
              }
            ]
          }
        })
        .sort(sortOptionsTableByExpiration),
    [options, prices]
  )

  return {
    data: {
      columns,
      rows,
      instructions,
      /** Cosmetics */
      isLoading: isLoading,
      expected: count,
      empty: 3,
      theme: Theme.constants.table.theme.slim
    },
    info: {}
  }
}
