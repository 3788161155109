import Dashboard from './Dashboard'
import Hedge from './Hedge'
import History from './History'
import Invest from './Invest'
import Pool from './Pool'

export default {
  Dashboard,
  Hedge,
  History,
  Invest,
  Pool
}
