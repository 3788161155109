import React from 'react'
import styled from 'styled-components'
import { Structure, ToolbarTransaction } from '../../../components/shared'
import { pages } from '../../../constants'
import * as Sections from '../../../components/specific/Transaction/Sections'
import * as Modals from '../../../components/specific/Modals'

const WrapperPartial = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${props => props.theme.sizes.edge};
  padding: 0 ${props => props.theme.sizes.layoutEdge};
`

const Left = styled.div`
  grid-column: span 8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge});
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Right = styled.div`
  grid-column: span 4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge});
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.medium} {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
    padding-top: ${props => props.theme.sizes.layoutEdgeMedium};
    ${Left}, ${Right} {
      grid-column: span 1;
    }
  }
`

function TransactionHedge () {
  return (
    <Structure type='transaction'>
      <ToolbarTransaction
        title='Transaction - Buy Options'
        subtitle='Hedge assets with Put options'
        parent={pages.hedge}
      />
      <Wrapper>
        <Left>
          <Sections.TabsHedge />
          <Sections.OptionHistory />
        </Left>
        <Right>
          <Sections.Info context={pages.hedge.title} />
          <Sections.Charts />
          <Sections.Earn />
          <Sections.Learn />
        </Right>
      </Wrapper>
      <Modals.TransactionState />
    </Structure>
  )
}

export default TransactionHedge
