import BigNumber from 'bignumber.js'
import { constants } from 'shared'

BigNumber.config({ EXPONENTIAL_AT: 256 })
export const MAX_UINT = new BigNumber(2)
  .exponentiatedBy(256)
  .minus(1)
  .toString()

export const types = {
  action: {
    invest: 'invest',
    hedge: 'hedge',
    pool: 'pool',
    history: 'history'
  },
  option: {
    put: 'put',
    call: 'call'
  }
}

export const MODAL_PORTAL = 'pods-modal__container'

export const modals = {
  transaction: 'transaction',
  termsOfService: 'termsOfService',
  historyOptions: 'historyOptions',
  restrictedService: 'restrictedService',
  risk: 'risk'
}

export const ETH_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
export const MATIC_ADDRESS = '0xMmmmmMmmmmMmMmmMmMmMmmMMMmmmmMmmmmmmmMMmM'
export const ETH_DECIMALS = 18
export const MATIC_DECIMALS = 18

export const { chains, networks } = constants

export const prefs = {
  isTermsOfServiceAccepted: 'isTermsOfServiceAccepted',
  isAnalyticsEnabled: 'isAnalyticsEnabled',
  isDarkTheme: 'isDarkTheme'
}

export const ___RANDOM_NUMBER = 3

export const GUARDED_LAUNCH = {
  status: 1,
  limit: 50000
}

export const ENVIRONMENT = constants.ENVIRONMENT
