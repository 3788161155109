import React, { useEffect } from 'react'
import Helmet from '../../components/specific/Helmet'
import { Structure } from '../../components/shared'
import { useModal } from '../../hooks'
import { modals } from '../../constants'

import * as Modals from '../../components/specific/Modals'

function Restricted () {
  const { setOpen } = useModal(modals.restrictedService)

  useEffect(() => {
    setOpen(true)
  }, [setOpen])

  return (
    <Structure>
      <Helmet.Restricted />
      <Modals.RestrictedService />
    </Structure>
  )
}

export default Restricted
