import { networks, ETH_ADDRESS, MATIC_ADDRESS } from './atoms'
import { addresses } from './addresses'

import IconETHFallback from '../assets/tokens/ETH.png'
import IconWBTCFallback from '../assets/tokens/WBTC.png'
import IconLINKFallback from '../assets/tokens/LINK.png'
import IconDAIFallback from '../assets/tokens/DAI.png'
import IconUSDCFallback from '../assets/tokens/USDC.png'
import IconADAIFallback from '../assets/tokens/ADAI.png'
import IconAUSDCFallback from '../assets/tokens/AUSDC.png'
import IconMATICFallback from '../assets/tokens/MATIC.png'

export default {
  keys: {
    LINK: 'LINK',
    WBTC: 'WBTC',
    ETH: 'ETH',
    WETH: 'WETH',
    USDC: 'USDC',
    DAI: 'DAI',
    AUSDC: 'AUSDC',
    ADAI: 'ADAI',
    PMDAI: 'PMDAI',
    PMUSDC: 'PMUSDC',
    AMUSDC: 'PMUSDC',
    WMATIC: 'WMATIC',
    MATIC: 'MATIC'
  },
  [networks.mainnet]: {
    ETH: {
      symbol: 'ETH',
      title: 'Ether',
      address: ETH_ADDRESS,
      icon: IconETHFallback,
      tag: 'underlying',
      decimals: 18
    },
    WETH: {
      symbol: 'WETH',
      title: 'Wrapped Ethereum',
      address: addresses[networks.mainnet].weth,
      icon: IconETHFallback,
      tag: 'underlying',
      decimals: 18
    },
    WBTC: {
      symbol: 'WBTC',
      title: 'Wrapped Bitcoin',
      address: addresses[networks.mainnet].wbtc,
      icon: IconWBTCFallback,
      tag: 'underlying',
      decimals: 8
    },
    LINK: {
      symbol: 'LINK',
      title: 'Link',
      address: addresses[networks.mainnet].link,
      icon: IconLINKFallback,
      tag: 'underlying',
      decimals: 18
    },
    USDC: {
      symbol: 'USDC',
      title: 'USD Coin',
      address: addresses[networks.mainnet].usdc,
      tag: 'collateral',
      decimals: 8
    },
    DAI: {
      symbol: 'DAI',
      title: 'Dai Stablecoin',
      address: addresses[networks.mainnet].dai,
      tag: 'collateral',
      decimals: 18
    },
    AUSDC: {
      symbol: 'AUSDC',
      title: 'Aave USD Coin',
      address: addresses[networks.mainnet].ausdc,
      tag: 'smart',
      decimals: 6
    },
    ADAI: {
      symbol: 'ADAI',
      title: 'Aave Dai Stablecoin',
      address: addresses[networks.mainnet].adai,
      tag: 'smart',
      decimals: 18
    }
  },
  [networks.kovan]: {
    ETH: {
      symbol: 'ETH',
      title: 'Ether',
      address: ETH_ADDRESS,
      icon: IconETHFallback,
      tag: 'underlying',
      decimals: 18
    },
    WBTC: {
      symbol: 'WBTC',
      title: 'Wrapped Bitcoin',
      address: addresses[networks.kovan].wbtc,
      icon: IconWBTCFallback,
      tag: 'underlying',
      decimals: 8
    },
    WETH: {
      symbol: 'WETH',
      title: 'Wrapper Ethereum',
      address: addresses[networks.kovan].weth,
      icon: IconETHFallback,
      tag: 'underlying',
      decimals: 18
    },
    LINK: {
      symbol: 'LINK',
      title: 'Link',
      address: addresses[networks.kovan].link,
      icon: IconLINKFallback,
      tag: 'underlying',
      decimals: 18
    },
    USDC: {
      symbol: 'USDC',
      title: 'USD Coin',
      address: addresses[networks.kovan].usdc,
      tag: 'collateral',
      decimals: 6
    },
    DAI: {
      symbol: 'DAI',
      title: 'Dai Stablecoin',
      address: addresses[networks.kovan].dai,
      tag: 'collateral',
      decimals: 18
    },
    AUSDC: {
      symbol: 'AUSDC',
      title: 'Aave USD Coin',
      address: addresses[networks.kovan].ausdc,
      tag: 'smart',
      decimals: 6
    },
    ADAI: {
      symbol: 'ADAI',
      title: 'Aave Dai Stablecoin',
      address: addresses[networks.kovan].adai,
      tag: 'smart',
      decimals: 18
    }
  },
  [networks.matic]: {
    ETH: {
      symbol: 'ETH',
      title: 'Ether',
      address: ETH_ADDRESS,
      icon: IconETHFallback,
      tag: 'underlying',
      decimals: 18
    },
    WETH: {
      symbol: 'WETH',
      title: 'Wrapper Ethereum',
      address: addresses[networks.matic].weth,
      icon: IconETHFallback,
      tag: 'underlying',
      decimals: 18
    },
    USDC: {
      symbol: 'USDC',
      title: 'USD Coin',
      address: addresses[networks.matic].usdc,
      tag: 'collateral',
      decimals: 6
    },
    DAI: {
      symbol: 'DAI',
      title: 'Dai Stablecoin',
      address: addresses[networks.matic].dai,
      tag: 'collateral',
      decimals: 18
    },
    AMUSDC: {
      symbol: 'AMUSDC',
      title: 'Aave USD Coin',
      address: addresses[networks.matic].amusdc,
      icon: IconAUSDCFallback,
      tag: 'smart',
      decimals: 6
    },
    AMDAI: {
      symbol: 'AMDAI',
      title: 'Aave Dai Stablecoin',
      address: addresses[networks.matic].amdai,
      icon: IconADAIFallback,
      tag: 'smart',
      decimals: 18
    },
    MATIC: {
      symbol: 'MATIC',
      title: 'Matic',
      address: MATIC_ADDRESS,
      icon: IconMATICFallback,
      tag: 'underlying',
      decimals: 18
    },
    WMATIC: {
      symbol: 'WMATIC',
      title: 'Wrapper Matic',
      address: addresses[networks.matic].wmatic,
      icon: IconMATICFallback,
      tag: 'underlying',
      decimals: 18
    },
    LINK: {
      symbol: 'LINK',
      title: 'Link',
      address: addresses[networks.matic].link,
      icon: IconLINKFallback,
      tag: 'underlying',
      decimals: 18
    }
  },
  [networks.mumbai]: {
    ETH: {
      symbol: 'ETH',
      title: 'Ether',
      address: ETH_ADDRESS,
      icon: IconETHFallback,
      tag: 'underlying',
      decimals: 18
    },
    WBTC: {
      symbol: 'WBTC',
      title: 'Wrapped Bitcoin',
      address: addresses[networks.mumbai].wbtc,
      icon: IconWBTCFallback,
      tag: 'underlying',
      decimals: 8
    },
    WETH: {
      symbol: 'WETH',
      title: 'Wrapper Ethereum',
      address: addresses[networks.mumbai].weth,
      icon: IconETHFallback,
      tag: 'underlying',
      decimals: 18
    },
    MATIC: {
      symbol: 'MATIC',
      title: 'Matic',
      address: MATIC_ADDRESS,
      icon: IconMATICFallback,
      tag: 'underlying',
      decimals: 18
    },
    WMATIC: {
      symbol: 'WMATIC',
      title: 'Wrapper Matic',
      address: addresses[networks.mumbai].wmatic,
      icon: IconMATICFallback,
      tag: 'underlying',
      decimals: 18
    },
    LINK: {
      symbol: 'LINK',
      title: 'Link',
      address: addresses[networks.mumbai].link,
      icon: IconLINKFallback,
      tag: 'underlying',
      decimals: 18
    },
    USDC: {
      symbol: 'USDC',
      title: 'USD Coin',
      address: addresses[networks.mumbai].usdc,
      tag: 'collateral',
      decimals: 6
    },
    DAI: {
      symbol: 'DAI',
      title: 'Dai Stablecoin',
      address: addresses[networks.mumbai].dai,
      tag: 'collateral',
      decimals: 18
    },
    PMDAI: {
      symbol: 'PMDAI',
      title: 'Polygon Mumbai Dai',
      address: addresses[networks.mumbai].dai,
      icon: IconDAIFallback,
      tag: 'collateral',
      decimals: 18
    },
    PMUSDC: {
      symbol: 'PMUSDC',
      title: 'Polygon Mumbai USDC',
      address: addresses[networks.mumbai].usdc,
      icon: IconUSDCFallback,
      tag: 'collateral',
      decimals: 6
    },
    AMUSDC: {
      symbol: 'AMUSDC',
      title: 'Aave USD Coin',
      address: addresses[networks.mumbai].amusdc,
      icon: IconAUSDCFallback,
      tag: 'smart',
      decimals: 6
    },
    AMDAI: {
      symbol: 'AMDAI',
      title: 'Aave Dai Stablecoin',
      address: addresses[networks.mumbai].amdai,
      icon: IconADAIFallback,
      tag: 'smart',
      decimals: 18
    }
  },
  [networks.local]: {
    ETH: {
      symbol: 'ETH',
      title: 'Ether',
      address: ETH_ADDRESS,
      icon: IconETHFallback,
      tag: 'underlying',
      decimals: 18
    },
    WBTC: {
      symbol: 'WBTC',
      title: 'Wrapped Bitcoin',
      address: addresses[networks.local].wbtc,
      icon: IconWBTCFallback,
      tag: 'underlying',
      decimals: 8
    },
    WETH: {
      symbol: 'WETH',
      title: 'Wrapper Ethereum',
      address: addresses[networks.local].weth,
      icon: IconETHFallback,
      tag: 'underlying',
      decimals: 18
    },
    LINK: {
      symbol: 'LINK',
      title: 'Link',
      address: addresses[networks.local].link,
      icon: IconLINKFallback,
      tag: 'underlying',
      decimals: 18
    },
    USDC: {
      symbol: 'USDC',
      title: 'USD Coin',
      address: addresses[networks.local].usdc,
      tag: 'collateral',
      decimals: 6
    },
    DAI: {
      symbol: 'DAI',
      title: 'Dai Stablecoin',
      address: addresses[networks.local].dai,
      tag: 'collateral',
      decimals: 18
    },
    AUSDC: {
      symbol: 'AUSDC',
      title: 'Aave USD Coin',
      address: addresses[networks.local].ausdc,
      tag: 'smart',
      decimals: 6
    },
    ADAI: {
      symbol: 'ADAI',
      title: 'Aave Dai Stablecoin',
      address: addresses[networks.local].adai,
      tag: 'smart',
      decimals: 18
    }
  }
}
