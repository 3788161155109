import _ from 'lodash'
import React, { useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import SpecialButton from '../../../../../shared/SpecialButton'
import { Structure, Section, Summary } from '../../../../../shared/Tabs'
import {
  Form as FormPartial,
  Step,
  Label,
  Input
} from '../../../../../shared/Form'

import { tabs, types } from '../../../../../../constants'
import reducers from '../../../../../../reducers'
import machines, { guards } from '../../../../../../machines'
import {
  useOwnBalance,
  useOptionData,
  useFormValidator,
  useFormPairAllowance,
  useTokenAllowanceForLater,
  useOptionABPrice
} from '../../../../../../hooks'
import { analytics } from '../../../../../../vendors'
import { toSignificantInput } from '../../../../../../utils'

import Incentive from './Incentive'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Form = styled(FormPartial)`
  max-width: 538px;

  div[data-step='actions'] {
    max-width: 100%;
    & > div:first-child {
      min-width: 270px;
    }
  }
`

function initialize ({
  elements,
  dispatch,
  balanceStablecoins,
  balanceOptions,
  tokenUnderlying,
  tokenCollateral
}) {
  dispatch([], 'RESET', [elements.allowance])
  dispatch([
    elements.tokenA,
    {
      max: balanceOptions,
      token: [
        _.get(tokenUnderlying, 'symbol'),
        _.get(tokenCollateral, 'symbol')
      ]
    }
  ])
  dispatch([
    elements.tokenB,
    {
      token: _.get(tokenCollateral, 'symbol'),
      max: balanceStablecoins
    }
  ])
}

function Add () {
  const {
    tokenUnderlying,
    tokenCollateral,
    item: option,
    isLoading: isOptionLoading,
    version
  } = useOptionData()
  const pool = useMemo(
    () => (!isOptionLoading && option ? option.getPool() : null),
    [option, isOptionLoading]
  )

  const { value: ABPrice } = useOptionABPrice({ option })

  const {
    value: balanceOptions,
    isLoading: isBalanceOptionsLoading
  } = useOwnBalance(_.get(option, 'address'))
  const {
    value: balanceStablecoins,
    isLoading: isBalanceStablecoinsLoading
  } = useOwnBalance(_.get(tokenCollateral, 'address'))

  const { elements, state, dispatch } = reducers.liquidityAdd.useReducer()
  const machine = machines.liquidityAdd.useMachine()

  const setup = useTokenAllowanceForLater()

  /**
   *
   * Reducer state updaters: Form values
   *
   */

  const safeComputeValues = useCallback(
    ({ tokenA, tokenB }) => {
      try {
        const sABPrice = new BigNumber(ABPrice)

        if (!_.isNilOrEmptyString(tokenA)) {
          const sanitized = new BigNumber(tokenA)
          if (
            sanitized.isZero() ||
            sanitized.isNaN() ||
            !sanitized.isFinite() ||
            sABPrice.isNaN()
          ) {
            return { safeTokenB: null }
          } else {
            return {
              safeTokenB: toSignificantInput(
                sanitized.times(sABPrice).toNumber(),
                BigNumber.ROUND_UP
              )
            }
          }
        } else if (!_.isNilOrEmptyString(tokenB)) {
          const sanitized = new BigNumber(tokenB)
          if (
            sanitized.isZero() ||
            sanitized.isNaN() ||
            !sanitized.isFinite() ||
            sABPrice.isNaN()
          ) {
            return { safeTokenA: null }
          } else {
            return {
              safeTokenA: toSignificantInput(
                sanitized.dividedBy(sABPrice).toNumber(),
                BigNumber.ROUND_UP
              )
            }
          }
        }
      } catch (e) {
        console.error(e)
      }
      return {
        safeTokenA: null,
        safeTokenB: null
      }
    },
    [ABPrice]
  )

  const doAllowanceUpdate = useCallback(
    ({ tokenA, tokenB, isLoading }) => {
      if (_.isNil(tokenB)) {
        dispatch([
          elements.allowance,
          { tokenA: !!tokenA, isLoadingTokenA: isLoading }
        ])
      } else {
        dispatch([
          elements.allowance,
          { tokenB: !!tokenB, isLoadingTokenB: isLoading }
        ])
      }
    },
    [elements, dispatch]
  )

  const onChangeTokenA = useCallback(
    (value, isManual, callback = _.noop) => {
      dispatch([
        elements.tokenA,
        {
          value,
          warning:
            _.toString(value) !== ''
              ? guards.isAmountValid({
                value: value,
                max: balanceOptions,
                min: null
              })
              : null
        }
      ])

      if (isManual === true) {
        const { safeTokenB } = safeComputeValues({ tokenA: value })
        callback(safeTokenB, false)
      }
    },
    [dispatch, elements, balanceOptions, safeComputeValues]
  )

  const onChangeTokenB = useCallback(
    (value, isManual, callback = _.noop) => {
      dispatch([
        elements.tokenB,
        {
          value,
          warning:
            _.toString(value) !== ''
              ? guards.isAmountValid({
                value: value,
                max: balanceStablecoins,
                min: null
              })
              : null
        }
      ])

      if (isManual === true) {
        const { safeTokenA } = safeComputeValues({ tokenB: value })
        callback(safeTokenA, false)
      }
    },
    [dispatch, elements, balanceStablecoins, safeComputeValues]
  )

  const onTransact = useCallback(() => {
    analytics.track(e => e.transactionAddLiquidityTrigger)
    machine.send(machine.events.save, {
      payload: {
        state,
        option,
        pool,
        setup
      }
    })
  }, [machine, state, option, pool, setup])

  /**
   *
   * Automated effects
   *
   */

  const {
    doApproveTokenA: doAllowanceApproveTokenA,
    doApproveTokenB: doAllowanceApproveTokenB,
    doRefreshTokenA: doAllowanceRefreshTokenA,
    doRefreshTokenB: doAllowanceRefreshTokenB
  } = useFormPairAllowance({
    amountTokenB: _.get(state, 'tokenB.value'),
    addressTokenB: _.get(option, 'strikeAsset'),

    amountTokenA: _.get(state, 'tokenA.value'),
    addressTokenA: _.get(option, 'address'),

    onUpdate: doAllowanceUpdate
  })

  useEffect(() => {
    if (
      !isOptionLoading &&
      !isBalanceOptionsLoading &&
      !isBalanceStablecoinsLoading
    ) {
      initialize({
        elements,
        dispatch,
        balanceStablecoins,
        balanceOptions,
        tokenUnderlying,
        tokenCollateral,
        version
      })

      doAllowanceRefreshTokenB()
      doAllowanceRefreshTokenA()
    }
  }, [
    elements,
    dispatch,
    balanceStablecoins,
    balanceOptions,
    tokenUnderlying,
    tokenCollateral,
    version,

    doAllowanceRefreshTokenB,
    doAllowanceRefreshTokenA,

    isOptionLoading,
    isBalanceOptionsLoading,
    isBalanceStablecoinsLoading
  ])

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  const isZeroValues = useMemo(() => {
    const t = new BigNumber(_.get(state, 'tokenB.value'))
    const o = new BigNumber(_.get(state, 'tokenA.value'))

    return (t.isZero() || t.isNaN()) && (o.isZero() || o.isNaN())
  }, [state])

  const onPrimaryRefocus = useCallback(
    isTokenAPrimary => {
      if (isTokenAPrimary === _.get(state, `${elements.tokenA}.isPrimary`)) {
        return
      }

      dispatch([
        elements.tokenA,
        {
          isPrimary: isTokenAPrimary
        }
      ])
      dispatch([
        elements.tokenB,
        {
          isPrimary: !isTokenAPrimary
        }
      ])
    },
    [dispatch, elements, state]
  )

  return (
    <Structure hash={tabs.pool.add}>
      <Wrapper>
        <Section
          title='Add liquidity'
          isContained
          isLoading={
            isOptionLoading ||
            isBalanceOptionsLoading ||
          isBalanceStablecoinsLoading
          }
          isDisabled={[
            machine.states.validate,
            machine.states.process
          ].includes(machine.current.value)}
        >
          <Form>
            <Step>
              <Label>Step 1. Amount to provide </Label>
              <Input.Amount
                {...state.tokenA}
                placeholder='How many options?'
                onChange={e =>
                  onChangeTokenA(_.get(e, 'target.value'), true, onChangeTokenB)}
                onClick={() => onPrimaryRefocus(true)}
              />
              <Input.Amount
                {...state.tokenB}
                placeholder='How many tokens?'
                onChange={e =>
                  onChangeTokenB(_.get(e, 'target.value'), true, onChangeTokenA)}
                onClick={() => onPrimaryRefocus(false)}
              />
            </Step>
            <Summary
              index={2}
              context={tabs.pool.add}
              data={{
                tokenUnderlying,
                tokenCollateral
              }}
              allow={
                <SpecialButton.AllowPair
                  amountTokenA={_.get(state, 'tokenA.value')}
                  amountTokenB={_.get(state, 'tokenB.value')}
                  titleTokenA='Allow options'
                  titleTokenB={`Allow ${_.get(tokenCollateral, 'symbol')}`}
                  isAllowedTokenA={_.get(state, 'allowance.tokenA')}
                  isAllowedTokenB={_.get(state, 'allowance.tokenB')}
                  isLoadingTokenA={_.get(state, 'allowance.isLoadingTokenA')}
                  isLoadingTokenB={_.get(state, 'allowance.isLoadingTokenB')}
                  onApproveTokenA={doAllowanceApproveTokenA}
                  onApproveTokenB={doAllowanceApproveTokenB}
                />
              }
              transact={
                <SpecialButton.Transact
                  action={types.action.pool}
                  title='Add Liquidity'
                  isDisabled={!isFormValid || isZeroValues}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                  warning='Make sure that all allowances have been granted and you have enough options &amp; tokens.'
                />
              }
            />
          </Form>
        </Section>
        <Incentive />
      </Wrapper>
    </Structure>
  )
}

export default Add
