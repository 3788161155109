import React from 'react'
import { Card, Title, Description, Content } from '../atoms'

import Input from '../../../../Form/Input'

function Context ({ reducer }) {
  return (
    <Card>
      <Title>Type</Title>
      <Description>
        Sell Put options, hedge assets or gain market exposure.
      </Description>
      <Content>
        <Input.Select
          {...reducer.state.context}
          placeholder='Choose type'
          onChange={item => {
            reducer.dispatch([
              reducer.elements.context,
              {
                value: item
              }
            ])
          }}
        />
      </Content>
    </Card>
  )
}

export default Context
