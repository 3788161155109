import _ from 'lodash'
import React, { useMemo } from 'react'
import styled, { keyframes } from 'styled-components'
import Theme from 'shared'
import {
  useRows,
  useSize,
  useColumns,
  useInstructions,
  useInfo
} from '../context'
import CellLayouts from '../Cells'

const FadeIn = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => props.size || '1fr'};
  grid-gap: calc(${props => props.theme.sizes.edge} * 1);
  padding: calc(${props => props.theme.sizes.edge} * 1 / 2);
  width: 100%;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.platform};
  border: 1px solid ${props => props.theme.colors.border};
  transition: border 150ms;

  opacity: 0;
  animation-name: ${FadeIn};
  animation-duration: 150ms;
  animation-fill-mode: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  cursor: pointer;

  & > * {
    min-height: 40px;
  }
  &:hover,
  &:active {
    border: 1px solid ${props => props.theme.colors.borderMedium};
    transition: border 250ms;
  }

  &[data-theme="${Theme.constants.table.theme.slim}"]{
    background-color: ${props => props.theme.colors.transparent};
    padding: 0 calc(${props => props.theme.sizes.edge} * 1 / 2);
  }

  &[data-dim="true"]{
    div[data-component="pod-asset"], div[data-component="pod-pair"]{
        filter: grayscale(100%);
    }
  }

`

function Row ({ position }) {
  const size = useSize()
  const { list: rows } = useRows()
  const { list: columns } = useColumns()
  const { theme } = useInfo()
  const { onRowClick } = useInstructions()
  const { id, cells } = useMemo(() => rows[position], [rows, position])

  return (
    <Wrapper
      data-component='row'
      data-theme={theme}
      data-id={id}
      data-dim={_.get(
        cells,
        `[${_.get(cells, 'length') - 1}].times.isExercised`
      )}
      size={size}
      onClick={e =>
        onRowClick({
          event: e,
          id,
          position
        })}
    >
      {_.toArray(cells).map((cell, index) => {
        try {
          const column = columns[index]
          const props = {
            key: _.get(column, 'id') || _.get(column, 'title'),
            column,
            position: {
              row: position,
              column: index
            },
            data: cell
          }

          const Component = CellLayouts[_.get(column, 'layout')]

          if (!_.isNil(Component)) return <Component {...props} />
          return <CellLayouts.Text {...props} />
        } catch (e) {
          console.error(e)
        }
        return null
      })}
    </Wrapper>
  )
}

export default Row
