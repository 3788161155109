import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Helper } from 'shared'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: calc(${props => props.theme.sizes.edge} * 1 / 2) 0;
  border-bottom: 1px solid ${props => props.theme.colors.border};

  div[data-purpose='helper-wrapper'] {
    display: flex;
    align-items: center;
  }
`

const Label = styled.div`
  & > p {
    font-size: 11pt;
    font-weight: 600;
    color: ${props => props.theme.colors.contentMedium};
    margin: 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-height: 46px;
  text-align: right;
  p {
    font-size: 11pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0 !important;
  }
`

const Title = styled.div`
  & > p {
    color: ${props => props.theme.colors.dark};
  }
`

const Subtitle = styled.div`
  margin: 2px 0 0 0;
  & > p {
    font-weight: 700;
    font-size: 10pt;
    color: ${props => props.theme.colors.contentMedium};
    &:empty {
      display: none;
    }
  }
`

function Item ({ title, subtitle, label, children, help }) {
  return (
    <Wrapper>
      <Helper value={help} force='right'>
        <Label>
          <p>{label}</p>
        </Label>
      </Helper>
      <Content data-component='content'>
        {children || (
          <>
            <Title>
              <p>{title}</p>
            </Title>
            <Subtitle>
              <p>{subtitle}</p>
            </Subtitle>
          </>
        )}
      </Content>
    </Wrapper>
  )
}

Item.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  label: PropTypes.string.isRequired
}

Item.defaultProps = {
  title: '',
  subtitle: null
}

export default Item
