import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

const Wrapper = styled.div`
  width: 100%;
  padding-top: ${props => props.theme.sizes.edge};
  &[data-active='true'] {
    display: block;
  }
  &[data-active='false'] {
    display: none;
  }
`

function Structure ({ hash, children, force }) {
  const location = useLocation()

  const isActive = useMemo(
    () => force || _.get(location, 'hash') === `#${hash}`,
    [location, hash, force]
  )

  return <Wrapper data-active={isActive}>{children}</Wrapper>
}

Structure.propTypes = {
  force: PropTypes.bool
}

Structure.defaultProps = {
  force: false
}

export default Structure
