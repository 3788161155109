import useAtomicReducer from './atoms'

const initial = {
  collateral: {
    value: null,
    max: null,
    token: null,
    warning: null,
    isPrimary: true
  },
  underlying: {
    value: null,
    token: null,
    warning: null,
    isPrimary: false
  },
  allowance: {
    token: false,
    isLoading: false
  }
}

export default {
  useReducer: () => useAtomicReducer(initial)
}
