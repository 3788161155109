import _ from 'lodash'
import React, { useMemo } from 'react'
import numeral from 'numeral'
import BigNumber from 'bignumber.js'
import IconMarket from '@material-ui/icons/TimelineRounded'
import { useHistory } from 'react-router-dom'
import { layout } from '../../components/shared/Table'
import { types, pages } from '../../constants'
import { useOptions, useOwnInvestments } from '../option'
import { useMarketPrices, useOptionPrices } from '../price'
import {
  interpretTimeWindows,
  interpretPremium,
  sortOptionsTableByExpiration
} from '../../utils'

export default function useInvestTable ({ filter }) {
  const history = useHistory()

  const { list: allOptions, count, isLoading } = useOptions()
  const { list: investments } = useOwnInvestments()
  const { list: premiums, isLoading: isLoadingPremiums } = useOptionPrices({
    context: pages.invest.title
  })
  const { list: prices } = useMarketPrices()

  const options = useMemo(() => filter(allOptions), [allOptions, filter])

  const columns = useMemo(
    () => [
      {
        title: 'Underlying',
        layout: layout.PodAsset,
        subtitle: 'Asset'
      },
      {
        title: 'Collateral',
        layout: layout.PodAsset,
        subtitle: 'Asset'
      },
      {
        title: 'Strike Price',
        layout: layout.Price,
        subtitle: (
          <>
            Market Price <IconMarket />
          </>
        )
      },
      {
        title: 'Premium',
        layout: layout.Reward
      },
      {
        title: 'Expiration',
        layout: layout.Timestamp
      },
      {
        title: 'Type',
        layout: layout.Text
      },
      {
        title: '',
        layout: layout.Actions
      }
    ],
    []
  )

  const instructions = useMemo(
    () => ({
      onRowClick: params => {
        if (_.get(params, 'id')) {
          history.push(pages.transactionInvest.builder(_.get(params, 'id')))
        }
      }
    }),
    [history]
  )

  const rows = useMemo(
    () =>
      options
        .map(option => {
          const underlying = _.get(
            option,
            'underlyingAssetSymbol'
          ).toUpperCase()
          const collateral = _.get(option, 'strikeAssetSymbol').toUpperCase()
          const price = _.get(premiums, `${_.get(option, 'address')}.price`)
          const market = _.get(prices, underlying)

          const amount = new BigNumber(
            _.get(
              investments.find(
                investment =>
                  _.get(investment, 'address') === _.get(option, 'address')
              ),
              'lockedBalance'
            )
          ).toNumber()

          const times = interpretTimeWindows(option)
          const premium = interpretPremium(option, price)

          return {
            id: _.get(option, 'address'),
            cells: [
              {
                value: underlying,
                type: types.action.invest
              },
              {
                value: collateral,
                type: types.action.invest
              },

              {
                value: numeral(_.get(option, 'strikePrice')).format(
                  '$0.[0000]'
                ),
                market: numeral(market).format('$0.[00]')
              },
              {
                times,
                value: premium.formatted,
                isLoading: _.isNil(premium.raw) || isLoadingPremiums
              },
              {
                value: _.get(option, 'expiration')
              },
              {
                value: 'Put'
              },
              {
                times,
                value: _.get(option, 'address'),
                expiration: _.get(option, 'expiration'),
                amount,
                type: types.action.invest,
                source: pages.invest.builder()
              }
            ]
          }
        })
        .sort(sortOptionsTableByExpiration),
    [options, prices, investments, premiums, isLoadingPremiums]
  )

  return {
    data: {
      columns,
      rows,
      instructions,
      /** Cosmetics */
      isLoading,
      expected: count
    },
    info: {
      total: _.get(allOptions, 'length'),
      filtered: _.get(options, 'length')
    }
  }
}
