import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { ExternalLink, Button, constants } from 'shared'
import { useToasts } from 'react-toast-notifications'
import Base from '../../../shared/Modal'
import { modals, prefs } from '../../../../constants'
import { usePreferences, useModal } from '../../../../hooks'
import * as vendors from '../../../../vendors'
import { ReactComponent as AssetLogo } from '../../../../assets/logo/logo_gradient.svg'
// import CheckIcon from '@material-ui/icons/CheckRounded'

const Modal = styled(Base)`
  & > div[data-component='card'] {
    max-width: 500px;
    border-radius: ${props => props.theme.sizes.edge};
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: calc(${props => props.theme.sizes.edge} * 1);
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  padding-top: calc(${props => props.theme.sizes.edge} * 2);
  padding-bottom: calc(${props => props.theme.sizes.edge} * 1);

  border-top: 1px solid ${props => props.theme.colors.grayBlueLight};
  border-bottom: 1px solid ${props => props.theme.colors.grayBlueLight};
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  &:last-child {
    margin-bottom: 0;
  }
`

const Title = styled.div`
  width: 100%;
  text-align: left;
  & > p {
    color: ${props => props.theme.colors.dark};
    font-size: 13pt;
    font-weight: 600;
    line-height: 1.5;
    margin: 0 0 10px 0;
  }
`

const Description = styled.div`
  width: 100%;
`

const Paragraph = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: 11pt;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;

  a {
    color: ${props => props.theme.colors.middle};
    font-weight: 600;
  }
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  & > svg {
    height: 48px;
    width: auto;
  }
`

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
`

const SwitchBox = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='checkbox'] {
      --active: ${props => props.theme.colors.middle};
      --active-inner: #fff;
      --focus: 2px rgba(39, 94, 254, 0.3);
      --border: #bbc1e1;
      --border-hover: #a6aed8;
      --background: #fff;
      --disabled: #f6f8ff;
      --disabled-inner: #e1e6f9;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 28px;
      flex-shrink: 0;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
      &:after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
          opacity var(--d-o, 0.2s);
      }
      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
      }
      &:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;
        &:checked {
          --b: var(--disabled-inner);
          --bc: var(--border);
        }
        & + label {
          cursor: not-allowed;
        }
      }
      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }

      & + label {
        line-height: 21px;
        cursor: pointer;
      }
    }
    input[type='checkbox'] {
      width: 52px;
      border-radius: 14px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 24px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: 0.6;
          }
        }
      }
    }
  }
`

const SwitchInput = styled.input``

const SwitchLabel = styled(Paragraph)`
  padding: 1px 0 0 15px;
`

// const CheckboxWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   align-items: flex-start;
//   justify-content: center;
//   cursor: pointer;
// `

// const CheckboxLabel = styled(Paragraph)`
//   margin-left: 10px;
//   margin-top: -2px;
// `

// const Checkbox = styled.div`
//   flex-shrink: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 20px;
//   width: 20px;
//   border-radius: 4px;
//   border: 1px solid ${props => props.theme.colors.gray200};
//   background-color: ${props => props.theme.colors.white};
//   & > svg {
//     height: 14px;
//     width: 14px;
//     color: ${props => props.theme.colors.gray400};
//   }

//   &[data-value='true'] {
//     border: 1px solid ${props => props.theme.colors.primary};
//     background-color: ${props => props.theme.colors.primary};
//     & > svg {
//       visibility: visible;
//       color: ${props => props.theme.colors.white};
//     }
//   }

//   &[data-value='false'] {
//     & > svg {
//       visibility: hidden;
//     }
//     &:hover,
//     &:active {
//       box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.12);
//     }
//   }
// `

const Actions = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1 / 2);
  padding-bottom: calc(${props => props.theme.sizes.edge} * 1);

  ${props => props.theme.medias.medium} {
    padding-bottom: calc(${props => props.theme.sizes.edge} * 5);
  }
`

function TermsOfService () {
  const id = modals.termsOfService
  const { setOpen } = useModal(id)
  const { addToast, removeAllToasts } = useToasts()
  const [analyticsAllowance, setAnalyticsAllowance] = useState(true)
  const [jurisdictionAllowance, setJurisdictionAllowance] = useState(false)

  const { update: updatePreferences } = usePreferences()

  const accept = useCallback(() => {
    try {
      removeAllToasts()
      if (!jurisdictionAllowance) {
        addToast(
          'Please formally agree to not being a US resident before joining the platform.',
          {
            appearance: 'warning',
            autoDismiss: true,
            autoDismissTimeout: 6000
          }
        )
        document.getElementById('jurisdictionAllowance').focus()
        return
      }

      updatePreferences({
        [prefs.isTermsOfServiceAccepted]: true,
        [prefs.isAnalyticsEnabled]: analyticsAllowance
      })
      setOpen(false)
      vendors.initialize()
    } catch (e) {
      console.error(e)
    }
  }, [
    analyticsAllowance,
    updatePreferences,
    jurisdictionAllowance,
    addToast,
    removeAllToasts,
    setOpen
  ])

  return (
    <Modal id={id} title='' isCloseHidden isBackdropDisabled>
      <Content>
        <Logo>
          <AssetLogo />
        </Logo>
        <Info>
          <Section>
            <Title>
              <p>Welcome to our demo release</p>
            </Title>
            <Description>
              <Paragraph>
                Thank you for trying out Pods. Here you'll be able to trade
                options and provide liquidity up to a publicly announced TVL
                cap. We've chosen to cap this initial launch in order to provide
                better and safer measurements for our next option series.{' '}
                <b>
                  Please be advised that the current live version of the
                  contracts isn't fully audited.
                </b>
              </Paragraph>
            </Description>
          </Section>
          <Section>
            <Title>
              <p>Before you join</p>
            </Title>
            <Description>
              <Paragraph>
                By moving forward with your on-boarding, you accept our{' '}
                <ExternalLink href={constants.links.terms.primary.link}>
                  Terms of Service
                </ExternalLink>{' '}
                as well as confirm that you understand the possible{' '}
                <ExternalLink href={constants.links.terms.risks.link}>
                  risks
                </ExternalLink>{' '}
                You hereby agree to comply with the local laws and regulations
                of your jurisdiction. You acknowledge that you are fully
                responsible for the safety of your capital.
                <br />
              </Paragraph>
            </Description>
          </Section>
          <Section>
            <SwitchWrapper>
              <SwitchBox>
                <SwitchInput
                  checked={analyticsAllowance}
                  value={analyticsAllowance}
                  onChange={() => {
                    setAnalyticsAllowance(!analyticsAllowance)
                  }}
                  type='checkbox'
                  name='analyticsAllowance'
                  id='analyticsAllowance'
                />
                <SwitchLabel htmlFor='analyticsAllowance'>
                  (Optional) You agree to share anonymous{' '}
                  <ExternalLink href={constants.links.terms.analytics.link}>
                    analytics
                  </ExternalLink>{' '}
                  and crashalytics
                </SwitchLabel>
              </SwitchBox>
            </SwitchWrapper>
            <SwitchWrapper>
              <SwitchBox>
                <SwitchInput
                  checked={jurisdictionAllowance}
                  value={jurisdictionAllowance}
                  onChange={() => {
                    setJurisdictionAllowance(prev => !prev)
                  }}
                  type='checkbox'
                  name='jurisdictionAllowance'
                  id='jurisdictionAllowance'
                />
                <SwitchLabel htmlFor='jurisdictionAllowance'>
                  (Mandatory) You are not a person or company who is a resident
                  of, or is located, incorporated or has a registered agent in,
                  the United States.
                </SwitchLabel>
              </SwitchBox>
            </SwitchWrapper>
          </Section>
        </Info>
        <Actions>
          <Button
            title='Accept &amp; Join'
            appearance={a => a.gradient}
            accent={a => a.primary}
            type={t => t.button}
            onClick={accept}
            isFullWidth
          />
        </Actions>
      </Content>
    </Modal>
  )
}

export default TermsOfService
