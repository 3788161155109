import React from 'react'
import styled from 'styled-components'
import { useOwnHedgeTable } from '../../../../../hooks'
import Section from '../../../../shared/Section'
import TablePartial from '../../../../shared/Table'

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;

  ${props => props.theme.styles.horizontalScrollContainer};
`

const TableWrapper = styled.div`
  width: 100%;
`

const Table = styled(TablePartial)`
  min-width: 900px;
  padding: 0 ${props => props.theme.sizes.layoutEdge};

  div[data-component='body'] {
    min-height: 86px;
  }

  ${props => props.theme.medias.medium} {
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
  }
`

function Hedge () {
  const { data } = useOwnHedgeTable()

  return (
    <Section
      hash='bought'
      title='Options bought'
      description='Find below the options you bought.'
    >
      <Content>
        <TableWrapper>
          <Table data={data} />
        </TableWrapper>
      </Content>
    </Section>
  )
}

export default Hedge
