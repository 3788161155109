import React from 'react'
import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import { Section } from '../../../../shared/Tabs'
import { pages, tabs } from '../../../../../constants'
import { analytics } from '../../../../../vendors'
import IconArrow from '@material-ui/icons/ArrowRightRounded'

const WrapperPartial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.sizes.edge};
  padding-top: calc(${props => props.theme.sizes.edge} * 1.5);
  padding-bottom: calc(${props => props.theme.sizes.edge} * 1.5);
`

const Content = styled.div`
  width: 100%;
`

const BoxPartial = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: ${props => props.theme.colors.platform};
  border: 1px solid ${props => props.theme.colors.border};
  padding: calc(${props => props.theme.sizes.edge} * 1);
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1 / 2);
  &:last-child {
    margin-bottom: 0;
  }
`

const Title = styled.p`
  margin: 0;
  flex: 1;
  text-align: left;
  font-size: 10pt;
  font-weight: 500;
  color: ${props => props.theme.colors.contentMedium};
`

const Action = styled(Link)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.contentMedium};
  margin-right: -4px;
  padding: 8px 4px 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  & > p {
    font-size: 10pt;
    font-weight: 600;
    margin: 0;
  }

  & > svg {
    font-size: 15pt;
    margin-left: 4px;
  }

  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

const Box = styled(BoxPartial)`
  &[data-type='mint'] {
    background: ${props => props.theme.gradients.primary};
    border: 1px solid ${props => props.theme.colors.transparent};
    ${Title}, ${Action} {
      color: ${props => props.theme.colors.white};
    }
    ${Action} {
      &:hover,
      &:active {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.small} {
    padding: 0;
    padding-top: calc(${props => props.theme.sizes.edge} * 3 / 2);
    border-radius: 0;
  }
`

function PoolActions () {
  const { id: address } = useParams()

  return (
    <Wrapper>
      <Content>
        <Section title='Other Actions'>
          <Box data-type='mint'>
            <Title>Mint more options tokens to provide as liquidity</Title>
            <Action
              to={pages.transactionPool.builder(address, tabs.pool.mint)}
              onClick={() => analytics.track(e => e.poolActionMint)}
            >
              <p>Mint options</p>
              <IconArrow />
            </Action>
          </Box>
          <Box>
            <Title>
              Unmint your unused options and release the locked collateral
            </Title>
            <Action
              to={pages.transactionPool.builder(address, tabs.pool.unwind)}
              onClick={() => analytics.track(e => e.poolActionUnwind)}
            >
              <p>Unmint</p>
              <IconArrow />
            </Action>
          </Box>
        </Section>
      </Content>
    </Wrapper>
  )
}

export default PoolActions
