import useAtomicReducer from './atoms'

const initial = {
  tokenA: {
    value: null,
    max: null,
    token: null,
    warning: null,
    isPrimary: true
  },
  tokenB: {
    value: null,
    max: null,
    token: null,
    warning: null,
    isPrimary: false
  },
  allowance: {
    tokenA: false,
    tokenB: false,
    isLoadingTokenA: false,
    isLoadingTokenB: false
  }
}

export default {
  useReducer: () => useAtomicReducer(initial)
}
