import _ from 'lodash'
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect
} from 'react'

import { useLocation } from 'react-router-dom'
import { findPageByRoute } from '../../utils'

export const UIContext = createContext(null)

export default function Provider ({ children }) {
  const value = useUI()

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}

export function useUIContext () {
  return useContext(UIContext)
}

function useUI () {
  const location = useLocation()

  const [drawer, setDrawer] = useState(false)
  const [entry, setEntry] = useState(undefined)
  const openDrawer = useCallback(() => setDrawer(true), [setDrawer])
  const closeDrawer = useCallback(() => setDrawer(false), [setDrawer])
  const toggleDrawer = useCallback(prev => setDrawer(!prev), [setDrawer])

  const modals = useModalsConfiguration()

  useEffect(() => {
    setEntry(prev => {
      const cleanPrev = _.get(prev, 'pathname')
        ? _.toString(_.get(prev, 'pathname')).split('#')[0]
        : null
      const cleanCurrent = _.get(location, 'pathname')
        ? _.toString(_.get(location, 'pathname')).split('#')[0]
        : null

      /** If you run into "tabs" don't push the same page again */
      if (cleanPrev === cleanCurrent) return prev

      /** If current page is not depth-0, don't track it as a back solution */
      if (!findPageByRoute(cleanCurrent)) return prev

      return location
    })
  }, [setEntry, location])

  return {
    drawer,
    openDrawer,
    closeDrawer,
    toggleDrawer,

    entry,

    modals
  }
}

function useModalsConfiguration () {
  const [list, setList] = useState([])

  const send = useCallback(
    (action, payload) => {
      switch (action) {
        case 'open':
          setList(prev => {
            if (_.isNil(prev.find(item => item.id === _.get(payload, 'id')))) {
              return [
                ...prev,
                {
                  id: _.get(payload, 'id'),
                  isOpen: true,
                  data: _.get(payload, 'data')
                }
              ]
            }
            return prev.map(item =>
              item.id === _.get(payload, 'id')
                ? {
                  ...item,
                  isOpen: true,
                  data: _.get(payload, 'data') || item.data
                }
                : item
            )
          })
          break
        case 'close':
          setList(prev => {
            return prev.map(item =>
              item.id === _.get(payload, 'id')
                ? { ...item, isOpen: false }
                : item
            )
          })
          break
        case 'set':
          setList(prev => {
            return prev.map(item =>
              item.id === _.get(payload, 'id')
                ? { ...item, data: _.get(payload, 'data') }
                : item
            )
          })
          break
        case 'update':
          setList(prev => {
            return prev.map(item =>
              item.id === _.get(payload, 'id')
                ? {
                  ...item,
                  data: {
                    ..._.toPlainObject(item.data),
                    ...(_.get(payload, 'data') || {})
                  }
                }
                : item
            )
          })
          break
        default:
          break
      }
    },
    [setList]
  )

  return {
    list,
    send
  }
}
