import _ from 'lodash'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'

const Wrapper = styled.div`
  height: 60px;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ${props => props.theme.sizes.edge};

  & > * {
    &[data-locked='true'] {
      order: 10000;
    }
  }
  &:after {
    content: '';
    height: 100%;
    flex: 1;
    order: 10;
  }

  ${(props) => props.theme.medias.medium} {
      width: calc(100vw);
      padding-left: calc(${props => props.theme.sizes.edge});
      padding-right: calc(${props => props.theme.sizes.edge});
      overflow-x: auto;
      &:after{
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-color: transparent transparent;
      &::-webkit-scrollbar {
        display: none !important;
        height: 0 !important;
        width: 0 !important;
        background: transparent !important;
    }
  }
`

function Bar ({ fallback, supported, children, locked }) {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (
      !_.has(location, 'hash') ||
      (!supported.find(hash => _.toString(location.hash) === `#${hash}`) &&
        supported.includes(fallback)) ||
      locked.find(hash => _.toString(location.hash) === `#${hash}`)
    ) {
      history.replace({
        pathname: location.pathname,
        hash: fallback,
        search: location.search
      })
    }
  }, [location, history, fallback, supported, locked])

  return <Wrapper>{children}</Wrapper>
}

Bar.propTypes = {
  fallback: PropTypes.string,
  supported: PropTypes.arrayOf(PropTypes.string),
  locked: PropTypes.arrayOf(PropTypes.string)
}

Bar.defaultProps = {
  fallback: null,
  supported: [],
  locked: []
}

export default Bar
