import _ from 'lodash'
import { useCallback, useEffect, useState, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { ETH_DECIMALS, macros, MATIC_DECIMALS } from '../constants'
import { ERC20ABI } from '../contracts'
import { useWeb3, useNetworkId, useAccount } from './web3'
import { useAddresses } from './utility'
import { useOptionsVersion } from './option'
import { isEth, isEthereumChain, isMatic, isPolygonChain } from '../utils'

function useBalance (asset, owner) {
  const web3 = useWeb3()
  const networkId = useNetworkId()
  const version = useOptionsVersion()
  const { weth } = useAddresses()

  const [balance, setBalance] = useState({ value: null, isLoading: true })

  const fetchBalance = useCallback(
    ({ asset, owner }) => {
      if (_.isNil(web3)) return null

      setBalance(prev => ({ ...prev, isLoading: true }))
      let requests = []

      if (isEth(asset, networkId) && isEthereumChain(networkId)) {
        requests = [web3.eth.getBalance(owner), ETH_DECIMALS]
      } else if (isMatic(asset, networkId) && isPolygonChain(networkId)) {
        requests = [web3.eth.getBalance(owner), MATIC_DECIMALS]
      } else {
        const contract = new web3.eth.Contract(ERC20ABI, asset)
        requests = [
          contract.methods.balanceOf(owner).call(),
          contract.methods.decimals().call()
        ]
      }

      return Promise.all(requests)
        .then(([value, decimals]) => {
          let result = null
          const divideBy = new BigNumber(10)
            .exponentiatedBy(decimals)
            .toNumber()

          if (
            !(
              _.isNil(value) ||
              new BigNumber(value).isNaN() ||
              new BigNumber(value).isZero()
            )
          ) {
            result = new BigNumber(value).dividedBy(divideBy)
            result = result.isGreaterThan(macros.MINIMUM_BALANCE_AMOUNT)
              ? result.toNumber()
              : 0
          } else result = 0

          setBalance({
            value: result,
            isLoading: false
          })
          return result
        })
        .catch(e => {
          console.error('Balance', e)
          setBalance({ value: null, isLoading: false })
          return null
        })
    },
    [web3, networkId]
  )

  useEffect(() => {
    if (web3 && asset && owner && weth) {
      fetchBalance({ asset, owner })
    }
  }, [web3, asset, owner, fetchBalance, weth, version])

  return {
    value: balance.value,
    fetch: fetchBalance,
    isLoading: balance.isLoading
  }
}

export function useOwnBalance (assetAddress, force = null) {
  const { address } = useAccount()
  const owner = useMemo(() => force || address, [force, address])

  const balance = useBalance(assetAddress, owner)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchBalance = useCallback(asset => balance.fetch({ asset, owner }), [
    balance.fetch,
    owner
  ])

  return {
    ...balance,
    fetch: fetchBalance
  }
}

export function useOwnBalances (assets = [], force = null) {
  const [result, setResult] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const { address } = useAccount()
  const owner = useMemo(() => force || address, [force, address])
  const { fetch } = useBalance(null, owner)

  useEffect(() => {
    if (
      _.isNil(assets) ||
      assets.length === 0 ||
      assets.some(a => _.isNil(a))
    ) {
      return
    }
    ;(async () => {
      setIsLoading(true)
      const balances = []

      for (let i = 0; i < assets.length; i++) {
        const value = await fetch({ asset: assets[i], owner })

        balances.push({
          address: assets[i],
          balance: !_.isNil(value) ? value : 0
        })
      }

      setResult(balances)
      setIsLoading(false)
    })()
  }, [assets, owner, fetch])

  return { result, isLoading }
}

export function useExchangeBalance (assetAddress, force = null) {
  const { optionHelper } = useAddresses()
  const owner = useMemo(() => force || optionHelper, [force, optionHelper])

  const balance = useBalance(assetAddress, owner)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchBalance = useCallback(asset => balance.fetch({ asset, owner }), [
    balance.fetch,
    owner
  ])

  return {
    ...balance,
    fetch: fetchBalance
  }
}

export function usePoolBalance (option, force = null) {
  const pool = option?.getPool()

  const owner = useMemo(() => {
    if (force || pool) {
      return pool.address
    }
  }, [force, pool])

  const balance = useBalance(option?.address, owner)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchBalance = useCallback(asset => balance.fetch({ asset, owner }), [
    balance.fetch,
    owner
  ])

  return {
    ...balance,
    fetch: fetchBalance
  }
}
