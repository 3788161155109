const colors = {
  /**
   * Global
   */

  primary: '#B7156B',
  secondary: '#DF1D2C',
  middle: '#C41857',
  white: '#ffffff',
  black: '#000000',
  dark: '#262940',
  night: '#20212A',

  grayGhost: '#fcfcfc',
  grayLight: '#f5f5f5',
  grayAccent: '#eeeeee',
  grayClear: '#dddddd',
  grayBlueBlack: '#40474f',
  grayBlueNight: '#6d8097',
  grayBlueDark: '#8B9AAC',
  grayBlueMedium: '#b3bed5',
  grayBlueNormal: '#d5dbe1',
  grayBlueLight: '#e4e8f0',
  grayBlueGhost: '#F6F9FE',

  transparent: 'transparent',
  background: '#f3f8fa',
  metamask: '#F6851B',

  blue: '#2979FF',
  blueSuite: '#04BEFE',
  blueFaded: '#03A0DC',
  purple: '#7F00FF',
  pink: '#E100FF',
  red: '#F44336',
  green: '#4CAF50',
  green2: '#18cc72',
  green3: '#0CCB7B',
  yellow: '#FFEB3B',
  yellow2: '#FFC83B',
  orange: '#FF9800',

  google: '#E13F2A',

  /**
   * Platform
   */

  platform: '#F8F8FE',
  platformMedium: '#F6F6FF',
  content: '#C7C9E3',
  contentLight: '#DDDEEE',
  contentMedium: '#B3B5CC',
  border: '#EFEFFD',
  borderMedium: '#DDDEEE'
}

export const gradients = {
  primary: `linear-gradient(45deg, ${colors.primary}, ${colors.secondary})`,
  primaryBuilder: (angle = 0) =>
    `linear-gradient(${angle}deg, ${colors.primary}, ${colors.secondary})`,
  gold: `linear-gradient(25deg, ${colors.orange} 30%, ${colors.yellow})`,
  green: `linear-gradient(25deg, ${colors.green} 30%, ${colors.green2})`,
  white: `linear-gradient(25deg, ${colors.white} 30%, ${colors.white})`,
  transparentBuilder: (start = '#ffffff', angle = 0) =>
    `linear-gradient(${angle}deg, ${start}, ${start}00)`,
  dark: `linear-gradient(45deg, #262940 30%, #3B3F59)`,
  darkReverse: `linear-gradient(45deg, #3B3F59 30%, #262940)`
}

export const fills = {
  primary: `data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg'><linearGradient id='globalGradientPrimary' gradientUnits='userSpaceOnUse'><stop stop-color='%23${colors.primary.slice(
    1
  )}'/><stop offset='1' stop-color='%23${colors.secondary.slice(
    1
  )}'/></linearGradient></svg>#globalGradientPrimary`
}

export default colors
