import _ from 'lodash'
import { useCallback } from 'react'
import useAtomicMachine from './atoms'
import * as guards from './guards'
import { useToasts } from 'react-toast-notifications'
import { useHardRefresh, useModal } from '../hooks'
import { modals } from '../constants'

export function useMachine () {
  const { addToast, removeAllToasts } = useToasts()
  const { refresh } = useHardRefresh()
  const { setOpen, updateData } = useModal(modals.transaction)

  const onPrepare = useCallback(async ({ context }) => {
    const state = _.get(context, 'payload.state')

    const form = await guards.booleanize(() =>
      guards.isFormValid({ value: state, soft: true })
    )

    if (!form) return false

    return true
  }, [])

  const onValidate = useCallback(
    async ({ context }) => {
      const state = _.get(context, 'payload.state')

      const form = await guards.interpret(
        () =>
          guards.isFormValid({
            value: state
          }),
        addToast
      )

      if (form[0] === false) throw new Error(form[1])

      const collateral = await guards.interpret(
        () =>
          guards.isAmountValid({
            value: _.get(state, 'collateral.value')
          }),
        addToast
      )

      if (collateral[0] === false) throw new Error(collateral[1])
    },
    [addToast]
  )

  const onProcess = useCallback(
    async ({ context }) => {
      const option = _.get(context, 'payload.option')

      try {
        setOpen(true, {
          state: 'loading',
          tx: null,
          info: 'Unlocking and withdrawing colllateral.'
        })

        let rejected = null

        const transaction = option.withdraw((error, transactionHash) => {
          if (error) rejected = error
          updateData({
            tx: transactionHash
          })
        })
        await transaction

        if (!_.isNil(rejected)) throw rejected

        removeAllToasts()
        refresh()

        updateData({
          state: 'success',
          info: 'Collateral successfully withdrawn.'
        })

        addToast('Collateral successfully withdrawn!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
      } catch (e) {
        console.error('Withdraw', e)
        removeAllToasts()

        updateData({
          state: 'error',
          info: 'Attempted to withdraw collateral.'
        })

        addToast('Transaction cancelled', {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
        throw e
      }
    },
    [addToast, removeAllToasts, refresh, setOpen, updateData]
  )

  const machine = useAtomicMachine({
    id: 'withdraw',
    onPrepare,
    onValidate,
    onProcess
  })

  return machine
}

export default {
  useMachine
}
