import _ from 'lodash'
import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import useAtomicMachine from './atoms'
import * as guards from './guards'
import { useToasts } from 'react-toast-notifications'
import { useHardRefresh, useModal } from '../hooks'
import { modals } from '../constants'

export function useMachine () {
  const { addToast, removeAllToasts } = useToasts()
  const { refresh } = useHardRefresh()
  const { setOpen, updateData } = useModal(modals.transaction)

  const onPrepare = useCallback(async ({ context }) => {
    const state = _.get(context, 'payload.state')

    const form = await guards.booleanize(() =>
      guards.isFormValid({ value: state, soft: true })
    )

    if (!form) return false

    return true
  }, [])

  const onValidate = useCallback(
    async ({ context }) => {
      const state = _.get(context, 'payload.state')

      const form = await guards.interpret(
        () =>
          guards.isFormValid({
            value: state
          }),
        addToast
      )

      if (form[0] === false) throw new Error(form[1])

      const options = await guards.interpret(
        () =>
          guards.isAmountValid({
            value: _.get(state, 'options.value'),
            max: _.get(state, 'options.max')
          }),
        addToast
      )

      if (options[0] === false) throw new Error(options[1])
    },
    [addToast]
  )

  const onProcess = useCallback(
    async ({ context }) => {
      const option = _.get(context, 'payload.option')
      const optionAmount = new BigNumber(
        _.get(context, 'payload.state.options.value')
      ).toString()
      const optionLabel = `${optionAmount} ${
        optionAmount === 1 ? 'option' : 'options'
      }`
      const modalLabel = `${optionLabel} of ${_.get(
        option,
        'underlyingAssetSymbol'
      ).toUpperCase()}:${_.get(option, 'strikeAssetSymbol').toUpperCase()} Put`

      try {
        setOpen(true, {
          state: 'loading',
          tx: null,
          info: `Unminting ${modalLabel}.`
        })

        let rejected = null

        const transaction = option.unwind(
          optionAmount,
          (error, transactionHash) => {
            if (error) rejected = error
            updateData({
              tx: transactionHash
            })
          }
        )
        await transaction

        if (!_.isNil(rejected)) throw rejected

        removeAllToasts()
        refresh()

        updateData({
          state: 'success',
          info: `Unlocked ${modalLabel}.`
        })

        addToast('Collateral successfully unlocked!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
      } catch (e) {
        console.error('Unmint', e)
        removeAllToasts()

        updateData({
          state: 'error',
          info: `Attempted to unmint ${modalLabel}.`
        })

        addToast('Transaction cancelled', {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
        throw e
      }
    },
    [addToast, removeAllToasts, refresh, setOpen, updateData]
  )

  const machine = useAtomicMachine({
    id: 'unwind',
    onPrepare,
    onValidate,
    onProcess
  })

  return machine
}

export default {
  useMachine
}
