import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { Helper } from 'shared'
import { macros, pages, types } from '../../../../../constants'

import IconArrow from '@material-ui/icons/ArrowForwardRounded'
import IconCheck from '@material-ui/icons/CheckRounded'
import IconCalendar from '@material-ui/icons/CalendarTodayRounded'
import IconCalendarOver from '@material-ui/icons/EventBusyRounded'
import IconFlash from '@material-ui/icons/FlashOnRounded'

const HELPER_POSTION = 'left'

const Wrapper = styled.div`
  grid-column: span 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Labels = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1;
  & > * {
    margin-right: 6px;
    &:last-child {
      margin-right: 0;
    }
  }

  &[data-context='pool'] {
    margin-left: auto;
    flex: 0;
    padding-right: 6px;
  }

  *[data-purpose='helper-wrapper'] {
    display: flex;
    align-items: center;
  }
  *[data-purpose='helper-box'] {
    width: 120px !important;
    top: 0;
  }
`

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};

  & > svg {
    font-size: 11pt;
    color: ${props => props.theme.colors.dark};
    transition: color 250ms;
  }

  &[data-component='label-expiration'] {
    & > svg {
      color: ${props => props.theme.colors.middle};
    }
  }

  &[data-component='label-exercise'] {
    & > svg {
      color: ${props => props.theme.colors.middle};
    }
  }

  &[data-component='label-ended'] {
    & > svg {
      color: ${props => props.theme.colors.dark};
    }
  }
`

const Visit = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 52px;
  height: 52px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  box-shadow: none;
  transition: box-shadow 150ms;
  cursor: pointer;

  & > svg {
    font-size: 16pt;
    color: ${props => props.theme.colors.dark};
    transform: translateX(0px);
    transition: transform 150ms;
  }

  &:hover,
  &:active {
    box-shadow: ${props => props.theme.styles.boxShadowHover};
    transition: box-shadow 250ms;
    & > svg {
      transform: translateX(2px);
      transition: transform 250ms;
    }
  }
`

const RegularButton = styled(Link)`
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.platformMedium};
  padding: 8px 12px;
  cursor: pointer;
  margin-left: 0;
  & > p {
    margin: 0;
    font-size: 10pt;
    font-weight: 600;
    color: ${props => props.theme.colors.contentMedium};
    transition: color 200ms;
  }
  & > svg {
    font-size: 11pt;
    margin-left: 4px;
    color: ${props => props.theme.colors.contentMedium};
    transition: color 200ms;
  }

  &:hover,
  &:active {
    & > svg,
    & > p {
      color: ${props => props.theme.colors.dark};
      transition: color 200ms;
    }
  }
`

const PoolButton = styled(RegularButton)`
  transition: background-color 200ms;
  & > svg,
  & > p {
    color: ${props => props.theme.colors.dark};
    transition: color 200ms;
  }
  &:hover,
  &:active {
    transition: background-color 200ms;
    background-color: ${props => props.theme.colors.contentLight};
  }
`

const HistoryButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 3px;
  margin-top: -6px;
  margin-right: -6px;
  margin-bottom: -6px;
  & > * {
    grid-column: span 1;
    &:first-child {
      grid-column: span 2;
    }
  }
`

const HistoryButton = styled(RegularButton)`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  padding: 5px 12px;
  transition: background-color 200ms;
  & > p {
    flex: 1;
    color: ${props => props.theme.colors.dark};
  }
  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.platform};
    transition: background-color 200ms;
  }
`

function ActionsPools ({ column, value, isOver = false, isExpired = false }) {
  return (
    <Wrapper size={_.get(column, 'weight')}>
      <Labels data-context='pool'>
        {isOver ? (
          <Helper value='Option series ended' force={HELPER_POSTION}>
            <Label data-component='label-ended' title=''>
              <IconCalendarOver />
            </Label>
          </Helper>
        ) : (
          isExpired && (
            <Helper value='Option exercising now' force={HELPER_POSTION}>
              <Label data-component='label-exercise'>
                <IconFlash />
              </Label>
            </Helper>
          )
        )}
      </Labels>
      <PoolButton to={pages.transactionPool.builder(value)}>
        <p>Manage</p>
        <IconArrow />
      </PoolButton>
    </Wrapper>
  )
}

function ActionsHistory ({ column, value }) {
  return (
    <Wrapper size={_.get(column, 'weight')}>
      <HistoryButtons>
        <HistoryButton to={pages.transactionPool.builder(value)}>
          <p>Manage Pool</p>
          <IconArrow />
        </HistoryButton>
        <HistoryButton to={pages.transactionHedge.builder(value)}>
          <p>Buy</p>
          <IconArrow />
        </HistoryButton>
        <HistoryButton to={pages.transactionInvest.builder(value)}>
          <p>Sell</p>
          <IconArrow />
        </HistoryButton>
      </HistoryButtons>
    </Wrapper>
  )
}

function Actions ({ data, column }) {
  const { value, amount, type, expiration, times } = data

  const target =
    type === types.action.invest
      ? pages.transactionInvest
      : pages.transactionHedge

  const isOwned = useMemo(
    () => !_.isNil(amount) && new BigNumber(amount).isGreaterThan(0),
    [amount]
  )
  const isExpiring = useMemo(
    () =>
      expiration - dayjs().valueOf() / 1000 < macros.EXPIRY_NOTICE_TIME &&
      !_.get(times, 'isExpired'),
    [times, expiration]
  )

  const isOver = useMemo(
    () => _.get(times, 'isExpired') && _.get(times, 'isExercised'),
    [times]
  )

  if (type === types.action.pool) {
    return (
      <ActionsPools
        column={column}
        value={value}
        isExpired={_.get(times, 'isExpired')}
        isOver={isOver}
      />
    )
  }

  if (type === types.action.history) {
    return <ActionsHistory column={column} value={value} />
  }

  return (
    <Wrapper size={_.get(column, 'weight')}>
      <Labels>
        {isExpiring ? (
          <Helper value='Option expiring soon' force={HELPER_POSTION}>
            <Label data-component='label-expiration'>
              <IconCalendar />
            </Label>
          </Helper>
        ) : (
          <>
            {_.get(times, 'isExpired') && !_.get(times, 'isExercised') && (
              <Helper value='Option exercising now' force={HELPER_POSTION}>
                <Label data-component='label-exercise'>
                  <IconFlash />
                </Label>
              </Helper>
            )}

            {_.get(times, 'isExpired') && _.get(times, 'isExercised') && (
              <Helper value='Option series ended' force={HELPER_POSTION}>
                <Label data-component='label-ended'>
                  <IconCalendarOver />
                </Label>
              </Helper>
            )}
          </>
        )}
        {isOwned && (
          <Helper value='You have an active position' force={HELPER_POSTION}>
            <Label data-component='label-own'>
              <IconCheck />
            </Label>
          </Helper>
        )}
      </Labels>
      <Visit to={target.builder(value)} data-component='action-visit'>
        <IconArrow />
      </Visit>
    </Wrapper>
  )
}

Actions.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string.isRequired,
    expiration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOf(Object.values(types.action))
  }).isRequired
}

Actions.defaultTypes = {
  data: {
    expiration: null,
    amount: 0,
    type: types.action.invest
  }
}

export default Actions
