import _ from 'lodash'
import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import numeral from 'numeral'
import { Link } from 'react-router-dom'
import IconCalendarBusy from '@material-ui/icons/EventBusyRounded'
import IconCalendarActive from '@material-ui/icons/EventAvailableRounded'
import IconHistory from '@material-ui/icons/HistoryRounded'
import IconWallet from '@material-ui/icons/AccountBalanceWalletRounded'

import { pages, types } from '../../../constants'
import {
  useOwnInvestment,
  useOptionData,
  useOwnBalance,
  useOwnWithdrawbleAmount,
  useOptionPoolPosition
} from '../../../hooks'

import Countdown from './Countdown'

const WrapperPartial = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${props => props.theme.sizes.edge};
`

const Row = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: calc(${props => props.theme.sizes.edge} * 1);
  & > svg {
    font-size: 15pt;
    color: ${props => props.theme.colors.dark};
  }
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;

  & > p {
    font-size: ${props => props.theme.sizes.text};
    color: ${props => props.theme.colors.dark};
    margin: 0;
    line-height: 1.5;
    font-weight: 500;

    & > b {
      font-weight: 700;
    }
    & > a {
      text-decoration: underline;
      font-weight: 700;
      cursor: pointer;
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.small} {
    ${Row} {
      flex-direction: column;
      ${Icon} {
        margin: 0;
        margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
        padding: calc(${props => props.theme.sizes.edge} * 1);
        justify-content: flex-start;
        border-radius: 4px;
        border: 1px solid ${props => props.theme.colors.border};
      }
      padding-bottom: calc(${props => props.theme.sizes.edge} * 1);
      border-bottom: 1px solid ${props => props.theme.colors.border};
      &:last-child {
        border: none;
      }
    }
  }
`

function PositionStatus ({ action }) {
  const isHistoryAvailable = false

  const { item: option } = useOwnInvestment()
  const { value: position } = useOptionPoolPosition(option)
  const { tokenCollateral, tokenUnderlying, times } = useOptionData()
  const { expiration, exerciseToToday, isExercised, isExpired } = times

  const { value: balanceWithdrawable } = useOwnWithdrawbleAmount(option)

  const { value: balanceOptions } = useOwnBalance(_.get(option, 'address'))
  const { value: balanceUnderlying } = useOwnBalance(
    _.get(option, 'underlyingAsset')
  )

  const elements = useMemo(
    () => ({
      investExpired: (
        <p>
          This series expired on {expiration}. You can withdraw{' '}
          <b>
            {numeral(_.get(balanceWithdrawable, '[1]')).format('0,0.[0000]')}{' '}
            {_.get(tokenCollateral, 'symbol')}
          </b>{' '}
          and{' '}
          <b>
            {numeral(_.get(balanceWithdrawable, '[0]')).format('0,0.[0000]')}{' '}
            {_.get(tokenUnderlying, 'symbol')}
          </b>{' '}
          of collateral that hasn’t been converted (exercised) to{' '}
          {_.get(tokenUnderlying, 'symbol')}.
        </p>
      ),
      hedgeExpired: (
        <p>
          This series expired on {expiration}. You are no longer able to
          exercise your right to exchange {_.get(tokenUnderlying, 'symbol')} for{' '}
          {_.get(tokenCollateral, 'symbol')}, with this specific option.
        </p>
      ),
      investExercising: (
        <p>
          The exercising window for this options is open! It will only last for
          a brief period. You will be able to withdraw your unused collateral
          after this time.
        </p>
      ),
      hedgeExercising: (
        <p>
          The exercising window for this options is open! It will only last for
          a brief period. If you wish to exchange your assets, you will be able
          to do it only during this time.
        </p>
      ),
      hedgeExercisingWallet: (
        <p>
          You own <b>{numeral(balanceOptions).format('0,0.[0000]')} options</b>{' '}
          {!new BigNumber(_.get(position, '[0]')).isZero() && (
            <>
              ( + <b>{numeral(_.get(position, '[0]')).format('0,0.[00]')}</b>{' '}
              still locked as pool liquidity)
            </>
          )}{' '}
          and you may exercise them for a maximum of{' '}
          <b>
            {numeral(Math.min(balanceOptions, balanceUnderlying)).format(
              '0,0.[0000]'
            )}{' '}
            {_.get(tokenUnderlying, 'symbol')}
          </b>{' '}
          based on your wallet balance (
          <b>
            {numeral(balanceUnderlying).format('0,0.[0000]')}{' '}
            {_.get(tokenUnderlying, 'symbol')})
          </b>
          .
        </p>
      )
    }),
    [
      expiration,
      balanceOptions,
      balanceUnderlying,
      balanceWithdrawable,
      position,
      tokenCollateral,
      tokenUnderlying
    ]
  )

  return (
    <Wrapper>
      {action === types.action.hedge && isExpired && !isExercised && (
        <Row>
          <Icon>
            <IconWallet />
          </Icon>
          <Content>{elements.hedgeExercisingWallet}</Content>
        </Row>
      )}
      {isExpired && !isExercised && (
        <Row>
          <Icon>
            <IconCalendarActive />
          </Icon>
          <Content>
            {action === types.action.hedge
              ? elements.hedgeExercising
              : elements.investExercising}
            <Countdown time={exerciseToToday} />
          </Content>
        </Row>
      )}

      {action === types.action.hedge && isExercised && (
        <Row>
          <Icon>
            <IconCalendarBusy />
          </Icon>
          <Content>{elements.hedgeExpired}</Content>
        </Row>
      )}

      {action === types.action.invest && isExercised && (
        <Row>
          <Icon>
            <IconCalendarBusy />
          </Icon>
          <Content>{elements.investExpired}</Content>
        </Row>
      )}
      {isHistoryAvailable && (
        <Row>
          <Icon>
            <IconHistory />
          </Icon>
          <Content>
            To view a detailed list of your actions linked to this option, check
            the{' '}
            <Link to={pages.history.builder(_.get(option, 'address'))}>
              transaction history (activity).
            </Link>
            .
          </Content>
        </Row>
      )}
    </Wrapper>
  )
}

PositionStatus.propTypes = {
  action: PropTypes.oneOf(Object.values(types.action))
}
PositionStatus.defaultProps = {
  action: types.action.invest
}

export default PositionStatus
