import _ from 'lodash'
import { useEffect } from 'react'
import Web3 from 'web3'
import Web3Modal from 'web3modal'
import Torus from '@toruslabs/torus-embed'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { NOOP, resolveAddressToENS } from '../../utils'
import { analytics } from '../../vendors'

const options = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      bridge: 'https://bridge.walletconnect.org',
      rpc: {
        1: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
        137: `https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_MATIC_ID}`
      }
    }
  },
  torus: {
    package: Torus,
    options: {
      networkId: 1
    }
  }
}

/**
 *
 * Configuration Helper Functions
 *
 */

export async function attemptToConnect (
  modal,
  setProvider,
  setWeb3Instance,
  reset = NOOP
) {
  try {
    const provider = await modal.connect()
    const set = provider => {
      const instance = new Web3(provider)
      setProvider(provider)
      setWeb3Instance(instance)

      if (
        !_.isNil(window) &&
        _.has(window, 'ethereum') &&
        _.has(window, 'ethereum.autoRefreshOnNetworkChange')
      ) {
        window.ethereum.autoRefreshOnNetworkChange = false
      }
    }

    modal.on('connect', () => {
      analytics.track(e => e.connectWalletChoice, {
        provider: _.get(modal, 'cachedProvider')
      })
    })

    if (provider) {
      provider.on('accountsChanged', () => set(provider))
      provider.on('chainChanged', () => set(provider))

      set(provider)
    }
  } catch (e) {
    console.error('Web3 Modal aTC', e)
    if (_.isNil(e)) await reset()
  }
}

export async function attemptToDisconnect (modal, reset = NOOP) {
  try {
    await modal.clearCachedProvider()
  } catch (e) {
    console.error('Web3 Modal aTD', e)
  } finally {
    reset()
  }
}

/**
 *
 * Configuration Hooks
 *
 */

export function useWeb3Modal (setModal) {
  useEffect(() => {
    setModal(
      new Web3Modal({
        cacheProvider: true,
        providerOptions: options
      })
    )
  }, [setModal])
}

export function useWeb3Provider (modal, setProvider, setWeb3Instance, reset) {
  useEffect(() => {
    const cache = async () => {
      try {
        if (_.get(modal, 'cachedProvider')) {
          await attemptToConnect(modal, setProvider, setWeb3Instance, reset)
        }
      } catch (e) {
        console.error('Web3', e)
        attemptToDisconnect(modal, reset)
      }
    }
    cache()
  }, [modal, setProvider, setWeb3Instance, reset])
}

export function useWeb3Account (web3Instance, setAccount) {
  useEffect(() => {
    const cache = async () => {
      try {
        if (
          _.get(web3Instance, 'eth') &&
          _.get(web3Instance, 'eth.currentProvider')
        ) {
          const list = await web3Instance.eth.getAccounts()
          const networkId = await web3Instance.eth.net.getId()

          if (list && list.length) {
            const address = list[0]
            setAccount({
              address,
              ens: null,
              isResolving: true,
              networkId
            })
            const ens = await resolveAddressToENS(address, web3Instance)
            setAccount({
              address,
              ens,
              isResolving: false,
              networkId
            })
          }
        }
      } catch (e) {
        console.error('Web3', e)
      }
    }
    cache()
  }, [web3Instance, setAccount])
}
