import _ from 'lodash'
import mixpanel from './mixpanel'
import google from './google'
import rollbar from './rollbar'

import { localStorageAccessSync } from '../utils'
import { prefs } from '../constants'

export const analytics = {
  init: () => {
    mixpanel.init()
    google.init()
  },
  track: mixpanel.track
}

export const crashlytics = {
  init: () => {
    rollbar.init()
  },
  log: rollbar.log,
  info: rollbar.info,
  error: rollbar.error
}

export const initialize = () => {
  try {
    const preferences = localStorageAccessSync('preferences', null)
    if (
      !_.isNil(preferences) &&
      [true, 'true'].includes(_.get(preferences, prefs.isAnalyticsEnabled))
    ) {
      analytics.init()
      crashlytics.init()
    }
  } catch (e) {
    console.error(e)
  }
}
