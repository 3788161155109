import _ from 'lodash'
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo
} from 'react'
import { Option } from '../../models'
import { useWeb3Context } from '../Web3'
import { useRefresh } from '../../hooks/atoms'
import { useAddresses } from '../../hooks/utility'
import { useAccount } from '../../hooks/web3'

export const OptionContext = createContext(undefined)

export default function Provider ({ children }) {
  const addresses = useAddresses()

  const options = useOptionsIndexer(addresses.options)
  const archive = useOptionsArchiver(addresses.archive)

  return (
    <OptionContext.Provider value={{ options, archive }}>
      {children}
    </OptionContext.Provider>
  )
}

export function useOptionsContext () {
  return useContext(OptionContext)
}

function useOptionsArchiver (supported = []) {
  const { web3 } = useWeb3Context()
  const { refresh, version } = useRefresh()

  const [requested, setRequested] = useState([])
  const [options, setOptions] = useState(
    {
      list: [],
      refresh
    },
    []
  )

  const retrieve = useCallback(async (address, web3) => {
    try {
      const option = new Option(address, web3)
      await option.init()
      return option
    } catch (error) {
      console.error('Archive', error)
    }
    return null
  }, [])

  const store = useCallback(
    address => {
      try {
        if (
          address &&
          web3 &&
          web3.currentProvider &&
          requested.find(
            element => element.address === address && !element.isResolved
          )
        ) {
          setRequested(prev =>
            prev.map(request =>
              request.address === address
                ? { ...request, isResolved: true }
                : request
            )
          )
          ;(async () => {
            const option = await retrieve(address, web3)
            setOptions(prev => ({
              ...prev,
              list: [...prev.list, option]
            }))
          })()
        }
      } catch (error) {
        console.error('Archive', error)
      }
      return null
    },
    [retrieve, setOptions, web3, requested]
  )

  const register = useCallback(address => {
    setRequested(prev =>
      prev.map(r => r.address).includes(address)
        ? prev
        : [...prev, { address, isResolved: false }]
    )
  }, [])

  useEffect(() => {
    setOptions(prev => ({ ...prev, list: [], count: 0 }))
    setRequested([])
  }, [version, setOptions, setRequested])

  useEffect(() => {
    for (let i = 0; i < requested.length; i++) {
      if (!requested[i].isResolved) store(requested[i].address)
    }
  }, [requested, store])

  const result = useMemo(
    () => ({
      ...options,
      requested,
      isLoading: supported.length ? options.list.length === 0 : false,
      count: requested.length,
      register,
      supported
    }),
    [options, register, requested, supported]
  )

  return result
}

function useOptionsIndexer (addresses = []) {
  const { web3 } = useWeb3Context()
  const { isExpected, networkId } = useAccount()
  const { refresh, version } = useRefresh()

  const initial = useMemo(
    () => ({
      list: [],
      isLoading: isExpected,
      requested: addresses,
      count: addresses.length,
      refresh
    }),
    [addresses, refresh, isExpected]
  )

  const [options, setOptions] = useState(initial)

  const retrieve = useCallback(
    addresses => {
      if (addresses && web3 && web3.currentProvider) {
        setOptions(prev => ({ ...prev, isLoading: true }))
        ;(async () => {
          const web3NetworkId = await web3.eth.net.getId()
          if (
            !_.isNil(networkId) &&
            !_.isNil(web3NetworkId) &&
            _.toString(networkId) === _.toString(web3NetworkId)
          ) {
            Promise.all(
              addresses
                .map(address => new Option(address, web3))
                .map(option => option.init())
            )
              .then(instances => {
                setOptions(prev => ({
                  ...prev,
                  list: instances.filter(instance => !_.isNil(instance)),
                  isLoading: false
                }))
              })
              .catch(error => {
                console.error('Indexer', error)
                setOptions({
                  ...initial,
                  isLoading: false
                })
              })
          }
        })()
      }
    },
    [web3, initial, networkId]
  )

  useEffect(() => {
    retrieve(addresses)
  }, [retrieve, addresses, version])

  return { ...options, version }
}
