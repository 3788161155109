import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import BigNumber from 'bignumber.js'
import numeral from 'numeral'
import { constants, ExternalLink } from 'shared'
import IconArrow from '@material-ui/icons/ArrowRight'
import IconCalendar from '@material-ui/icons/SendRounded'
import IconParty from '@material-ui/icons/FavoriteRounded'
import { GUARDED_LAUNCH } from '../../../../../../constants'
import { useOptions } from '../../../../../../hooks'

const WrapperPartial = styled.div`
  width: 100%;
  position: relative;
  grid-column: span 2;
`

const Box = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  padding: calc(${props => props.theme.sizes.edge} * 1);
  background: ${props => props.theme.gradients.dark};
  border-radius: ${props => props.theme.sizes.edge};
  overflow: hidden;
  & > * {
    z-index: 10;
  }
  &:before {
    content: '';
    position: absolute;
    right: -100px;
    top: -160px;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    z-index: 30;
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.03);
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  & > svg {
    font-size: 18pt;
    color: ${props => props.theme.colors.white};
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Tracker = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`

const TrackerSupply = styled.p`
  font-size: 22pt;
  font-weight: 800;
  color: ${props => props.theme.colors.white};
  text-align: left;
  margin: 0;
  margin-bottom: 4px;
`

const TrackerCap = styled.p`
  font-size: 11pt;
  font-weight: 600;
  color: ${props => props.theme.colors.white};
  text-align: left;
  margin: 0;
  margin-bottom: 4px;
  &:before {
    content: '/';
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  padding: calc(${props => props.theme.sizes.edge} * 3 / 2) 0;
`

const Description = styled.div`
  & > p {
    font-size: 11pt;
    font-weight: 500;
    color: ${props => props.theme.colors.white};
    margin: 0;
    line-height: 1.5;
    width: 100%;
    padding-left: 2px;
  }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  & > * {
    margin-right: ${props => props.theme.sizes.edge};
    &:last-child {
      margin-right: 0;
    }
  }
`

const Action = styled(ExternalLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 8px 8px 12px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.4);
  transition: background-color 150ms;
  & > svg {
    font-size: 12pt;
    margin-left: 6px;
    color: ${props => props.theme.colors.white};
  }

  & > p {
    font-size: 10pt;
    color: ${props => props.theme.colors.white};
    font-weight: 600;
    margin: 0;
  }
  &:hover,
  &:active {
    background-color: rgba(255, 255, 255, 0.1);
    transition: background-color 250ms;
  }
`

const Wrapper = styled(WrapperPartial)`
  opacity: 1;
  transform: translateY(0);
  transition: transform 400ms, opacity 400ms;

  &[data-loading='true'] {
    opacity: 0;
    transform: translateY(0);
    transition: transform 400ms, opacity 400ms;
  }

  &[data-status='1'] ${Box} {
    background: ${props => props.theme.gradients.primary};
    &:before {
      background-color: rgba(255, 255, 255, 0.08);
    }

    b {
      font-weight: 600;
    }
  }

  ${props => props.theme.medias.medium} {
    grid-column: span 1;
    ${Box} {
      border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
      padding: calc(${props => props.theme.sizes.edge} * 1);
      ${Header} {
        padding-bottom: calc(${props => props.theme.sizes.edge} * 1);
      }
      ${Content} {
        padding: 0;
        align-items: center;
      }

      ${TrackerSupply} {
        font-size: 11pt;
      }
      ${TrackerCap} {
        font-size: 11pt;
      }

      ${IconWrapper} {
        height: 48px;
        width: 48px;
        margin-right: calc(${props => props.theme.sizes.edge} * 1);
      }

      ${Footer} {
        display: none;
      }
    }
  }
`

function CapGuard ({ isLoading }) {
  const { list: options, isLoading: isLoadingOptions } = useOptions()

  const [cap, supply] = useMemo(() => {
    let [cap, supply] = [new BigNumber(0), new BigNumber(0)]
    if (!options || isLoadingOptions || isLoading) return [cap, supply]
    options.forEach(option => {
      try {
        const capOfOptions = option.cap
        const supplyOfOptions = option.supply
        const strikePrice = option.strikePrice
        const pool = option.pool

        const capOfStablecoins = pool.cap
        const supplyOfStablecoins = pool.supply
        const tokenADecimals = pool.tokenADecimals
        const tokenBDecimals = pool.tokenBDecimals

        const capOfOptionsUSD = new BigNumber(capOfOptions).div(
          new BigNumber(10).pow(tokenADecimals)
        )
        const capOfCollateralUSD = new BigNumber(strikePrice).times(
          capOfOptionsUSD
        )

        const capOfStablecoinsUSD = new BigNumber(capOfStablecoins).div(
          new BigNumber(10).pow(tokenBDecimals)
        )

        const supplyOfOptionsUSD = new BigNumber(supplyOfOptions).div(
          new BigNumber(10).pow(tokenADecimals)
        )
        const supplyOfCollateralUSD = new BigNumber(strikePrice).times(
          supplyOfOptionsUSD
        )

        const supplyOfStablecoinsUSD = new BigNumber(supplyOfStablecoins)

        cap = cap.plus(capOfCollateralUSD).plus(capOfStablecoinsUSD)
        supply = supply.plus(supplyOfCollateralUSD).plus(supplyOfStablecoinsUSD)
      } catch (error) {
        console.error(error)
      }
    })

    return [cap, supply]
  }, [options, isLoadingOptions, isLoading])

  return (
    <Wrapper data-loading={isLoading} data-status={GUARDED_LAUNCH.status}>
      <Box>
        <Header>
          <IconWrapper>
            {GUARDED_LAUNCH.status === 1 ? <IconCalendar /> : <IconParty />}
          </IconWrapper>
          <Tracker>
            <TrackerSupply>
              {numeral(supply || 0).format('$0,0.[00]')}
            </TrackerSupply>
            <TrackerCap>{numeral(cap || 0).format('$0,0.[00]')}</TrackerCap>
          </Tracker>
        </Header>
        <Content>
          <Description>
            {GUARDED_LAUNCH.status === 1 ? (
              <p>
                We've set a maximum liquidity goal for our demo release to gain
                insight while keeping everything safe.
              </p>
            ) : (
              <p>
                Thank you for helping us reach the demo release goal. No more{' '}
                <b>new</b> liquidity will be accepted. You can still interact
                with the options you hold or pooled.
              </p>
            )}
          </Description>
        </Content>
        <Footer>
          <Action to={constants.links.twitter.primary.link}>
            <p>View latest updates</p>
            <IconArrow />
          </Action>
        </Footer>
      </Box>
    </Wrapper>
  )
}

CapGuard.propTypes = {
  isLoading: PropTypes.bool
}

CapGuard.defaultProps = {
  isLoading: true
}

export default CapGuard
