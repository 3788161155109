import _ from 'lodash'
import BigNumber from 'bignumber.js'
import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import SpecialButton from '../../../../../shared/SpecialButton'
import { Structure, Section, Summary } from '../../../../../shared/Tabs'
import {
  Form as FormPartial,
  Step,
  Label,
  Input
} from '../../../../../shared/Form'

import { tabs } from '../../../../../../constants'
import reducers from '../../../../../../reducers'
import machines, { guards } from '../../../../../../machines'
import {
  useOwnBalance,
  useOptionData,
  useFormValidator,
  useFormAssetAllowance,
  useTokenAllowanceForLater
} from '../../../../../../hooks'
import { analytics } from '../../../../../../vendors'
import { toSignificantInput } from '../../../../../../utils'

import Incentive from './Incentive'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Form = styled(FormPartial)`
  max-width: 538px;
  div[data-step='actions'] {
    max-width: 100%;
    & > div:first-child {
      min-width: 226px;
    }
  }
`

const Info = styled.p``

function initialize ({
  balance,
  tokenUnderlying,
  tokenCollateral,
  elements,
  dispatch
}) {
  dispatch([], 'RESET', [elements.allowance])
  dispatch([
    elements.underlying,
    {
      token: [
        _.get(tokenUnderlying, 'symbol'),
        _.get(tokenCollateral, 'symbol')
      ]
    }
  ])
  dispatch([
    elements.collateral,
    {
      token: _.get(tokenCollateral, 'symbol'),
      max: balance
    }
  ])
}

function safeCompute ({ option, underlying = null, collateral = null }) {
  if (
    (_.isNil(underlying) || _.isEmpty(_.toString(underlying))) &&
    (_.isNil(collateral) || _.isEmpty(_.toString(collateral)))
  ) {
    return [null, null]
  }

  if (
    _.isNil(option) ||
    _.isNil(option.getOptionAmount) ||
    _.isNil(option.getStrikeAmount)
  ) {
    console.error('Misconfigured option model.')
    return [null, null]
  }

  if (underlying === null) {
    return {
      underlying: toSignificantInput(
        option.getOptionAmount(collateral) || 0,
        BigNumber.ROUND_DOWN
      ),
      collateral
    }
  } else {
    return {
      underlying,
      collateral: toSignificantInput(
        option.getStrikeAmount(underlying) || 0,
        BigNumber.ROUND_UP
      )
    }
  }
}

function Mint () {
  const {
    tokenUnderlying,
    tokenCollateral,
    item: option,
    isLoading: isOptionLoading,
    version
  } = useOptionData()

  const {
    value: balance,
    isLoading: isBalanceCollateralLoading
  } = useOwnBalance(_.get(option, 'strikeAsset'))

  const { elements, state, dispatch } = reducers.mint.useReducer()
  const machine = machines.mint.useMachine()
  const setup = useTokenAllowanceForLater()

  /**
   *
   * Form setters and state updaters
   *
   */

  const doAllowanceUpdate = useCallback(
    ({ isAllowed, isLoading }) =>
      dispatch([elements.allowance, { token: isAllowed, isLoading }]),
    [elements, dispatch]
  )

  const onChangeAmount = useCallback(
    (underlying = null, collateral = null) => {
      const values = safeCompute({ option, underlying, collateral })

      dispatch([
        elements.collateral,
        {
          value: values.collateral,
          warning:
            _.toString(values.collateral) !== ''
              ? guards.isAmountValid({
                value: values.collateral,
                max: balance
              })
              : null
        }
      ])
      dispatch([elements.underlying, { value: values.underlying }])
    },
    [dispatch, elements, balance, option]
  )

  const onPrimaryRefocus = useCallback(
    isCollateralPrimary => {
      if (
        isCollateralPrimary &&
        _.get(state, `${elements.collateral}.isPrimary`) === true
      ) {
        return
      }
      dispatch([
        elements.collateral,
        {
          isPrimary: isCollateralPrimary
        }
      ])

      dispatch([
        elements.underlying,
        {
          isPrimary: !isCollateralPrimary
        }
      ])
    },
    [dispatch, elements, state]
  )

  const onTransact = useCallback(() => {
    analytics.track(e => e.transactionMintTrigger)
    machine.send(machine.events.save, {
      payload: {
        state,
        option,
        setup
      }
    })
  }, [machine, state, option, setup])

  /**
   *
   * Automated effects
   *
   */

  const {
    doApprove: doAllowanceApprove,
    doRefresh: doAllowanceRefresh
  } = useFormAssetAllowance({
    amount: _.get(state, 'collateral.value'),
    tokenAddress: _.get(option, 'strikeAsset'),
    onUpdate: doAllowanceUpdate
  })

  useEffect(() => {
    if (!isOptionLoading && !isBalanceCollateralLoading) {
      initialize({
        balance,
        elements,
        dispatch,
        tokenCollateral,
        tokenUnderlying,
        version
      })
      doAllowanceRefresh()
    }
  }, [
    balance,
    tokenUnderlying,
    tokenCollateral,
    elements,
    dispatch,
    version,
    doAllowanceRefresh,

    isOptionLoading,
    isBalanceCollateralLoading
  ])

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  return (
    <Structure hash={tabs.pool.mint}>
      <Wrapper>
        <Section
          title='Create (mint) idle options tokens'
          isContained
          isLoading={isOptionLoading}
          isDisabled={[
            machine.states.validate,
            machine.states.process
          ].includes(machine.current.value)}
        >
          <Form>
            <Step>
              <Label>Step 1. Collateral to lock</Label>
              <Input.Amount
                {...state.collateral}
                placeholder='Enter amount'
                onChange={e => onChangeAmount(null, _.get(e, 'target.value'))}
                onClick={() => onPrimaryRefocus(true)}
              />
              <Step>
                <Info style={{ whiteSpace: 'nowrap' }}>Exchangeable for</Info>
                <Input.Amount
                  placeholder='Enter amount'
                  {...state.underlying}
                  onChange={e => onChangeAmount(_.get(e, 'target.value'), null)}
                  onClick={() => onPrimaryRefocus(false)}
                />
              </Step>
            </Step>
            <Summary
              index={2}
              context={tabs.pool.mint}
              data={{
                tokenUnderlying,
                tokenCollateral,
                state
              }}
              allow={
                <SpecialButton.AllowToken
                  amount={_.get(state, 'collateral.value')}
                  title={`Allow ${_.get(tokenCollateral, 'symbol')}`}
                  isAllowed={_.get(state, 'allowance.token')}
                  isLoading={_.get(state, 'allowance.isLoading')}
                  onApprove={doAllowanceApprove}
                />
              }
              transact={
                <SpecialButton.Transact
                  token={state.collateral.token}
                  title='Mint Options'
                  isDisabled={!isFormValid}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </Section>
        <Incentive />
      </Wrapper>
    </Structure>
  )
}

export default Mint
