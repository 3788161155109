import _ from 'lodash'
import React, { useMemo } from 'react'
import numeral from 'numeral'
import BigNumber from 'bignumber.js'
import IconMarket from '@material-ui/icons/TimelineRounded'
import { useHistory } from 'react-router-dom'
import { layout } from '../../components/shared/Table'
import { pages, types } from '../../constants'
import { useOwnHedges } from '../option'
import { useMarketPrices } from '../price'
import { interpretTimeWindows, sortOptionsTableByExpiration } from '../../utils'

export default function useOwnHedgeTable () {
  const history = useHistory()

  const { list: options, isLoading } = useOwnHedges()
  const { list: prices } = useMarketPrices()

  const columns = useMemo(
    () => [
      {
        title: 'Option',
        layout: layout.PodPair,
        subtitle: 'Underlying:Collateral',
        weight: 3
      },
      {
        title: 'Strike Price',
        layout: layout.Price,
        subtitle: (
          <>
            Market Price <IconMarket />
          </>
        ),
        weight: 2
      },
      {
        title: 'Hedged',
        layout: layout.TextDeck,
        subtitle: 'Options bought',
        weight: 2
      },
      {
        title: 'Expiration',
        layout: layout.Timestamp,
        weight: 2
      },
      {
        title: 'Type',
        layout: layout.Text,
        weight: 2
      },
      {
        title: 'Actions',
        layout: layout.Actions,
        weight: 3
      }
    ],
    []
  )

  const instructions = useMemo(
    () => ({
      onRowClick: params => {
        if (_.get(params, 'id')) {
          history.push(pages.transactionHedge.builder(_.get(params, 'id')))
        }
      }
    }),
    [history]
  )
  const rows = useMemo(
    () =>
      options
        .map(option => {
          const underlying = _.get(
            option,
            'underlyingAssetSymbol'
          ).toUpperCase()
          const collateral = _.get(option, 'strikeAssetSymbol').toUpperCase()

          const market = _.get(prices, underlying)

          const amount = new BigNumber(
            _.get(
              options.find(
                hedge => _.get(hedge, 'address') === _.get(option, 'address')
              ),
              'balance'
            )
          ).toNumber()

          const times = interpretTimeWindows(option)

          return {
            id: _.get(option, 'address'),
            cells: [
              {
                value: [underlying, collateral],
                type: types.action.hedge
              },
              {
                value: numeral(_.get(option, 'strikePrice')).format(
                  '$0.[0000]'
                ),
                market: numeral(market).format('$0.[00]')
              },

              {
                value: `${numeral(amount).format('0.[0000]')} ${underlying}`,
                subtitle: `${numeral(amount).format('0.[0000]')} options`
              },
              {
                value: _.get(option, 'expiration')
              },
              {
                value: 'Put'
              },
              {
                times,
                value: _.get(option, 'address'),
                expiration: _.get(option, 'expiration'),
                amount,
                type: types.action.hedge,
                source: pages.dashboard.builder()
              }
            ]
          }
        })
        .sort(sortOptionsTableByExpiration),
    [options, prices]
  )

  return {
    data: {
      columns,
      rows,
      instructions,
      /** Cosmetics */
      isLoading,
      empty: 2
    },
    info: {}
  }
}
