import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import BigNumber from 'bignumber.js'

const WrapperPartial = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: ${props => props.theme.sizes.edge};
`
const Boxes = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 8px;
    &:last-of-type {
      margin: 0;
    }
  }
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${props => props.theme.styles.boxShadowInset};
  border: 1px solid ${props => props.theme.colors.platformMedium};
  border-radius: 6px;
  min-width: 50px;
  height: 70px;
  & > p {
    font-size: 18pt;
    color: ${props => props.theme.colors.middle};
    margin: 0;
    font-weight: 700;
    font-family: monospace;
  }
`

const Separator = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  & > p {
    position: absolute;
    content: ':';
    font-size: 18pt;
    margin: 0;
    color: ${props => props.theme.colors.content};
    font-weight: 700;
  }
`

const Group = styled.div`
  display: flex;
  align-items: center;
  &:last-child {
    ${Separator} {
      display: none;
    }
  }
`
const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.small} {
    ${Box} {
      min-width: 30px;
      height: 50px;
      & > p {
        font-size: 12pt;
      }
    }
    ${Separator} {
      & > p {
        font-size: 12pt;
      }
    }
  }
`

function breakdown ({ time: unsanitized }) {
  const time = new BigNumber(unsanitized)
  const hours = time.dividedToIntegerBy(60 * 60)
  const minutes = time.minus(hours.multipliedBy(60 * 60)).dividedToIntegerBy(60)
  const seconds = time
    .minus(hours.multipliedBy(60 * 60))
    .minus(minutes.multipliedBy(60))

  if (time.isZero() || _.isNil(time)) return [0, '00', '00', '00']

  return [
    time.toNumber(),
    numeral(hours.toNumber()).format('00'),
    numeral(minutes.toNumber()).format('00'),
    numeral(seconds.toNumber()).format('00')
  ]
}

function Countdown ({ time }) {
  const [values, setValues] = useState([time, '00', '00', '00'])

  useEffect(() => {
    setValues(prev => breakdown({ time }))
    const interval = setInterval(() => {
      if (!_.isNil(time)) {
        setValues(prev =>
          breakdown({ time: prev[0] > 0 ? prev[0] - 1 : prev[0] + 1 })
        )
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [time, setValues])

  if (_.isNil(time)) return <></>

  return (
    <Wrapper>
      {values.length === 4 &&
        !_.isNil(values[0]) &&
        _.isFinite(values[0]) &&
        values.map((value, index) => {
          if (index === 0) return null
          return (
            <Group key={index + ' - ' + value}>
              <Boxes>
                {String(value)
                  .split('')
                  .map((digit, index) => (
                    <Box key={index + ' - ' + digit}>
                      <p>{digit}</p>
                    </Box>
                  ))}
              </Boxes>
              <Separator>
                <p>:</p>
              </Separator>
            </Group>
          )
        })}
    </Wrapper>
  )
}

Countdown.propTypes = {
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
Countdown.defaultProps = {
  time: null
}

export default Countdown
