import _ from 'lodash'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import OptionPool from './OptionPool'
import {
  ERC20ABI,
  OptionPutABI,
  ConfigurationManagerABI,
  CapProviderABI
} from '../contracts'
import {
  getAPR,
  isEth,
  isMatic,
  NOOP,
  isOptionExpired,
  isEthereumChain,
  isPolygonChain,
  getAddresses
} from '../utils'
import { macros } from '../constants'

const OptionType = {
  0: 'Put',
  1: 'Call'
}

/**
 * A number, or an instance of BigNumber.
 * @typedef {(number|BigNumber)} NumberLike
 */

/**
 * A string of the type address (started with 0x).
 * @typedef {(string)} address
 */

/**
 * @typedef {object} OptionPoolData
 * @property {number[]} liquidity
 * @property {number[]} position
 * @property {number[]} balance
 */

/**
 * The constant values of an option
 * @typedef {object} OptionData
 * @property {string} symbol
 * @property {string} name
 * @property {number} decimals
 * @property {string} type
 * @property {string} apr
 * @property {number} expiration
 * @property {string} expirationDate
 * @property {string} expirationTime
 * @property {string} underlyingAsset
 * @property {string} underlyingAssetSymbol
 * @property {number} underlyingAssetDecimals
 * @property {string} strikeAsset
 * @property {string} strikeAssetSymbol
 * @property {number} strikeAssetDecimals
 * @property {BigNumber} strikePrice
 * @property {number} strikePriceDecimals
 * @property {number} balance // Provided through a separate query
 * @property {OptionPool} pool
 * @property {OptionPoolData} poolData // Provided through a separate query
 *
 */

/**
 * Class representing a Option
 * @class
 */
class Option {
  /**
   * The address where the option is deployed
   * @type {string}
   * @private
   */
  #address

  /**
   * The constant values of an option
   * @type {OptionData}
   * @private
   */
  #data = {}

  /**
   * Instance of web3
   * @type {Web3}
   * @private
   */
  #web3

  constructor (address, web3) {
    this.#address = address
    this.#web3 = web3
  }

  get address () {
    return this.#address
  }

  get name () {
    return this.#data.name
  }

  get symbol () {
    return this.#data.symbol
  }

  get decimals () {
    return this.#data.decimals
  }

  get type () {
    return this.#data.type
  }

  get apr () {
    return this.#data.apr
  }

  get expiration () {
    return this.#data.expiration
  }

  get exercising () {
    return this.#data.exercising
  }

  get expirationDate () {
    return this.#data.expirationDate
  }

  get expirationTime () {
    return this.#data.expirationTime
  }

  get underlyingAsset () {
    return this.#data.underlyingAsset
  }

  get underlyingAssetSymbol () {
    return this.#data.underlyingAssetSymbol
  }

  get underlyingAssetDecimals () {
    return this.#data.underlyingAssetDecimals
  }

  get strikeAsset () {
    return this.#data.strikeAsset
  }

  get strikeAssetSymbol () {
    return this.#data.strikeAssetSymbol
  }

  get strikeAssetDecimals () {
    return this.#data.strikeAssetDecimals
  }

  get strikePrice () {
    return this.#data.strikePrice
  }

  get strikePriceDecimals () {
    return this.#data.strikePriceDecimals
  }

  get pool () {
    return this.#data.pool
  }

  get cap () {
    return this.#data.cap
  }

  get supply () {
    return this.#data.supply
  }

  async init () {
    try {
      const optionContract = new this.#web3.eth.Contract(
        OptionPutABI,
        this.#address
      )
      const parseInt = value => Number.parseInt(value)

      const [
        name,
        symbol,
        decimals,
        optionType,
        exercisingEnd,
        underlyingAsset,
        underlyingAssetDecimals,
        strikeAsset,
        strikeAssetDecimals,
        strikePrice,
        strikePriceDecimals,
        exercisingStart
      ] = await Promise.all([
        optionContract.methods.name().call(),
        optionContract.methods.symbol().call(),
        optionContract.methods
          .decimals()
          .call()
          .then(parseInt),
        optionContract.methods.optionType().call(),
        optionContract.methods
          .expiration()
          .call()
          .then(parseInt),
        optionContract.methods.underlyingAsset().call(),
        optionContract.methods
          .underlyingAssetDecimals()
          .call()
          .then(parseInt),
        optionContract.methods.strikeAsset().call(),
        optionContract.methods
          .strikeAssetDecimals()
          .call()
          .then(parseInt),
        optionContract.methods.strikePrice().call(),
        optionContract.methods
          .strikePriceDecimals()
          .call()
          .then(parseInt),
        optionContract.methods.startOfExerciseWindow().call()
      ])

      const [underlyingAssetSymbol, strikeAssetSymbol] = await getSymbols(
        this.#web3,
        await this.#web3.eth.net.getId(),
        [underlyingAsset, strikeAsset]
      )

      /**
       * Contracts: ExpirationDate = DurationOfTrading + ExerciseWindow
       * App: We will conside 'expiration' to be equal to durationOfWindow, while 'exercise' is the window after 'expiration'
       */

      const expiration = exercisingStart /** App: Ends when exercising starts */
      const exercising = exercisingEnd /** App: Ends when exercising stops */

      this.#data = {
        name,
        symbol,
        decimals,
        expiration,
        exercising,
        underlyingAsset: String(underlyingAsset),
        underlyingAssetSymbol,
        underlyingAssetDecimals,
        strikeAsset: String(strikeAsset),
        strikeAssetSymbol,
        strikeAssetDecimals,
        strikePrice: new BigNumber(strikePrice)
          .dividedBy(10 ** strikePriceDecimals)
          .toNumber(),
        strikePriceDecimals,
        expirationDate: moment(expiration * 1000).format('ll'),
        expirationTime: moment(expiration * 1000).format('LTS'),
        type: OptionType[optionType],
        balance: null,
        poolData: {
          liquidity: null,
          position: null,
          balance: null
        },
        /** Cap size will be in number of options that can be minted */
        cap: null,
        supply: null
      }

      this.#data.pool = await OptionPool.fromOption(this, this.#web3)

      if (isOptionExpired({ expiration })) {
        this.#data.apr = macros.RESTRICTED_APR
      } else {
        this.#data.apr = getAPR({
          premium: await this.#data.pool.getSellingPrice(0.01),
          expiration
        })
      }

      this.#data.cap = await this.getCapSize()
      this.#data.supply = await this.getSupply()

      this.#web3.eth.defaultAccount = await this._getDefaultAccount()

      return this
    } catch (error) {
      console.error('Option Model', error)
      return null
    }
  }

  /**
   * Returns the required amount of strike tokens to mint a given amount of options
   * @param {number|string} optionAmount
   * @return {string}
   */
  getStrikeAmount (optionAmount) {
    const { strikePrice, strikeAssetDecimals } = this.#data

    return new BigNumber(optionAmount)
      .multipliedBy(strikePrice)
      .toPrecision(strikeAssetDecimals)
  }

  /**
   * Return the amount of options generated by inputing the amount of strike amount
   * @param {number|string} strikeAmount
   * @return {string}
   */
  getOptionAmount (strikeAmount) {
    const { strikePrice, decimals } = this.#data
    return new BigNumber(strikeAmount).dividedBy(strikePrice).toFixed(decimals)
  }

  /**
   * Check owner locked Strike Asset(collateral)
   * @param {address} owner
   * @return {Promise<string>}
   */
  async getLockedCollateral (owner) {
    const optionContract = new this.#web3.eth.Contract(
      OptionPutABI,
      this.#address
    )
    const lockedBalance = await optionContract.methods
      .mintedOptions(owner)
      .call()

    if (_.isNil(lockedBalance)) return '0'
    return new BigNumber(lockedBalance)
      .multipliedBy(this.#data.strikePrice)
      .dividedBy(10 ** this.#data.decimals)
  }

  /**
   * Check owner minted options
   * @param {address} owner
   * @return {Promise<string>}
   */
  async getMintedOptions (owner) {
    const optionContract = new this.#web3.eth.Contract(
      OptionPutABI,
      this.#address
    )
    const minted = await optionContract.methods.mintedOptions(owner).call()

    return new BigNumber(minted).dividedBy(10 ** this.#data.decimals).toNumber()
  }

  /**
   * Check owner withdrawabe amount
   * @param {address} owner
   * @return {Promise<[number, number]>} - [underlying, collateral]
   */
  async getSellerWithdrawAmounts (owner) {
    const optionContract = new this.#web3.eth.Contract(
      OptionPutABI,
      this.#address
    )

    const amounts = await optionContract.methods
      .getSellerWithdrawAmounts(owner)
      .call()

    const withdrawable = [
      _.get(amounts, 'strikeAmount'),
      _.get(amounts, 'underlyingAmount')
    ]

    return [
      new BigNumber(_.get(withdrawable, '[1]'))
        .dividedBy(10 ** this.#data.underlyingAssetDecimals)
        .toNumber(), // underlying
      new BigNumber(_.get(withdrawable, '[0]'))
        .dividedBy(10 ** this.#data.strikeAssetDecimals)
        .toNumber() // collateral
    ]
  }

  /**
   * Exercise if it's allowed to lock an amount of strike asset
   * @param {number} optionAmount
   * @return {Promise<boolean>}
   */
  async exerciseExactAmount (optionAmount, callback = NOOP) {
    const optionContract = new this.#web3.eth.Contract(
      OptionPutABI,
      this.#address
    )
    const amount = new BigNumber(optionAmount).multipliedBy(10 ** this.decimals)
    const networkId = await this.#web3.eth.net.getId()

    if (
      (isEth(this.underlyingAsset, networkId) && isEthereumChain(networkId)) ||
      (isMatic(this.underlyingAsset, networkId) && isPolygonChain(networkId))
    ) {
      return optionContract.methods.exerciseEth().send(
        {
          from: this.#web3.eth.defaultAccount,
          value: amount.toFixed(0).toString()
        },
        callback
      )
    } else {
      return optionContract.methods
        .exercise(amount.toFixed(0).toString())
        .send({ from: this.#web3.eth.defaultAccount }, callback)
    }
  }

  /**
   * Withdraw the assets locked in the contract
   * @return {Promise<void>}
   */
  async withdraw (callback = NOOP) {
    const optionContract = new this.#web3.eth.Contract(
      OptionPutABI,
      this.#address
    )
    return optionContract.methods.withdraw().send(
      {
        from: this.#web3.eth.defaultAccount
      },
      callback
    )
  }

  /**
   * Unwind the option, releasing the equivalent collateral
   * @param {number} optionAmount
   * @return {Promise<void>}
   */
  unwind (optionAmount, callback = NOOP) {
    const amount = new BigNumber(optionAmount).multipliedBy(10 ** this.decimals)
    const optionContract = new this.#web3.eth.Contract(
      OptionPutABI,
      this.#address
    )

    return optionContract.methods.unmint(amount.toFixed(0).toString()).send(
      {
        from: this.#web3.eth.defaultAccount
      },
      callback
    )
  }

  async _getDefaultAccount () {
    try {
      const accounts = await this.#web3.eth.getAccounts()
      return accounts[0]
    } catch (e) {
      console.error('Account', e)
      return null
    }
  }

  /**
   * Returns the Exchange Pool for the Option instance
   * @return {OptionPool}
   */
  getPool () {
    return this.#data.pool
  }

  async getSupply () {
    const optionContract = new this.#web3.eth.Contract(
      OptionPutABI,
      this.#address
    )

    return optionContract.methods.totalSupply().call()
  }

  async getCapSize () {
    const networkId = await this.#web3.eth.net.getId()
    const { configurationManager } = getAddresses(networkId)

    if (_.isNil(configurationManager)) return new BigNumber(0)

    const managerContract = new this.#web3.eth.Contract(
      ConfigurationManagerABI,
      configurationManager
    )

    const capProviderAddress = await managerContract.methods
      .getCapProvider()
      .call()

    if (_.isNil(capProviderAddress)) return new BigNumber(0)

    const capContract = new this.#web3.eth.Contract(
      CapProviderABI,
      capProviderAddress
    )

    const capSize = await capContract.methods.getCap(this.#address).call()

    if (_.isNil(capSize)) return new BigNumber(0)

    return capSize
  }
}

export default Option

function getSymbols (web3, networkId, addresses) {
  try {
    return Promise.all(
      addresses.map(address => {
        if (isEth(address, networkId)) {
          return Promise.resolve('ETH')
        }
        if (isMatic(address, networkId)) {
          return Promise.resolve('MATIC')
        }
        const contract = new web3.eth.Contract(ERC20ABI, address)
        return contract.methods.symbol().call()
      })
    )
  } catch (e) {
    console.error('Symbols', e)
    return null
  }
}
