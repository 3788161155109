import React from 'react'
import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import { pages, tabs } from '../../../../../../../constants'

const Wrapper = styled.div`
  margin-left: ${props => props.theme.sizes.edge};
  width: calc(100% - 2 * ${props => props.theme.sizes.edge});
  position: relative;
  overflow: hidden;
  border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
  background: ${props => props.theme.gradients.dark};
  padding: calc(${props => props.theme.sizes.edge} * 3 / 2);
`

const Data = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    z-index: 10;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    border: 2px dotted ${props => props.theme.colors.white};
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: calc(${props => props.theme.sizes.edge} * 2);
  & > p {
    margin: 0;
    font-size: 10pt;
    font-weight: 600;
    color: ${props => props.theme.colors.white};
    a {
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }
`

function Incentive () {
  const { id } = useParams()
  return (
    <Wrapper>
      <Data>
        <Title>
          <p>
            For additional option tokens, create new idle ones in the{' '}
            <Link to={pages.transactionPool.builder(id, tabs.pool.mint)}>
              Mint
            </Link>{' '}
            tab with new collateral.
          </p>
        </Title>
      </Data>
    </Wrapper>
  )
}

export default Incentive
