import _ from 'lodash'
import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import numeral from 'numeral'
import { useToasts } from 'react-toast-notifications'
import useAtomicMachine from './atoms'
import * as guards from './guards'
import { useHardRefresh, useModal } from '../hooks'
import { modals } from '../constants'

export function useMachine () {
  const { addToast, removeAllToasts } = useToasts()
  const { refresh } = useHardRefresh()
  const { setOpen, updateData } = useModal(modals.transaction)

  const onPrepare = useCallback(async ({ context }) => {
    const state = _.get(context, 'payload.state')

    const form = await guards.booleanize(() =>
      guards.isFormValid({ value: state, soft: true })
    )

    if (!form) return false

    return true
  }, [])

  const onValidate = useCallback(
    async ({ context }) => {
      const state = _.get(context, 'payload.state')

      const form = await guards.interpret(
        () =>
          guards.isFormValid({
            value: state
          }),
        addToast
      )

      if (form[0] === false) throw new Error(form[1])

      const collateral = await guards.interpret(
        () =>
          guards.isAmountValid({
            value: _.get(state, 'collateral.value'),
            max: _.get(state, 'collateral.max'),
            min: null
          }),
        addToast
      )

      if (collateral[0] === false) throw new Error(collateral[1])

      const options = await guards.interpret(
        () =>
          guards.isAmountValid({
            value: _.get(state, 'options.value'),
            max: _.get(state, 'options.max'),
            min: null
          }),
        addToast
      )

      if (options[0] === false) throw new Error(options[1])

      const zeros = await guards.interpret(() => {
        if (
          new BigNumber(_.get(state, 'collateral.value')).isZero() &&
          new BigNumber(_.get(state, 'options.value')).isZero()
        ) {
          return guards.policy.system.values()
        }
        return null
      }, addToast)

      if (zeros[0] === false) throw new Error(zeros[1])
    },
    [addToast]
  )

  const onProcess = useCallback(
    async ({ context }) => {
      const state = _.get(context, 'payload.state')
      const pool = _.get(context, 'payload.pool')

      const exposureOptions = numeral(
        new BigNumber(_.get(state, 'options.exposure')).toNumber()
      ).format('0,0.[0000]')

      const exposureCollateral = numeral(
        new BigNumber(_.get(state, 'collateral.exposure')).toNumber()
      ).format('0,0.[0000]')

      const amountOptions = new BigNumber(
        _.get(state, 'options.value')
      ).toNumber()
      const amountCollateral = new BigNumber(
        _.get(state, 'collateral.value')
      ).toNumber()

      const liquidityLabel = `${amountOptions}% (${exposureOptions}) of option${
        amountOptions !== 1 ? 's' : ''
      } and ${amountCollateral}% (${exposureCollateral}) of ${_.get(
        state,
        'collateral.token'
      )}`

      try {
        setOpen(true, {
          state: 'loading',
          tx: null,
          info: `Removing liquidity of ${liquidityLabel}.`
        })

        let rejected = null

        const transaction = pool.removeLiquidity(
          amountOptions,
          amountCollateral,
          (error, transactionHash) => {
            if (error) rejected = error
            updateData({
              tx: transactionHash
            })
          }
        )
        await transaction

        if (!_.isNil(rejected)) throw rejected

        removeAllToasts()
        refresh()

        const success = `Liqudity (${liquidityLabel}) successfully removed!`

        updateData({
          state: 'success',
          info: success
        })
        addToast(success, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
      } catch (e) {
        console.error('Remove liquidity', e)
        removeAllToasts()

        updateData({
          state: 'error',
          info: `Attempted to remove liquidity of ${liquidityLabel}.`
        })

        addToast('Transaction cancelled', {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
        throw e
      }
    },
    [addToast, removeAllToasts, refresh, setOpen, updateData]
  )

  const machine = useAtomicMachine({
    id: 'liquidityRemove',
    onPrepare,
    onValidate,
    onProcess
  })

  return machine
}

export default {
  useMachine
}
