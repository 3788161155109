import _ from 'lodash'
import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import numeral from 'numeral'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import IconChart from '@material-ui/icons/MultilineChartRounded'
import IconWallet from '@material-ui/icons/AccountBalanceWalletRounded'
import Section from '../../../../shared/Section'
import Card from './Card'
// import Reminder from './Reminder'
import CapGuard from './CapGuard'
// import Beta from './Beta'

import {
  useOptions,
  useOwnHedges,
  useOwnBalances,
  useOwnPools,
  useAccount,
  useWalletModal,
  useNetworkId
} from '../../../../../hooks'
import { macros, pages, tokens } from '../../../../../constants'
import { tokenAddressToTokenKey } from '../../../../../utils'

const ContentPartial = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ${props => props.theme.sizes.layoutEdge};
`

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: ${props => props.theme.sizes.edge};
  width: 100%;
`

const Cards = styled.div`
  grid-column: span 4;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${props => props.theme.sizes.edge};
  width: 100%;
`

const Content = styled(ContentPartial)`
  ${props => props.theme.medias.medium} {
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
    & > ${List} {
      grid-template-columns: 1fr;
      grid-column-gap: 0;
      grid-row-gap: calc(${props => props.theme.sizes.edge} * 1 / 2);
      & > ${Cards} {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        grid-row-gap: calc(${props => props.theme.sizes.edge} * 1 / 2);
      }
    }
  }
`

function getActiveOptions (options) {
  return options
    .map(option => {
      option.timeToExpiration = new BigNumber(
        _.get(option, 'expiration')
      ).minus(new BigNumber(dayjs().valueOf()).dividedToIntegerBy(1000))
      return option
    })
    .filter(option => option.timeToExpiration > 0)
    .filter(option => _.get(option, 'underlyingAsset'))
}

function getExpiringOptions (options) {
  return options
    .map(option => {
      option.timeToExpiration = new BigNumber(
        _.get(option, 'expiration')
      ).minus(new BigNumber(dayjs().valueOf()).dividedToIntegerBy(1000))
      return option
    })
    .filter(
      option =>
        option.timeToExpiration < macros.EXPIRY_NOTICE_TIME_LONG &&
        option.timeToExpiration > 0
    )
}

function getProtected (hedges) {
  const unique = {}

  const list = hedges.map(item => ({
    tokenKey: _.get(item, 'underlyingAssetSymbol').toUpperCase(),
    tokenBalance: numeral(_.get(item, 'balance')).format('0,0[.]00'),
    tokenBalanceNumber: _.get(item, 'balance')
  }))

  list.forEach(item => {
    const tokenKey = _.get(item, 'tokenKey')
    if (_.has(unique, tokenKey)) {
      const balance = new BigNumber(_.get(item, 'tokenBalanceNumber'))
        .plus(new BigNumber(_.get(unique[tokenKey], 'tokenBalanceNumber')))
        .toNumber()
      unique[tokenKey].tokenBalanceNumber = balance
      unique[tokenKey].tokenBalance = numeral(
        unique[tokenKey].tokenBalanceNumber
      ).format('0,0[.]00')
    } else {
      unique[tokenKey] = item
    }
  })

  return Object.values(unique)
}

function getLiquiditySides (pools) {
  let tokens = 0
  let options = 0

  pools.forEach(optionWithPool => {
    options += new BigNumber(
      _.get(optionWithPool, 'poolData.position[0]')
    ).toNumber()
    tokens += new BigNumber(
      _.get(optionWithPool, 'poolData.position[1]')
    ).toNumber()
  })

  return [
    {
      tokenKey: 'Options',
      tokenBalance: numeral(options).format('0,0[.]00'),
      tokenBalanceNumber: options
    },
    {
      tokenKey: 'Stablecoins',
      tokenBalance: numeral(tokens).format('$0,0[.]00'),
      tokenBalanceNumber: tokens
    }
  ]
}

function useOverviewData () {
  const [underlyings, setUnderlyings] = useState(null)
  const networkId = useNetworkId()

  const tokensAddresses = useMemo(() => tokens[networkId], [networkId])

  const { list: options, isLoading: isLoadingOptions } = useOptions()
  const { list: pools, isLoading: isLoadingPools } = useOwnPools(null, true)
  const { list: hedges, isLoading: isLoadingHedges } = useOwnHedges()

  const active = useMemo(() => getActiveOptions(options), [options])

  const { result: balances, isLoadng: isLoadingBalances } = useOwnBalances(
    underlyings
  )

  const [data, setData] = useState({
    protectable: [],
    protected: [],
    liquidity: [],
    expiring: []
  })

  const isLoading = useMemo(
    () =>
      isLoadingBalances ||
      isLoadingHedges ||
      isLoadingOptions ||
      isLoadingPools,
    [isLoadingHedges, isLoadingOptions, isLoadingPools, isLoadingBalances]
  )

  useEffect(() => {
    if (!isLoadingOptions) {
      const list = active.map(option => _.get(option, 'underlyingAsset'))
      setUnderlyings([...new Set(list)])
    }
  }, [active, isLoadingOptions])

  useEffect(() => {
    if (isLoading) return

    const expiring = getExpiringOptions(options).map(
      option =>
        `${_.get(option, 'underlyingAssetSymbol').toUpperCase()}:${_.get(
          option,
          'strikeAssetSymbol'
        ).toUpperCase()}`
    )

    setData({
      protectable: balances
        .filter(item => !new BigNumber(_.get(item, 'balance')).isZero())
        .map(item => ({
          tokenKey: tokenAddressToTokenKey(
            _.get(item, 'address'),
            tokensAddresses
          ),
          tokenBalance: numeral(_.get(item, 'balance')).format('0,0[.]00'),
          tokenBalanceNumber: _.get(item, 'balance')
        })),
      protected: getProtected(hedges),
      liquidity: getLiquiditySides(pools),
      expiring: [...new Set(expiring)]
    })
  }, [tokensAddresses, active, options, hedges, balances, pools, isLoading])

  return { ...data, isLoading }
}

function Overview () {
  const data = useOverviewData()

  const account = useAccount()
  const { connect } = useWalletModal()

  const isLoading = useMemo(() => data.isLoading || !account.isConnected, [
    account,
    data
  ])
  const isConnected = useMemo(() => account.isConnected || account.isExpected, [
    account
  ])

  return (
    <Section title='Account Overview'>
      <Content>
        <List>
          <Cards>
            {!isConnected ? (
              <Card
                Icon={IconWallet}
                title='Link your account to start trading options'
                titleShort='Account'
                value='Connect wallet'
                isWallet
                isLoading={false}
                onClick={connect}
              />
            ) : (
              <Card
                Icon={pages.hedge.Icon}
                title='Your hedged assets'
                titleShort='Hedged'
                value={
                  data.protected.length === 0 ||
                  data.protected.every(item =>
                    new BigNumber(item.tokenBalanceNumber).isZero()
                  )
                    ? 'Start Hedging'
                    : data.protected
                }
                to={pages.hedge.builder()}
                isLoading={isLoading}
              />
            )}
            <Card
              Icon={IconChart}
              title={
                <>
                  <b>Protectable</b> capital in your wallet
                </>
              }
              titleShort='Wallet'
              value={
                data.protectable.length === 0 ||
                data.protectable.every(item =>
                  new BigNumber(item.tokenBalanceNumber).isZero()
                )
                  ? '$0'
                  : data.protectable
              }
              isLoading={isLoading}
              to={pages.hedge.builder()}
            />
            <Card
              Icon={pages.pool.Icon}
              title='Your liquidity in pools'
              titleShort='Liquidity'
              value={
                data.liquidity.length === 0 ||
                data.liquidity.every(item =>
                  new BigNumber(item.tokenBalanceNumber).isZero()
                )
                  ? 'Earn in Pools'
                  : data.liquidity
              }
              to={pages.pool.builder()}
              isLoading={isLoading}
            />
          </Cards>
          <CapGuard isLoading={isLoading} />
          {/* <Beta isLoading={isLoading} /> */}
          {/* {GUARDED_LAUNCH.status ? (
            <CapGuard isLoading={isLoading} />
          ) : (
            <Reminder expiring={data.expiring} isLoading={isLoading} />
          )} */}
        </List>
      </Content>
    </Section>
  )
}

export default Overview
