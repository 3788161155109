import _ from 'lodash'
export { default as chains } from './eip155-chains'

export const _RANDOM_BUILD_INDEX = 6

export const ENVIRONMENT = {
  current: String(
    process.env.REACT_APP_PODS_ENV || process.env.NODE_ENV || 'development'
  ).toLowerCase(),
  development: 'development',
  production: 'production',
  staging: 'staging',
  isDevelopment: () => ENVIRONMENT.current === ENVIRONMENT.development,
  isProduction: () => ENVIRONMENT.current === ENVIRONMENT.production,
  isStaging: () => ENVIRONMENT.current === ENVIRONMENT.staging
}

export const host = {
  teaser: {
    development: `http://localhost:${process.env.PORT || 5001}`,
    staging: 'https://teaser.dev.pods.finance',
    production: 'https://pods.finance',
    auto: () => host.teaser[ENVIRONMENT.current]
  },
  landing: {
    development: `http://localhost:${process.env.PORT || 5000}`,
    staging: 'https://landing.dev.pods.finance',
    production: 'https://pods.finance',
    auto: (slug = '') =>
      `${host.landing[ENVIRONMENT.current]}${_.toString(slug || '')}`
  },
  platform: {
    development: `http://localhost:${process.env.PORT || 5002}`,
    staging: 'https://app.dev.pods.finance',
    production: 'https://app.pods.finance',
    auto: (slug = '') =>
      `${host.platform[ENVIRONMENT.current]}${_.toString(slug || '')}`
  }
}

export const links = {
  audit: {
    solidified: {
      link: 'https://docs.pods.finance'
    }
  },
  twitter: {
    primary: {
      link: 'https://twitter.com/podsfinance',
      username: '@PodsFinance'
    }
  },
  gitbook: {
    primary: {
      link: 'https://docs.pods.finance/'
    },
    whitepaper: {
      link: 'https://docs.pods.finance/'
    },
    faq: {
      link: 'https://docs.pods.finance/'
    },
    etherscan: {
      link: 'https://docs.pods.finance/'
    },
    AMM: {
      link: 'https://docs.pods.finance/options-amm-overview/introduction'
    },
    adminKeys: {
      link: 'https://docs.pods.finance/the-protocol/cap-and-admin-keys'
    },
    options: {
      link: 'https://docs.pods.finance/understand-options/what-are-options'
    }
  },
  discord: {
    primary: {
      link: 'https://discord.gg/Qf7utym'
    }
  },
  defipulse: {
    primary: {
      link: 'defipulse.com/'
    }
  },
  medium: {
    primary: {
      link: 'https://blog.pods.finance'
    }
  },
  github: {
    primary: {
      link: 'https://github.com/pods-finance'
    }
  },
  calendly: {
    primary: {
      link: 'https://calendly.com/podsfinance'
    }
  },
  newsletter: {
    endpoint:
      'https://sx4cu0jww2.execute-api.us-east-2.amazonaws.com/dev/subscribe'
  },
  notion: {
    hire: {
      link: 'https://angel.co/company/pods-finance'
    },
    team: {
      link: 'https://angel.co/company/pods-finance'
    }
  },
  aave: {
    about: {
      link: 'https://aave.com/'
    }
  },
  terms: {
    primary: {
      link:
        'https://www.notion.so/podsfinance/Pods-Terms-of-Use-2632469f8aae4e4e8f6aef15d4c65a29'
    },
    risks: {
      link: 'https://blog.pods.finance/risks-of-using-pods-9ea880e192e8'
    },
    analytics: {
      link:
        'https://www.notion.so/podsfinance/Pods-Analytics-cda2ecf2b6d94cc18ae2d5b584136883'
    }
  },
  faucet: {
    mycrypto: {
      link: 'https://app.mycrypto.com/faucet'
    },
    gitter: {
      link: 'https://gitter.im/kovan-testnet/faucet'
    }
  },
  ethereum: {
    primary: {
      link: 'https://ethereum.org/en/'
    }
  }
}

export const networks = {
  mainnet: 1,
  ropsten: 3,
  rinkeby: 4,
  goerli: 5,
  kovan: 42,
  xDai: 100,
  mumbai: 80001,
  matic: 137,
  local: 1337,
  _data: {
    1: {
      name: 'Mainnet',
      averageBlockTime: 15,
      scanner: 'https://etherscan.io/tx/',
      subgraph: 'https://api.thegraph.com/subgraphs/name/pods-finance/pods',
      subgraphDev:
        'https://api.thegraph.com/subgraphs/name/pods-finance/pods-dev'
    },
    3: {
      name: 'Ropsten',
      averageBlockTime: null,
      scanner: 'ropsten.etherscan.io/tx/'
    },
    4: {
      name: 'Rinkeby',
      averageBlockTime: null,
      scanner: 'rinkeby.etherscan.io/tx/'
    },
    5: {
      name: 'Goerli',
      averageBlockTime: null,
      scanner: 'goerli.etherscan.io/tx/'
    },
    42: {
      name: 'Kovan',
      averageBlockTime: 4,
      scanner: 'https://kovan.etherscan.io/tx/',
      faucet: 'https://faucet.kovan.network/',
      subgraph:
        'https://api.thegraph.com/subgraphs/name/pods-finance/pods-kovan',
      subgraphDev:
        'https://api.thegraph.com/subgraphs/name/pods-finance/pods-kovan-dev'
    },
    100: {
      name: 'xDai',
      averageBlockTime: null,
      scanner: 'https://blockscout.com/poa/xdai/mainnet/tx/'
    },
    137: {
      name: 'Matic',
      averageBlockTime: null,
      scanner: 'https://explorer-mainnet.maticvigil.com/tx/',
      subgraph:
        'https://api.thegraph.com/subgraphs/name/pods-finance/pods-matic-legacy',
      subgraphDev:
        'https://api.thegraph.com/subgraphs/name/pods-finance/pods-matic-legacy'
    },
    80001: {
      name: 'Mumbai',
      averageBlockTime: 4,
      scanner: 'https://explorer-mumbai.maticvigil.com/tx/',
      faucet: 'https://faucet.matic.network/',
      subgraph:
        'https://api.thegraph.com/subgraphs/name/pods-finance/pods-mumbai',
      subgraphDev:
        'https://api.thegraph.com/subgraphs/name/pods-finance/pods-mumbai-dev'
    },
    1337: {
      name: 'Local',
      averageBlockTime: null,
      scanner: ''
    }
  },
  _supported: [42, 1337, 137]
}

export const etherscaned = (tx, networkId = networks.kovan) =>
  `${_.get(networks, `_data[${networkId}]scanner`)}${tx}`

export const subgraphed = (networkId = networks.kovan) =>
  ENVIRONMENT.isProduction()
    ? _.get(networks, `_data[${networkId}]subgraph`)
    : _.get(networks, `_data[${networkId}]subgraphDev`)

export const faucetLink = (networkId = networks.kovan) =>
  `${_.get(networks, `_data[${networkId}]faucet`)}`

export const networkName = (networkId = networks.kovan) =>
  `${_.get(networks, `_data[${networkId}]name`)}`

export const meta = {
  general: {
    title: 'Pods',
    titleFull: 'Pods Finance',
    baseUrl: 'https://www.pods.finance',
    creator: '@pods.finance',
    site: '@pods.finance',
    alt: 'Pods.Finance',
    motto: 'The future of your hedged portfolio on Ethereum.',
    description:
      "Manage your portfolio's risk by hedging your positions. Invest, get a higher APY and be exposed to assets you want. Become a liquidity provider and earn with your assets."
  }
}
