import _ from 'lodash'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import numeral from 'numeral'
import { Emoji } from 'shared'
import {
  useOptionData,
  useMarketPrices,
  useOptionPrices
} from '../../../../../hooks'
import { TokenDisplay } from '../../../../atoms'
import { macros, pages } from '../../../../../constants'
import Item from './Item'
import { interpretPremium } from '../../../../../utils'

const WrapperPartial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding: calc(${props => props.theme.sizes.edge} * 1.5);
  padding-bottom: 0;
`

const Header = styled.div`
  width: 100%;
  padding-bottom: ${props => props.theme.sizes.edge};
`

const Title = styled.p`
  margin: 0;
  font-size: 16pt;
  font-weight: 700;
  color: ${props => props.theme.colors.dark};
`

const Body = styled.div`
  width: 100%;
  & > *:last-child {
    border-bottom: none;
  }

  &[data-loading='true'] {
    div[data-component='content'] {
      position: relative;
      & > * {
        opacity: 0;
      }
      &:before {
        content: '';
        width: 140px;
        height: 100%;
        position: absolute;
        right: 0;
        z-index: 0;
        border-radius: 8px;
        background-image: ${props =>
          props.theme.styles.backgroundGradientLoader};
      }
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.small} {
    padding: calc(${props => props.theme.sizes.edge} * 3 / 2)
      calc(${props => props.theme.sizes.edge} * 1);
    border-radius: 0;
  }
`

const Missing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  font-size: 40pt;
`

function InfoMissing () {
  return (
    <Wrapper>
      <Header>
        <Title>Option Info</Title>
      </Header>
      <Body>
        <Missing>
          <Emoji symbol='🛸' label='UFO' />
        </Missing>
      </Body>
    </Wrapper>
  )
}

function Info ({ context }) {
  const { list: prices } = useMarketPrices()
  const { list: premiums } = useOptionPrices({ context })

  const {
    item: option,
    isLoading,
    tokenUnderlying,
    tokenCollateral,
    labelStrike,
    expiration,
    times
  } = useOptionData()

  const market = _.get(prices, _.get(tokenUnderlying, 'symbol'))
  const price = _.get(premiums, `${_.get(option, 'address')}.price`)

  const expirationSubtitle = useMemo(() => _.get(times, 'expirationFromNow'), [
    times
  ])

  if (!isLoading && _.isNil(option)) return <InfoMissing />

  return (
    <Wrapper>
      <Header>
        <Title>Option Info</Title>
      </Header>
      <Body data-loading={isLoading}>
        <Item
          label='Type'
          title='Put Options'
          help='Put Options are contracts giving the owner the right, but not the obligation, to sell an asset at a pre-determined strike price.'
        />
        <Item label='Underlying Asset'>
          <TokenDisplay token={tokenUnderlying} isSelfPadded={false} />
        </Item>
        <Item label='Collateral Asset'>
          <TokenDisplay token={tokenCollateral} isSelfPadded={false} />
        </Item>
        <Item
          label='Expiration date'
          title={expiration}
          subtitle={expirationSubtitle}
        />
        <Item
          label='Exercise Type'
          title='European'
          subtitle='After expiration'
          help='European options are a type of instrument that allows the buyer to exercise their right only after expiration, during the exercise window.'
        />
        <Item
          label='Exercise window'
          title={_.get(times, 'windowTime') + ' long'}
          subtitle={`end ${_.get(times, 'exerciseLeft')}`}
          help='During this time (top) buyers will be able to exercise options of these series.'
        />

        <Item
          label='Strike Price'
          title={labelStrike}
          subtitle={numeral(market).format('$0.[00]')}
          help='Strike price (top) vs. current market price for one unit of asset (bottom).'
        />
        <Item
          label='Market Premium'
          title={interpretPremium(option, price).complex}
        />

        <Item
          label='Default Slippage'
          title={`${macros.DEFAULT_SLIPPAGE * 100}%`}
        />
      </Body>
    </Wrapper>
  )
}

Info.propTypes = {
  context: PropTypes.string
}

Info.defaultProps = {
  context: pages.hedge.title
}

export default Info
