import _ from 'lodash'
import React, { useMemo } from 'react'
import numeral from 'numeral'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { constants } from 'shared'
import { tabs } from '../../../constants'
import { capitalize } from '../../../utils'

const { meta } = constants

function Dashboard () {
  return (
    <Helmet>
      <title>{`${meta.general.title} - ${meta.general.motto}`}</title>
    </Helmet>
  )
}

function Invest () {
  return (
    <Helmet>
      <title>{`${meta.general.title} - Sell Options - ${meta.general.motto}`}</title>
    </Helmet>
  )
}

function Hedge () {
  return (
    <Helmet>
      <title>{`${meta.general.title} - Buy Options - ${meta.general.motto}`}</title>
    </Helmet>
  )
}

function History () {
  return (
    <Helmet>
      <title>{`${meta.general.title} - History - ${meta.general.motto}`}</title>
    </Helmet>
  )
}

function Pool () {
  return (
    <Helmet>
      <title>{`${meta.general.title} - Pools - ${meta.general.motto}`}</title>
    </Helmet>
  )
}

function Faucet () {
  return (
    <Helmet>
      <title>{`${meta.general.title} - Be a Testnet 🐳 Whale - ${meta.general.motto}`}</title>
    </Helmet>
  )
}
function Restricted () {
  return (
    <Helmet>
      <title>{`${meta.general.title} - Terms of Service restrictions`}</title>
    </Helmet>
  )
}

function Transaction ({ data, tabs, fallback = 'Sell Options' }) {
  const location = useLocation()

  const title = useMemo(() => {
    const { tokenUnderlying, tokenCollateral, strike, times } = data

    if (!_.isNil(tokenCollateral) && !_.isNil(tokenCollateral)) {
      const area =
        _.get(
          tabs.find(tab => location.hash === `#${tab.hash}`),
          'title'
        ) || fallback
      const pair = `${_.get(tokenUnderlying, 'symbol')}:${_.get(
        tokenCollateral,
        'symbol'
      )}`
      const special =
        times.isExpired && !times.isExerices ? '⏱' : times.isNotice ? '📅' : ''
      const price = numeral(strike).format('0,0.[0000]')
      const { expiration } = times

      return [area, pair, special, price, expiration]
        .filter(element => !_.isNilOrEmptyString(element))
        .join(' - ')
    }

    return `${fallback} - ${meta.general.title}`
  }, [data, location, fallback, tabs])

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}

function TransactionInvest ({ data }) {
  return (
    <Transaction
      data={data}
      tabs={Object.values(tabs.invest).map(hash => ({
        hash,
        title: capitalize(hash)
      }))}
      fallback='Sell Options'
    />
  )
}

function TransactionHedge ({ data }) {
  return (
    <Transaction
      data={data}
      tabs={Object.values(tabs.hedge).map(hash => ({
        hash,
        title: capitalize(hash)
      }))}
      fallback='Buy Options'
    />
  )
}

function TransactionPool ({ data }) {
  return (
    <Transaction
      data={data}
      tabs={Object.values(tabs.pool).map(hash => ({
        hash,
        title: `${capitalize(hash)}${
          [tabs.pool.add, tabs.pool.remove].includes(hash) ? ' Liquidity' : ''
        }`
      }))}
      fallback='Manage Liquidity'
    />
  )
}

export default {
  Dashboard,
  Invest,
  Hedge,
  History,
  Pool,
  Faucet,
  Restricted,

  TransactionInvest,
  TransactionHedge,
  TransactionPool
}
