import _ from 'lodash'
import BigNumber from 'bignumber.js'
import { ERC20ABI } from '../contracts'
import { MAX_UINT } from '../constants'

export const checkAllowance = async ({
  web3,
  tokenAddress,
  owner,
  spender,
  amount
}) => {
  const erc20Contract = new web3.eth.Contract(ERC20ABI, tokenAddress)
  const amountAllowed = new BigNumber(
    await erc20Contract.methods.allowance(owner, spender).call()
  )
  return amountAllowed.isGreaterThanOrEqualTo(amount)
}

export const getAllowance = async ({ web3, tokenAddress, owner, spender }) => {
  const erc20Contract = new web3.eth.Contract(ERC20ABI, tokenAddress)
  const amountAllowed = new BigNumber(
    await erc20Contract.methods.allowance(owner, spender).call()
  )

  return amountAllowed
}

export const approveTransfer = ({
  web3,
  tokenAddress,
  owner,
  spender,
  amount = null
}) => {
  const erc20Contract = new web3.eth.Contract(ERC20ABI, tokenAddress)
  return erc20Contract.methods
    .approve(spender, !_.isNil(amount) ? String(amount) : MAX_UINT)
    .send({ from: owner })
}

export const checkBalance = async (web3, token, owner) => {
  const contract = new web3.eth.Contract(ERC20ABI, token)
  return await Promise.all([
    contract.methods.balanceOf(owner).call(),
    contract.methods.decimals().call()
  ]).then(([value, decimals]) => value / 10 ** decimals)
}
