import _ from 'lodash'
import React, { useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Helper } from 'shared'
import SpecialButton from '../../../../../shared/SpecialButton'
import { Structure, Section, Summary, Extra } from '../../../../../shared/Tabs'
import {
  Form as FormPartial,
  Step,
  Label,
  Input
} from '../../../../../shared/Form'

import { tabs, types } from '../../../../../../constants'
import reducers from '../../../../../../reducers'
import machines, { guards } from '../../../../../../machines'
import {
  useOwnBalance,
  useOptionData,
  useOptionBuyPrice,
  useOptionEstimatedBuy,
  usePoolBalance,
  useFormValidator,
  useFormAssetAllowance,
  useTokenAllowanceForLater
} from '../../../../../../hooks'
import { analytics } from '../../../../../../vendors'
import { toNumeralPrice, toSignificantInput } from '../../../../../../utils'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Form = styled(FormPartial)``

function initialize ({
  elements,
  dispatch,
  balancePremium,
  tokenUnderlying,
  tokenPremium
}) {
  dispatch([], 'RESET', [elements.allowance])
  dispatch([elements.underlying, { token: _.get(tokenUnderlying, 'symbol') }])
  dispatch([elements.exact, { value: 'tokenA' }])
  dispatch([
    elements.premium,
    { token: _.get(tokenPremium, 'symbol'), max: balancePremium }
  ])
}

function Buy () {
  const {
    item: option,
    isLoading: isOptionLoading,
    tokenUnderlying,
    tokenPremium,
    tokenCollateral,
    strike,
    times,
    version
  } = useOptionData()

  const {
    value: balancePremium,
    isLoading: isBalancePremiumLoading
  } = useOwnBalance(_.get(tokenPremium, 'address'))

  const {
    value: balanceUnderlying,
    isLoading: isBalanceUnderlyingLoading
  } = usePoolBalance(option)

  const { check: safeComputePremium } = useOptionBuyPrice()
  const { check: safeComputeUnderlying } = useOptionEstimatedBuy()

  const { elements, state, dispatch } = reducers.buy.useReducer()
  const machine = machines.buy.useMachine()
  const setup = useTokenAllowanceForLater()

  /**
   *
   * Form setters and state updaters
   *
   */

  const doAllowanceUpdate = useCallback(
    ({ isAllowed, isLoading }) =>
      dispatch([elements.allowance, { token: isAllowed, isLoading }]),
    [elements, dispatch]
  )

  const doPremiumUpdate = useCallback(
    _.debounce(
      ({
        underlying,
        elements,
        dispatch,
        option,
        balancePremium,
        safeComputePremium
      }) => {
        ;(async () => {
          dispatch([elements.premium, { value: null, isLoading: true }])
          if (underlying === null) return

          const premium = await safeComputePremium(
            option,
            new BigNumber(underlying).toNumber()
          )

          const value = _.get(premium, 'value')
          const fees = _.get(premium, 'fees')

          dispatch([
            elements.premium,
            {
              value: toSignificantInput(value || 0, BigNumber.ROUND_UP),
              isLoading: false,
              warning:
                _.toString(value) !== ''
                  ? guards.isPremiumValid({
                    value,
                    max: balancePremium
                  })
                  : null
            }
          ])
          dispatch([elements.exact, { value: 'tokenA' }])
          dispatch([elements.fees, { value: fees }])
        })()
      },
      300
    ),
    []
  )

  const doUnderlyingUpdate = useCallback(
    _.debounce(
      ({
        premium,
        elements,
        dispatch,
        option,
        balanceUnderlying,
        safeComputeUnderlying
      }) => {
        ;(async () => {
          dispatch([elements.underlying, { value: null, isLoading: true }])
          if (premium === null) return

          const underlying = await safeComputeUnderlying(
            option,
            new BigNumber(premium).toNumber()
          )

          const value = _.get(underlying, 'value')
          const fees = _.get(underlying, 'fees')

          const anchor = _.isNilOrEmptyString(value)
            ? new BigNumber(balanceUnderlying).plus(10).toString()
            : _.toString(value)

          dispatch([
            elements.underlying,
            {
              value: toSignificantInput(value || 0, BigNumber.ROUND_DOWN),
              isLoading: false,
              warning:
                value === null || _.toString(value) !== ''
                  ? guards.isAmountValid({
                    value: anchor,
                    max: balanceUnderlying,
                    context: 'liquidity'
                  })
                  : null
            }
          ])
          dispatch([elements.exact, { value: 'tokenB' }])
          dispatch([elements.fees, { value: fees }])
        })()
      },
      300
    ),
    []
  )

  const onChangeAmount = useCallback(
    (underlying = null, premium = null) => {
      if (!_.isNil(underlying)) {
        dispatch([
          elements.underlying,
          {
            value: underlying,
            warning:
              _.toString(underlying) !== ''
                ? guards.isAmountValid({
                  value: underlying,
                  max: balanceUnderlying,
                  context: 'liquidity'
                })
                : null
          }
        ])

        doPremiumUpdate({
          underlying,
          elements,
          dispatch,
          option,
          state,
          balancePremium,
          safeComputePremium
        })
      } else if (!_.isNil(premium)) {
        dispatch([
          elements.premium,
          {
            value: premium,
            warning:
              _.toString(premium) !== ''
                ? guards.isAmountValid({
                  value: premium,
                  max: balancePremium
                })
                : null
          }
        ])
        doUnderlyingUpdate({
          premium,
          elements,
          dispatch,
          option,
          tokenPremium,
          balanceUnderlying,
          safeComputeUnderlying
        })
      }
    },
    [
      dispatch,
      elements,
      balancePremium,
      balanceUnderlying,
      option,
      doPremiumUpdate,
      doUnderlyingUpdate,
      state,
      tokenPremium,
      safeComputePremium,
      safeComputeUnderlying
    ]
  )

  const onPrimaryRefocus = useCallback(
    isUnderlyingPrimary => {
      if (
        isUnderlyingPrimary === _.get(state, `${elements.underlying}.isPrimary`)
      ) {
        return
      }

      dispatch([
        elements.underlying,
        {
          isPrimary: isUnderlyingPrimary
        }
      ])
      dispatch([
        elements.premium,
        {
          isPrimary: !isUnderlyingPrimary
        }
      ])
    },
    [dispatch, elements, state]
  )

  const onTransact = useCallback(() => {
    analytics.track(e => e.transactionBuyTrigger)
    machine.send(machine.events.save, {
      payload: {
        state,
        option,
        premiumTokenAddress: _.get(tokenPremium, 'address'),
        setup
      }
    })
  }, [machine, state, option, tokenPremium, setup])

  /**
   *
   * Automated effects
   *
   */

  const {
    doApprove: doAllowanceApprove,
    doRefresh: doAllowanceRefresh
  } = useFormAssetAllowance({
    amount: _.get(state, 'premium.value'),
    tokenAddress: _.get(tokenPremium, 'address'),
    onUpdate: doAllowanceUpdate
  })

  useEffect(() => {
    if (
      !isOptionLoading &&
      !isBalancePremiumLoading &&
      !isBalanceUnderlyingLoading
    ) {
      initialize({
        balancePremium,
        elements,
        dispatch,
        tokenUnderlying,
        tokenPremium,
        version
      })
      doAllowanceRefresh()
    }
  }, [
    balancePremium,
    tokenUnderlying,
    tokenPremium,
    elements,
    dispatch,
    version,
    doAllowanceRefresh,

    isOptionLoading,
    isBalancePremiumLoading,
    isBalanceUnderlyingLoading
  ])

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  const isUnderlyingPrimary = useMemo(() => {
    return _.get(state, 'exact.value') === 'tokenA'
  }, [state])

  return (
    <Structure hash={tabs.hedge.buy}>
      <Wrapper>
        <Section
          title='Buy options (hedge)'
          isContained
          isLoading={isOptionLoading}
          isDisabled={[
            machine.states.validate,
            machine.states.process
          ].includes(machine.current.value)}
        >
          <Form>
            <Step>
              <Label>
                Step 1. Amount to hedge{!isUnderlyingPrimary && ' (expected)'}
              </Label>
              <Input.Amount
                {...state.underlying}
                placeholder='Enter amount'
                onChange={e => onChangeAmount(_.get(e, 'target.value'), null)}
                onClick={() => onPrimaryRefocus(true)}
              />
            </Step>
            <Step>
              <Label>
                Step 2. Pay with {isUnderlyingPrimary && ' (expected)'}
              </Label>
              <Input.Amount
                {...state.premium}
                placeholder='0'
                onChange={e => onChangeAmount(null, _.get(e, 'target.value'))}
                onClick={() => onPrimaryRefocus(false)}
              />
              <Extra>
                <Helper value='The fees will be paid to the liquidity providers, based on the size and price impact of your trade. This amount is included in the premium value.'>
                  <div data-component='pill'>
                    <p>Included fees: </p>
                    <span>
                      {!_.isNaN(_.get(state, 'fees.value')) &&
                        !_.isNil(_.get(state, 'fees.value')) &&
                        toNumeralPrice(_.get(state, 'fees.value'))}
                    </span>
                  </div>
                </Helper>
              </Extra>
            </Step>

            <Summary
              index={3}
              context={tabs.hedge.buy}
              data={{ state, strike, tokenCollateral, times }}
              allow={
                <SpecialButton.AllowToken
                  action={types.action.hedge}
                  amount={_.get(state, 'premium.value')}
                  title={`Allow ${_.get(tokenPremium, 'symbol')}`}
                  isAllowed={_.get(state, 'allowance.token')}
                  isLoading={_.get(state, 'allowance.isLoading')}
                  onApprove={doAllowanceApprove}
                />
              }
              transact={
                <SpecialButton.Transact
                  action={types.action.hedge}
                  token={state.premium.token}
                  title='Buy Options'
                  isDisabled={!isFormValid}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </Section>
      </Wrapper>
    </Structure>
  )
}

export default Buy
