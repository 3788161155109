import _ from 'lodash'
import { useMemo, useEffect, useCallback } from 'react'
import { useTransactionHistoryContext } from '../contexts/TransactionHistory'
import { useOptionsRefresh } from './option'

export function useHardRefresh () {
  const { refresh: refreshOptions } = useOptionsRefresh()
  const { refresh: refreshHistory } = useTransactionHistoryContext()

  const refresh = useCallback(() => {
    refreshOptions()
    setTimeout(() => {
      refreshHistory()
    }, 5000)
  }, [refreshOptions, refreshHistory])

  return { refresh }
}

export function useTransactionHistory () {
  const { historyByAccount } = useTransactionHistoryContext()
  return historyByAccount
}

export function useOptionTransactionHistory (option) {
  const { historyListByOption, version } = useTransactionHistoryContext()
  const { request, transactions, isLoading } = useMemo(
    () => historyListByOption,
    [historyListByOption]
  )

  useEffect(() => {
    if (!_.isNil(option)) {
      const history = _.get(transactions, option)
      if (_.isNil(history) || _.get(history, 'isFinished') !== true) {
        request(option)
      }
    }
  }, [option, request, version, transactions])

  const result = useMemo(() => {
    const item = _.get(transactions, option)
    return {
      transactions: _.get(item, 'transactions') || [],
      isFinished: _.get(item, 'isFinished') || false,
      isLoading
    }
  }, [isLoading, transactions, option])

  return result
}
