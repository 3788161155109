import _ from 'lodash'
import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import numeral from 'numeral'
import Theme from 'shared'
import IconMarket from '@material-ui/icons/TimelineRounded'
import { useHistory } from 'react-router-dom'
import { layout } from '../../components/shared/Table'
import { types, pages, macros } from '../../constants'
import {
  interpretTimeWindows,
  toQuantity,
  sortOptionsTableByExpiration
} from '../../utils'

import { useOwnPools } from '../option'
import { useMarketPrices, useOptionPrices } from '../price'

export default function usePoolsTable ({ filter }) {
  const history = useHistory()

  const { list: options, count, isLoading } = useOwnPools()
  const { list: prices } = useMarketPrices()
  const { list: premiums, isLoading: isLoadingPremiums } = useOptionPrices()

  const columns = useMemo(
    () => [
      {
        title: 'Option Token',
        layout: layout.PodPair,
        subtitle: 'Asset #1',
        weight: 2
      },
      {
        title: 'Stable Token',
        layout: layout.PodAsset,
        subtitle: 'Asset #2',
        weight: 2
      },
      {
        title: 'Strike',
        layout: layout.Price,
        subtitle: (
          <>
            Market Price <IconMarket />
          </>
        ),
        weight: 2
      },
      {
        title: 'Expiration',
        layout: layout.Timestamp,
        weight: 2
      },
      {
        title: 'Wallet Balance',
        layout: layout.Text,
        weight: 2
      },
      {
        title: 'Your Liquidity',
        layout: layout.Text,
        weight: 2
      },
      {
        title: 'Pool Liquidity',
        layout: layout.Text,
        weight: 2
      },
      {
        title: '',
        layout: layout.Actions,
        weight: 2
      }
    ],
    []
  )

  const instructions = useMemo(
    () => ({
      onRowClick: params => {
        if (_.get(params, 'id')) {
          history.push(pages.transactionPool.builder(_.get(params, 'id')))
        }
      }
    }),
    [history]
  )

  const rows = useMemo(
    () =>
      options
        .map(option => {
          const underlying = _.get(
            option,
            'underlyingAssetSymbol'
          ).toUpperCase()
          const collateral = _.get(option, 'strikeAssetSymbol').toUpperCase()
          const times = interpretTimeWindows(option)
          const market = _.get(prices, underlying)
          const price = _.get(premiums, `${_.get(option, 'address')}.price`)

          const position = _.get(option, 'poolData.position')
          const liquidity = _.get(option, 'poolData.liquidity')
          const balance = _.get(option, 'poolData.balance')

          const poolSize = _.attempt(() => {
            return new BigNumber(_.get(liquidity, '[1]'))
              .plus(
                new BigNumber(_.get(liquidity, '[0]')).multipliedBy(
                  new BigNumber(price === macros.RESTRICTED_PREMIUM ? 0 : price)
                )
              )
              .toNumber()
          })

          const positionSize = _.attempt(() => {
            return new BigNumber(_.get(position, '[1]'))
              .plus(
                new BigNumber(_.get(position, '[0]')).multipliedBy(
                  new BigNumber(price === macros.RESTRICTED_PREMIUM ? 0 : price)
                )
              )
              .toNumber()
          })

          return {
            id: _.get(option, 'address'),
            cells: [
              {
                value: [underlying, collateral],
                type: types.action.pool
              },
              {
                value: collateral,
                type: types.action.pool
              },
              {
                value: numeral(_.get(option, 'strikePrice')).format(
                  '$0.[0000]'
                ),
                market: numeral(market).format('$0.[00]')
              },
              {
                value: _.get(option, 'expiration')
              },
              {
                value: toQuantity(
                  numeral(_.get(balance, '[0]')).format('0,0.[000]'),
                  'option'
                )
              },
              {
                value: [
                  toQuantity(
                    numeral(_.get(position, '[0]')).format('0,0.[000]'),
                    'option'
                  ),
                  `${numeral(_.get(position, '[1]')).format(
                    '0,0.[000]'
                  )} ${collateral}`
                ],
                helper: isLoadingPremiums
                  ? 'Loading'
                  : numeral(positionSize).format('$0,0.[000]')
              },
              {
                value: [
                  toQuantity(
                    numeral(_.get(liquidity, '[0]')).format('0,0.[000]'),
                    'option'
                  ),
                  `${numeral(_.get(liquidity, '[1]')).format(
                    '0,0.[000]'
                  )} ${collateral}`
                ],
                helper: isLoadingPremiums
                  ? 'Loading'
                  : numeral(poolSize).format('$0,0.[000]')
              },
              {
                times,
                value: _.get(option, 'address'),
                type: types.action.pool,
                source: pages.pool.builder()
              }
            ]
          }
        })
        .sort(sortOptionsTableByExpiration),
    [options, prices, premiums, isLoadingPremiums]
  )

  const filteredRows = useMemo(() => {
    const filteredOptions = filter(options)
    return rows.filter(row =>
      filteredOptions
        .map(option => _.get(option, 'address'))
        .includes(_.get(row, 'id'))
    )
  }, [rows, options, filter])

  return {
    data: {
      columns,
      rows: filteredRows,
      instructions,
      /** Cosmetics */
      isLoading: isLoading,
      expected: count,
      theme: Theme.constants.table.theme.slim
    },
    info: {
      total: _.get(options, 'length'),
      filtered: isLoading
        ? _.get(options, 'length')
        : _.get(filteredRows, 'length')
    }
  }
}
