import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import advanced from 'dayjs/plugin/advancedFormat'
import Theme from 'shared'
import { useInfo } from '../../context'

dayjs.extend(advanced)

const Wrapper = styled.div`
  grid-column: span 1;
  display: flex;
  align-items: center;
  & > p {
    ${props => props.theme.styles.tableParagraph};
  }


  &[data-theme="${Theme.constants.table.theme.slim}"]{
    & > p {
    ${props => props.theme.styles.tableParagraphSlim};
  }
  }

`

function Timestamp ({ data, column }) {
  const { theme } = useInfo()
  const value = _.attempt(() =>
    dayjs(
      new BigNumber(_.get(data, 'value')).multipliedBy(1000).toNumber()
    ).format('MMM Do, YYYY')
  )

  return (
    <Wrapper size={_.get(column, 'weight')} data-theme={theme}>
      <p>{!_.isError(value) ? value : ''}</p>
    </Wrapper>
  )
}

Timestamp.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.any
  }),
  column: PropTypes.shape({
    size: PropTypes.number
  })
}

Timestamp.defaultProps = {
  data: {
    value: null
  },
  column: {
    size: 1
  }
}

export default Timestamp
