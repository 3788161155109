import React from 'react'
import { Card, Title, Description, Content } from '../atoms'

import Input from '../../../../Form/Input'

function Underlying ({ reducer }) {
  return (
    <Card>
      <Title>Underlying</Title>
      <Description>
        Select which assets you’re interested in trading options for.
      </Description>
      <Content>
        <Input.TokenList
          {...reducer.state.underlying}
          maxDisplay={1}
          placeholder='e.g. WBTC, ETH, ...'
          onChange={value => {
            reducer.dispatch([
              reducer.elements.underlying,
              {
                value
              }
            ])
          }}
        />
      </Content>
    </Card>
  )
}

export default Underlying
