import React from 'react'
import styled from 'styled-components'
import Helmet from '../../components/specific/Helmet'
import Restricted from '../../components/specific/Restricted'
import TablePartial from '../../components/shared/Table'
import { Structure, Section, ToolbarBase } from '../../components/shared'
import { useHistoryTable, useSupportedNetwork } from '../../hooks'
import * as Modals from '../../components/specific/Modals'

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;

  ${props => props.theme.styles.horizontalScrollContainer};
`

const TableWrapper = styled.div`
  width: 100%;
`

const Table = styled(TablePartial)`
  min-width: 900px;
  padding: 0 ${props => props.theme.sizes.layoutEdge};

  div[data-component='body'] {
    min-height: 86px;
  }

  div[data-component='row'] {
    background-color: white !important;
    cursor: default;
  }

  ${props => props.theme.medias.medium} {
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
  }
`

function History () {
  const suported = useSupportedNetwork()
  const { data } = useHistoryTable()

  return (
    <Structure>
      <Helmet.History />
      <ToolbarBase />
      {suported ? (
        <>
          <Section
            title='Transaction History'
            description='The latest activity linked to your wallet’s activity on Pods.'
          >
            <Content>
              <TableWrapper>
                <Table data={data} />
              </TableWrapper>
            </Content>
          </Section>
        </>
      ) : (
        <Restricted.History />
      )}
      <Modals.HistoryOptions />
    </Structure>
  )
}

export default History
