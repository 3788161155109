const toHex = num => {
  return '0x' + num.toString(16)
}

export default {
  1: {
    name: 'Ethereum Mainnet',
    chain: 'ETH',
    network: 'mainnet',
    rpc: [
      'https://mainnet.infura.io/v3/${INFURA_API_KEY}',
      'wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}',
      'https://api.mycryptoapi.com/eth',
      'https://cloudflare-eth.com'
    ],
    faucets: [],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18
    },
    infoURL: 'https://ethereum.org',
    shortName: 'eth',
    chainId: 1,
    networkId: 1,
    slip44: 60,
    ens: {
      registry: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
    }
  },
  137: {
    name: 'Matic Mainnet',
    chain: 'Matic',
    network: 'mainnet',
    rpc: [
      'https://rpc-mainnet.matic.network',
      'wss://ws-mainnet.matic.network'
    ],
    faucets: [],
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18
    },
    infoURL: 'https://explorer-mainnet.maticvigil.com/',
    shortName: 'matic',
    chainId: 137,
    networkId: 137
  },
  80001: {
    name: 'Matic Testnet Mumbai',
    chain: 'Matic',
    network: 'testnet',
    rpc: ['https://rpc-mumbai.matic.today', 'wss://ws-mumbai.matic.today'],
    faucets: ['https://faucet.matic.network/'],
    nativeCurrency: {
      name: 'Matic',
      symbol: 'tMATIC',
      decimals: 18
    },
    infoURL: 'https://explorer-mumbai.maticvigil.com/',
    shortName: 'maticmum',
    chainId: 80001,
    networkId: 80001
  },
  formatter: chain => {
    return {
      chainId: toHex(chain.chainId), // A 0x-prefixed hexadecimal string
      chainName: chain.name,
      nativeCurrency: {
        name: chain.nativeCurrency.name,
        symbol: chain.nativeCurrency.symbol, // 2-6 characters long
        decimals: chain.nativeCurrency.decimals
      },
      rpcUrls: chain.rpc,
      blockExplorerUrls: [chain.infoURL]
    }
  }
}
