import _ from 'lodash'
import BigNumber from 'bignumber.js'
import numeral from 'numeral'
import dayjs from 'dayjs'
import { tokenAddressToTokenKey } from '../utils'
import { tokens } from '../constants'

const FORMAT = {
  N: '0,0.[0000]',
  M: '$0,0.[0000]'
}

/**
 * @enum {string}
 */

const TransactionType = {
  Buy: 'Buy',
  Sell: 'Sell',
  Mint: 'Mint',
  Unmint: 'Unmint',
  Resell: 'Resell',
  AddLiquidity: 'AddLiquidity',
  RemoveLiquidity: 'RemoveLiquidity',
  Exercise: 'Exercise',
  Withdraw: 'Withdraw',
  TransferFrom: 'TransferFrom',
  TransferTo: 'TransferTo'
}

/**
 * @typedef TransactionType
 * @enum {string}

 * @typedef {object} TransactionDataPool
 * @property {string} id
 * @property {string} tokenA Address
 * @property {BigNumber} tokenADecimals
 * @property {string} tokenB Address
 * @property {BigNumber} tokenBDecimals

 * @typedef {object} TransactionDataOption
 * @property {string} id
 * @property {string} strikeAsset Address
 * @property {BigNumber} strikeAssetDecimals
 *
 * @property {string} underlyingAsset Address
 * @property {BigNumber} underlyingAssetDecimals
 *
 * @property {BigNumber} strikePrice
 * @property {BigNumber} expiration
 * @property {TransactionDataPool} pool

 * @typedef {object} TransactionData
 * @property {string} hash
 * @property {string} id
 * @property {TransactionType} type
 * @property {BigNumber} timestamp
 * @property {BigNumber} inputTokenA
 * @property {BigNumber} inputTokenB
 * @property {BigNumber} outputTokenA
 * @property {BigNumber} outputTokenB
 * @property {TransactionDataOption} option

 * @typedef {object} TransactionPreview
 *
 * @property {string} title
 * @property {string} underlyingAssetSymbol
 * @property {string} strikeAssetSymbol
 * @property {string} stableAssetSymbol
 * @property {string} timestamp
 * @property {string} strikePrice
 * @property {string} expiration
 * @property {string} amountTransacted
 * @property {string} amountTransactedInfo
 */

/*
 * Class representing a Transaction
 * @class
 */

class Transaction {
  /**
   * @type {string}
   */
  id = null

  /**
   * Preloaded networkId
   * @type {string}
   */
  networkId = null

  /**
   * @type {TransactionData}
   */

  data = {}

  /**
   * @type {TransactionPreview}
   */

  preview = {}

  constructor (payload, networkId) {
    this._init(payload, networkId)
  }

  _init (payload, networkId) {
    try {
      if (_.isNil(payload)) throw new Error('Missing payload')
      if (_.isNil(networkId)) return

      this.id = _.get(payload, 'id')
      this.networkId = networkId

      this.data = {
        id: _.get(payload, 'id'),
        hash: _.get(payload, 'hash'),
        type: _.get(payload, 'type'),
        timestamp: new BigNumber(_.get(payload, 'timestamp')).times(1000),
        inputTokenA: new BigNumber(_.get(payload, 'inputTokenA')),
        inputTokenB: new BigNumber(_.get(payload, 'inputTokenB')),
        outputTokenA: new BigNumber(_.get(payload, 'outputTokenA')),
        outputTokenB: new BigNumber(_.get(payload, 'outputTokenB')),
        option: {
          id: _.get(payload, 'option.id'),
          strikeAsset: _.get(payload, 'option.strikeAsset'),
          strikeAssetDecimals: new BigNumber(
            _.get(payload, 'option.strikeAssetDecimals')
          ),
          underlyingAsset: _.get(payload, 'option.underlyingAsset'),
          underlyingAssetDecimals: new BigNumber(
            _.get(payload, 'option.underlyingAssetDecimals')
          ),
          strikePrice: new BigNumber(_.get(payload, 'option.strikePrice')),
          expiration: new BigNumber(_.get(payload, 'option.expiration')).times(
            1000
          ),
          pool: {
            id: _.get(payload, 'option.pool.id'),
            tokenA: _.get(payload, 'option.pool.tokenA'),
            tokenADecimals: new BigNumber(
              _.get(payload, 'option.pool.tokenADecimals')
            ),
            tokenB: _.get(payload, 'option.pool.tokenB'),
            tokenBDecimals: new BigNumber(
              _.get(payload, 'option.pool.tokenBDecimals')
            )
          }
        }
      }

      this.preview = this._configurePreview()
    } catch (error) {
      console.error('Transaction', error)
    }
  }

  _configurePreview () {
    let title, amountTransacted, amountTransactedInfo

    const addresses = tokens[this.networkId]

    const underlyingAssetSymbol = tokenAddressToTokenKey(
      this.data.option.underlyingAsset,
      addresses
    )

    const strikeAssetSymbol = tokenAddressToTokenKey(
      this.data.option.strikeAsset,
      addresses
    )
    const stableAssetSymbol = tokenAddressToTokenKey(
      this.data.option.pool.tokenB,
      addresses
    )

    const strikePrice = numeral(
      this.data.option.strikePrice
        .dividedBy(new BigNumber(10).pow(this.data.option.strikeAssetDecimals))
        .toNumber()
    ).format('$0,0.[00]')

    const expiration = dayjs(this.data.option.expiration.toNumber()).format(
      'MMM DD'
    )
    const timestamp = dayjs(this.data.timestamp.toNumber()).format(
      'MMM DD, hh:mm'
    )

    const inputTokenAFlattened = this.data.inputTokenA
      .dividedBy(
        new BigNumber(10).pow(this.data.option.underlyingAssetDecimals)
      )
      .toNumber()
    const inputTokenBFlattened = this.data.inputTokenB
      .dividedBy(new BigNumber(10).pow(this.data.option.strikeAssetDecimals))
      .toNumber()
    const outputTokenAFlattened = this.data.outputTokenA
      .dividedBy(
        new BigNumber(10).pow(this.data.option.underlyingAssetDecimals)
      )
      .toNumber()
    const outputTokenBFlattened = this.data.outputTokenB
      .dividedBy(new BigNumber(10).pow(this.data.option.strikeAssetDecimals))
      .toNumber()
    const strikePriceFlattened = this.data.option.strikePrice
      .dividedBy(new BigNumber(10).pow(this.data.option.strikeAssetDecimals))
      .toNumber()

    switch (this.data.type) {
      case TransactionType.Buy: {
        title = 'Buy Options'
        amountTransacted = `${numeral(outputTokenAFlattened).format(
          FORMAT.N
        )} options`
        amountTransactedInfo = `Paid ${numeral(inputTokenBFlattened).format(
          FORMAT.M
        )} in premium`
        break
      }
      case TransactionType.Sell: {
        title = 'Write Options'
        amountTransacted = `${numeral(
          new BigNumber(inputTokenBFlattened)
            .dividedBy(strikePriceFlattened)
            .toNumber()
        ).format(FORMAT.N)} options`

        amountTransactedInfo = `Locked ${strikeAssetSymbol} ${numeral(
          inputTokenBFlattened
        ).format(FORMAT.M)} to earn ${numeral(outputTokenBFlattened).format(
          FORMAT.M
        )}`
        break
      }
      case TransactionType.Resell: {
        title = 'Resell Options'
        amountTransacted = `${numeral(
          new BigNumber(inputTokenAFlattened).toNumber()
        ).format(FORMAT.N)} options`

        amountTransactedInfo = `Resold options to earn ${numeral(
          outputTokenBFlattened
        ).format(FORMAT.M)}`
        break
      }
      case TransactionType.Exercise: {
        title = 'Exercise Options'

        amountTransacted = `${numeral(inputTokenAFlattened).format(
          FORMAT.N
        )} options`

        amountTransactedInfo = `Exercised for ${numeral(
          outputTokenBFlattened
        ).format(FORMAT.M)} in ${strikeAssetSymbol}`

        break
      }
      case TransactionType.Withdraw: {
        title = 'Withdraw Collateral'

        amountTransacted = `${[
          [
            this.data.outputTokenA,
            `${numeral(outputTokenAFlattened).format(
              FORMAT.N
            )} ${underlyingAssetSymbol}`
          ],
          [
            this.data.outputTokenB,
            `${numeral(outputTokenBFlattened).format(
              FORMAT.N
            )} ${stableAssetSymbol}`
          ]
        ]
          .filter(pair => !pair[0].isZero())
          .map(pair => pair[1])
          .join(' and ')}`

        amountTransactedInfo = 'Withdrawn'
        break
      }
      case TransactionType.Mint: {
        title = 'Mint Options'
        amountTransacted = `${numeral(outputTokenAFlattened).format(
          FORMAT.N
        )} options`

        amountTransactedInfo = `Locked ${numeral(inputTokenBFlattened).format(
          FORMAT.M
        )} in ${strikeAssetSymbol}`
        break
      }
      case TransactionType.Unmint: {
        title = 'Unmint Options'
        amountTransacted = `${numeral(inputTokenAFlattened).format(
          FORMAT.N
        )} options`

        amountTransactedInfo = `Unlocked ${numeral(
          outputTokenBFlattened
        ).format(FORMAT.M)} in ${strikeAssetSymbol}`
        break
      }
      case TransactionType.AddLiquidity: {
        title = 'Add Liquidity'

        amountTransacted = `${[
          [
            this.data.inputTokenA,
            `${numeral(inputTokenAFlattened).format(FORMAT.N)} options`
          ],
          [
            this.data.inputTokenB,
            `${numeral(inputTokenBFlattened).format(
              FORMAT.N
            )} ${stableAssetSymbol}`
          ]
        ]
          .filter(pair => !pair[0].isZero())
          .map(pair => pair[1])
          .join(' and ')}`

        amountTransactedInfo = 'Provided to the pool'
        break
      }
      case TransactionType.RemoveLiquidity: {
        title = 'Remove Liquidity'

        amountTransacted = `${[
          [
            this.data.outputTokenA,
            `${numeral(outputTokenAFlattened).format(FORMAT.N)} options`
          ],
          [
            this.data.outputTokenB,
            `${numeral(outputTokenBFlattened).format(
              FORMAT.N
            )} ${stableAssetSymbol}`
          ]
        ]
          .filter(pair => !pair[0].isZero())
          .map(pair => pair[1])
          .join(' and ')}`

        amountTransactedInfo = 'Removed from to the pool'
        break
      }
      case TransactionType.TransferTo: {
        title = 'Receive Options'
        amountTransacted = `${numeral(outputTokenAFlattened).format(
          '0,0.[0000000000]'
        )} options`

        amountTransactedInfo = 'Received from another wallet'
        break
      }
      case TransactionType.TransferFrom: {
        title = 'Send Options'
        amountTransacted = `${numeral(inputTokenAFlattened).format(
          '0,0.[0000000000]'
        )} options`

        amountTransactedInfo = 'Sent to another wallet'

        break
      }
      default: {
        title = 'Transaction'
        amountTransacted = ''
        amountTransactedInfo = ''
      }
    }

    return {
      title,

      inputTokenAFlattened,
      inputTokenBFlattened,
      outputTokenAFlattened,
      outputTokenBFlattened,
      strikePriceFlattened,

      amountTransacted,
      amountTransactedInfo,
      underlyingAssetSymbol,
      strikeAssetSymbol,
      stableAssetSymbol,
      timestamp,
      strikePrice,
      expiration
    }
  }
}

export { TransactionType }
export default Transaction
