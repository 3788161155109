import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import IconAdd from '@material-ui/icons/PlaylistAddRounded'
import IconCheck from '@material-ui/icons/CheckRounded'
import IconTest from '@material-ui/icons/VpnLockRounded'
import { useWalletChainRequest } from '../../../../../../hooks'

const WrapperPartial = styled.div`
  position: relative;
  grid-column: span 1;
  border-radius: calc(${props => props.theme.sizes.edge} * 3 / 4);
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.sizes.edge};
  padding-right: calc(${props => props.theme.sizes.edge} * 1 / 2);
  padding-left: calc(${props => props.theme.sizes.edge} * 3 / 2);
  min-height: 100%;
  overflow: hidden;
`

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 100;
  min-height: 80px;
  position: relative;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  flex: 1;
  background-color: ${props => props.theme.colors.white};
  flex-shrink: 0;

  & > img {
    height: 48px;
    width: 100%;
    max-width: 140px;
    object-fit: contain;
    &[src=''],
    &:not([src]) {
      opacity: 0;
    }
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    margin-left: 12px;
    &:first-child {
      margin-left: 16px;
    }
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.styles.boxShadowInset};
  transition: background-color 200ms;

  & > svg {
    font-size: 16pt;
    color: ${props => props.theme.colors.dark};
    transform: translateX(0px);
    transition: transform 200ms;
  }
`

const Wrapper = styled(WrapperPartial)`
  &[data-added='false'] {
    ${IconWrapper} {
      cursor: pointer;
      & > svg {
        color: ${props => props.theme.colors.dark};
      }
      transition: background-color 150ms;

      &:hover,
      &:active {
        background-color: ${props => props.theme.colors.platform};
        transition: background-color 150ms;
      }

      &[data-dim='true'] {
        & > svg {
          color: ${props => props.theme.colors.content};
        }
      }
    }
  }
`

function Network ({ icon, isAdded, main, test }) {
  const request = useWalletChainRequest()

  return (
    <Wrapper data-added={isAdded}>
      <Content>
        <ImageWrapper>
          <img src={icon} alt='' />
        </ImageWrapper>
        <Actions>
          {isAdded ? (
            <IconWrapper>
              <IconCheck />
            </IconWrapper>
          ) : (
            <>
              {main && (
                <IconWrapper title='Add network' onClick={() => request(main)}>
                  <IconAdd />
                </IconWrapper>
              )}
              {test && (
                <IconWrapper
                  title='Add test network'
                  onClick={() => request(test)}
                  data-dim='true'
                >
                  <IconTest />
                </IconWrapper>
              )}
            </>
          )}
        </Actions>
      </Content>
    </Wrapper>
  )
}

Network.propTypes = {
  isAdded: PropTypes.bool,
  icon: PropTypes.string,
  main: PropTypes.shape({}),
  test: PropTypes.shape({})
}

Network.defaultProps = {
  isAdded: false,
  icon: null,
  main: null,
  test: null
}

export default Network
