import Actions from './Actions'
import PodAsset from './PodAsset'
import PodPair from './PodPair'
import Price from './Price'
import Text from './Text'
import TextDeck from './TextDeck'
import Reward from './Reward'
import Timestamp from './Timestamp'

import TransactionHeader from './Transaction/Header'
import TransactionActions from './Transaction/Actions'
import TransactionAmount from './Transaction/Amount'

export const layout = {
  Actions: 'Actions',
  PodAsset: 'PodAsset',
  PodPair: 'PodPair',
  Price: 'Price',
  Text: 'Text',
  TextDeck: 'TextDeck',
  Reward: 'Reward',
  Timestamp: 'Timestamp',

  TransactionHeader: 'TransactionHeader',
  TransactionActions: 'TransactionActions',
  TransactionAmount: 'TransactionAmount'
}

export default {
  Actions: Actions,
  PodAsset: PodAsset,
  PodPair: PodPair,
  Price: Price,
  Text: Text,
  TextDeck: TextDeck,
  Reward: Reward,
  Timestamp: Timestamp,

  TransactionHeader: TransactionHeader,
  TransactionActions: TransactionActions,
  TransactionAmount: TransactionAmount
}
