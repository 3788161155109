import _ from 'lodash'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import numeral from 'numeral'
import BigNumber from 'bignumber.js'
import { useAccount, useWeb3 } from './web3'
import { useToken } from './utility'
import { useOptionsContext } from '../contexts/Option'
import { OptionPutABI } from '../contracts'
import { macros } from '../constants'

import { interpretTimeWindows, interpretAPR } from '../utils'
import { useOwnBalance } from './balance'

export function useOptionAddressParam (force) {
  const params = useParams()
  const result = useMemo(
    () => String(force || _.get(params, 'id')).toLowerCase(),
    [params, force]
  )
  return result
}

export function useOptions () {
  const { options: result } = useOptionsContext()
  return result
}

export function useOptionsArchive () {
  const { archive } = useOptionsContext()
  return archive
}

export function useOptionsRefresh () {
  const { refresh } = useOptions()
  return { refresh }
}

/**
 *
 * @param {string} force The address of the option we're looking for. By default, it will search the URL for an id/address.
 * 1) The logic below will first search for the option in the active options list (already fetched).
 * 2) If it does not find it there, it will look in the archive options list (already fetched).
 * 3) If it does not find the option, but the address is marked as *contained in the archive* it will trigger a fetch & store
 *    that will validate question 2) in the next effect cycle.
 */
export function useOption (force) {
  const options = useOptions()
  const archive = useOptionsArchive()

  const address = useOptionAddressParam(force)

  const item = useMemo(() => {
    let result = null
    if (_.isNil(result)) {
      result = options.list.find(
        o => o && o.address.toLowerCase() === address.toLowerCase()
      )
    }
    if (_.isNil(result)) {
      result = archive.list.find(
        o => o && o.address.toLowerCase() === address.toLowerCase()
      )
    }

    return result
  }, [options.list, archive.list, address])

  useEffect(() => {
    if (
      _.isNil(item) &&
      !archive.requested.find(element => element.address === address)
      // && archive.supported.includes(address) [HOTFIX] Allow for any kind of archived option
    ) {
      archive.register(address)
    }
  }, [address, item, archive])

  return {
    item,
    version: options.version,
    isLoading: options.isLoading
  }
}

export function useOwnInvestments (wallet = null, isFiltering = true) {
  const web3 = useWeb3()
  const { address, networkId } = useAccount()
  const { list: options, isLoading: isLoadingOptions } = useOptions()

  const [positions, setPositions] = useState({
    list: [],
    isLoading: true
  })

  const owner = useMemo(() => wallet || address, [wallet, address])

  const fetchInvestments = useCallback(
    async (options, owner, setPositions, web3) => {
      const web3NetworkId = await web3.eth.net.getId()
      if (
        _.isNil(networkId) ||
        _.isNil(web3NetworkId) ||
        _.toString(networkId) !== _.toString(web3NetworkId)
      ) { return }

      setPositions({
        list: [],
        isLoading: false
      })
      const instructions = options.map(option => {
        return option
          .getLockedCollateral(owner)
          .then(lockedBalance => {
            option.lockedBalance = lockedBalance.isGreaterThan(
              macros.MINIMUM_BALANCE_AMOUNT
            )
              ? lockedBalance.toNumber()
              : 0
            return option
          })
          .catch(() => {
            return null
          })
      })

      await Promise.all(instructions).then(options => {
        const owned = isFiltering
          ? options.filter(
            option =>
              option && option.lockedBalance > macros.MINIMUM_BALANCE_AMOUNT
          )
          : options
        setPositions({
          list: owned,
          isLoading: false
        })
      })
    },
    [isFiltering, networkId]
  )

  useEffect(() => {
    if (options && owner && !isLoadingOptions) {
      fetchInvestments(options, owner, setPositions, web3)
    } else if (options && _.isNil(owner)) {
      setPositions({
        list: [],
        isLoading: false
      })
    }
  }, [options, owner, fetchInvestments, setPositions, isLoadingOptions, web3])

  return {
    list: positions.list,
    fetch: fetchInvestments,
    isLoading: isLoadingOptions || positions.isLoading
  }
}

export function useOwnInvestment (optionAddress = null, haystack = null) {
  const { list: options, isLoading } = useOwnInvestments(null, false)

  const address = useOptionAddressParam(optionAddress)

  const list = useMemo(() => _.toArray(haystack || options), [
    haystack,
    options
  ])

  return {
    item: list.find(o => o.address.toLowerCase() === address.toLowerCase()),
    isLoading
  }
}

export function useOwnHedges (wallet = null, isFiltering = true) {
  const web3 = useWeb3()
  const { list: options, isLoading: isLoadingOptions } = useOptions()
  const { address, networkId } = useAccount()

  const [balances, setBalances] = useState({
    list: [],
    isLoading: true
  })
  const owner = useMemo(() => wallet || address, [wallet, address])

  const fetchHedges = useCallback(
    async (web3, options, owner, setBalances) => {
      const web3NetworkId = await web3.eth.net.getId()
      if (
        _.isNil(networkId) ||
        _.isNil(web3NetworkId) ||
        _.toString(networkId) !== _.toString(web3NetworkId)
      ) { return }

      const instructions = options.map(option => {
        const contract = new web3.eth.Contract(OptionPutABI, option.address)
        return contract.methods
          .balanceOf(owner)
          .call()
          .then(balance => {
            const value = new BigNumber(balance).dividedBy(
              10 ** option.decimals
            )
            option.balance = value.isGreaterThan(macros.MINIMUM_BALANCE_AMOUNT)
              ? value.toNumber()
              : 0
            return option
          })
          .catch(error => {
            console.error('useOwnHedges', error)
            return null
          })
      })

      setBalances(prev => ({
        ...prev,
        isLoading: true
      }))

      const result = await Promise.all(instructions)
        .then(options => options.filter(o => !_.isNil(o)))
        .then(options => {
          const owned = isFiltering
            ? options.filter(
              option => option.balance > macros.MINIMUM_BALANCE_AMOUNT
            )
            : options
          setBalances({ list: owned, isLoading: false })
          return owned
        })

      return result
    },
    [isFiltering, networkId]
  )

  useEffect(() => {
    if (web3 && options && owner && !isLoadingOptions) {
      fetchHedges(web3, options, owner, setBalances)
    } else if (web3 && _.isNil(owner)) {
      setBalances(prev => ({
        ...prev,
        isLoading: false
      }))
    }
  }, [web3, options, owner, isLoadingOptions, fetchHedges, setBalances])

  return {
    list: balances.list,
    fetch: fetchHedges,
    isLoading: isLoadingOptions || balances.isLoading
  }
}

export function useOwnHedge (option = null, haystack = null) {
  const { list: options, isLoading } = useOwnHedges(null, false)

  const address = useOptionAddressParam(option)
  const list = useMemo(() => _.toArray(haystack || options), [
    haystack,
    options
  ])

  return {
    item: list.find(o => o.address.toLowerCase() === address.toLowerCase()),
    isLoading
  }
}

export function useOwnPools (wallet = null, filtered = false) {
  const web3 = useWeb3()
  const { address, networkId } = useAccount()
  const { list: options, isLoading: isLoadingOptions } = useOptions()
  const { fetch: fetchBalance } = useOwnBalance()

  const [resolved, setResolved] = useState({
    list: [],
    isLoading: true
  })

  const owner = useMemo(() => wallet || address, [wallet, address])

  const fetchPoolsData = useCallback(
    async (options, owner, filtered, setResolved, web3) => {
      const web3NetworkId = await web3.eth.net.getId()
      if (
        _.isNil(networkId) ||
        _.isNil(web3NetworkId) ||
        _.toString(networkId) !== _.toString(web3NetworkId)
      ) { return }

      const instructions = options.map(async option => {
        const pool = await option.getPool()
        const data = {
          liquidity: null,
          position: null,
          balance: null
        }

        try {
          data.liquidity = await pool.getPoolLiquidity()
        } catch (e) {
          console.error('OP Liquidity', e)
        }

        try {
          data.balance = [
            (await fetchBalance(_.get(option, 'address'))) || 0,
            0
          ]
        } catch (e) {
          console.error('OP Balance', e)
        }
        try {
          data.position = await pool.getPoolPosition(owner)
        } catch (e) {
          console.error('OP Position', e)
        }

        option.poolData = data

        return option
      })

      setResolved(prev => ({
        ...prev,
        isLoading: true
      }))

      const result = await Promise.all(instructions)
        .then(list => list.filter(o => !_.isNil(o)))
        .then(list =>
          filtered
            ? list.filter(
              item =>
                !_.isNil(item.poolData.position) &&
                  !_.toArray(item.poolData.position).some(pos => pos === 0)
            )
            : list
        )
        .then(list => {
          setResolved({ list, isLoading: false })
        })
        .catch(e => {
          console.error('OP Position')
        })

      return result
    },
    [fetchBalance, networkId]
  )

  useEffect(() => {
    if (web3 && options && owner && !isLoadingOptions) {
      fetchPoolsData(options, owner, filtered, setResolved, web3)
    } else if (web3 && _.isNil(owner)) {
      setResolved(prev => ({
        ...prev,
        isLoading: false
      }))
    }
  }, [
    web3,
    options,
    owner,
    filtered,
    isLoadingOptions,
    fetchPoolsData,
    setResolved
  ])

  return {
    count: 2,
    list: resolved.list,
    fetch: fetchPoolsData,
    isLoading: isLoadingOptions || resolved.isLoading
  }
}

/**
 *
 * @param {*} option
 * @returns {object} result = {value: [ option:number, token: number ], isLoading: boolean}
 */
export function useOptionPoolLiquidity (option = null) {
  const [liquidity, setLiquidity] = useState([0, 0])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const get = async () => {
      try {
        setIsLoading(true)
        const pool = option?.getPool()
        const result = await pool.getPoolLiquidity()
        setLiquidity(Object.values(result))
      } catch (e) {
        console.error('OP Liquidity', e)
        setLiquidity([null, null])
      } finally {
        setIsLoading(false)
      }
    }

    if (option && option?.getPool()) get()
  }, [option, setLiquidity])

  return {
    value: liquidity,
    isLoading
  }
}

/**
 *
 * @param {*} option
 * @returns {object} result = {value: [ option:number, token: number ], isLoading: boolean}
 */
export function useOptionPoolPosition (option = null) {
  const { address } = useAccount()
  const [position, setPosition] = useState([0, 0])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const get = async () => {
      try {
        setIsLoading(true)
        const pool = option?.getPool()
        const result = await pool.getPoolPosition(address)
        setPosition(Object.values(result))
      } catch (e) {
        console.error('OP Position hook', e)
        setPosition([null, null])
      } finally {
        setIsLoading(false)
      }
    }

    if (address && option && option?.getPool()) get()
  }, [address, option, setPosition])

  return {
    value: position,
    isLoading
  }
}

export function useOptionPoolPriceProperties (option = null) {
  const [properties, setProperties] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const get = async () => {
      try {
        setIsLoading(true)
        const pool = option?.getPool()
        if (!_.isNil(_.get(pool, 'priceProperties'))) {
          setProperties(_.get(pool, 'priceProperties'))
        } else {
          const result = await option?.getPool().getPriceProperties()
          setProperties(result)
        }
        setIsLoading(false)
      } catch (e) {
        console.error('OP Price Properties hook', e)
        setProperties(null)
        setIsLoading(false)
      }
    }

    if (option && option?.getPool()) get()
  }, [option, setProperties])

  return {
    value: properties,
    isLoading
  }
}

export function useOptionsVersion () {
  const options = useOptions()
  return options.version
}

/** TODO:
 * - optimize the useOptionData facade to be used wherever we need to *translate* the Option Model
 * - return a *translate* function as well for when we want to use it individually (similar pattern to fetch:..)
 */

export function useOptionData (force = null, haystack = null) {
  const { item: option, version, isLoading } = useOption(force, haystack)

  const { value: tokenUnderlying } = useToken(
    _.toString(_.get(option, 'underlyingAssetSymbol')).toUpperCase()
  )
  const { value: tokenCollateral } = useToken(
    _.toString(_.get(option, 'strikeAssetSymbol')).toUpperCase()
  )

  const pool = !_.isNil(option) ? option.getPool() : null

  const { value: tokenPremium } = useToken(
    _.toString(_.get(pool, 'tokenBSymbol'))
  )

  const strike = useMemo(() => _.get(option, 'strikePrice'), [option])
  const times = useMemo(() => interpretTimeWindows(option), [option])
  const APR = useMemo(() => interpretAPR(option), [option])
  const { expiration, expirationToToday, isExercised, isExpired } = times

  const formattedStrike = useMemo(
    () => `${numeral(strike).format('0.[0000]')}`,
    [strike]
  )
  const labelStrike = useMemo(
    () => `${formattedStrike} ${_.get(tokenCollateral, 'symbol')}`,
    [tokenCollateral, formattedStrike]
  )

  const {
    value: liquidity,
    isLoading: isLiquidityLoading
  } = useOptionPoolLiquidity(option)

  return {
    poolAddress: _.get(pool, 'address'),
    tokenCollateral,
    tokenUnderlying,
    tokenPremium,
    labelStrike,
    formattedStrike,

    strike,
    expiration,

    APR,
    times,
    expirationToToday,
    isExpired,
    isExercised,

    liquidity,

    item: option,
    isLoading: isLoading || isLiquidityLoading,

    version
  }
}
