import _ from 'lodash'
import { useMemo, useCallback } from 'react'
import { useWeb3Context } from '../contexts/Web3'

export function useAccount () {
  const { account, provider, web3, cached } = useWeb3Context()
  const networkId = useMemo(() => _.get(account, 'networkId'), [account])
  const address = useMemo(() => _.get(account, 'address'), [account])
  const ens = useMemo(() => _.get(account, 'ens'), [account])

  const isResolving = useMemo(() => _.get(account, 'isResolving') || false, [
    account
  ])

  const isExpected = useMemo(() => !_.isNil(cached), [cached])

  const isConnected = useMemo(
    () => !_.isNil(provider) && !_.isNil(web3) && !_.isNil(address),
    [address, provider, web3]
  )

  return {
    address,
    ens,
    networkId,
    isResolving,
    isConnected,
    isExpected
  }
}

export function useNetworkId () {
  const { networkId } = useAccount()
  return networkId
}

export function useWalletModal () {
  const { connect, disconnect } = useWeb3Context()
  return { connect, disconnect }
}

export function useWeb3 () {
  const { web3 } = useWeb3Context()
  return web3
}
export function useProvider () {
  const { provider } = useWeb3Context()
  return provider
}

export function useWalletChainRequest () {
  const provider = useProvider()
  const { address } = useAccount()

  const request = useCallback(
    chain => {
      try {
        if (!_.isNil(provider) && _.has(provider, 'request')) {
          provider
            .request({
              method: 'wallet_addEthereumChain',
              params: [chain, address]
            })
            .then(result => {
              console.info('Chain support', result)
            })
            .catch(error => {
              console.error('Chain support', error)
            })
        }
      } catch (e) {
        console.error('Chain support', e)
      }
    },
    [address, provider]
  )

  return request
}

export function useWalletTokenRequest ({
  tokenAddress,
  tokenSymbol,
  tokenDecimals
}) {
  const provider = useProvider()
  const { networkId } = useAccount()

  const request = useCallback(() => {
    try {
      if (
        !_.isNil(provider) &&
        !_.isNil(networkId) &&
        _.has(provider, 'request')
      ) {
        provider
          .request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address: tokenAddress,
                symbol: tokenSymbol,
                decimals: tokenDecimals
                // image: tokenImage
              }
            }
          })
          .then(result => {
            console.info('Token support', result)
          })
          .catch(error => {
            console.error('Token support', error)
          })
      }
    } catch (e) {
      console.error('Token support', e)
    }
  }, [networkId, provider, tokenAddress, tokenSymbol, tokenDecimals])

  return request
}
