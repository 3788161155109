import _ from 'lodash'
import React, { createContext, useContext, useState } from 'react'
import Theme from 'shared'
import BigNumber from 'bignumber.js'

/**
 * columns: define the format of the table, column names and weight
 * rows: actual data to display inside the table
 * instructions: methods to be triggered in certain key moments (e.g. onRowClick)
 * expected: a rough value for the number of expected rows, for a better Placeholder-Loader layout (number of loader-rows)
 * empty: layout case for the isEmpty (read-only) state (e.g. default is 0)
 * isLoading: boolean to toggle the Placeholder-Loader state
 */
const initial = {
  columns: [],
  rows: [],
  instructions: {
    onRowClick: () => {}
  },
  expected: 1,
  empty: 0,
  isLoading: true,
  theme: Theme.constants.table.theme.classic,
  footer: null
}

export const Context = createContext()

export default function Provider ({ children }) {
  const [data, setData] = useState(initial)
  const value = {
    data,
    setData
  }
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useTable () {
  return useContext(Context)
}

export function useRows () {
  const { data } = useTable()
  const rows = _.toArray(_.get(data, 'rows'))
  return {
    list: rows
  }
}

export function useColumns () {
  const { data } = useTable()
  const columns = _.toArray(_.get(data, 'columns'))
  return {
    list: columns
  }
}

export function useInstructions () {
  const { data } = useTable()
  const instructions = _.get(data, 'instructions')

  return instructions
}

export function useInfo () {
  const { data } = useTable()

  const rows = _.toArray(_.get(data, 'rows'))
  const expected =
    new BigNumber(_.get(data, 'expected')).toNumber() || initial.expected
  const empty = new BigNumber(_.get(data, 'empty')).toNumber() || initial.empty
  const theme = _.get(data, 'theme') || initial.theme
  const footer = _.get(data, 'footer') || null
  const isLoading = _.get(data, 'isLoading')
  const isEmpty = rows.length === 0 && !isLoading

  return { expected, isLoading, isEmpty, empty, theme, footer }
}

export function useSize () {
  const { list: columns } = useColumns()

  return (
    _.attempt(() =>
      columns
        .map(c => {
          const weight = _.get(c, 'weight')
          if (_.isNil(weight)) return '1fr'
          if (_.isNumber(weight)) return `${weight}fr`
          return weight
        })
        .join(' ')
    ) || 0
  )
}
