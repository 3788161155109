import _ from 'lodash'
import BigNumber from 'bignumber.js'
import React, { useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import SpecialButton from '../../../../../shared/SpecialButton'
import { Structure, Section, Summary } from '../../../../../shared/Tabs'
import {
  Form as FormPartial,
  Step,
  Label,
  Input
} from '../../../../../shared/Form'

import { tabs, types } from '../../../../../../constants'
import reducers from '../../../../../../reducers'
import machines, { guards } from '../../../../../../machines'
import {
  useOptionData,
  useOwnInvestment,
  useOwnBalance,
  useOwnMintedOptions,
  useFormValidator
} from '../../../../../../hooks'
import { analytics } from '../../../../../../vendors'
import { toSignificantInput } from '../../../../../../utils'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Form = styled(FormPartial)`
  max-width: 538px;
  div[data-step='actions'] {
    max-width: 100%;
    & > div:first-child {
      min-width: 226px;
    }
  }
`

const Info = styled.p``

function initialize ({
  elements,
  dispatch,
  strike,
  unwindableOptions,
  tokenUnderlying,
  tokenCollateral
}) {
  dispatch([], 'RESET')
  dispatch([
    elements.options,
    {
      max: unwindableOptions,
      token: [
        _.get(tokenUnderlying, 'symbol'),
        _.get(tokenCollateral, 'symbol')
      ]
    }
  ])

  dispatch([
    elements.collateral,
    {
      token: _.get(tokenCollateral, 'symbol'),
      max: _.isFinite(unwindableOptions)
        ? new BigNumber(unwindableOptions).multipliedBy(strike).toNumber()
        : undefined
    }
  ])
}

function safeCompute ({ options = null, collateral = null, strike }) {
  if (!_.isNil(options)) {
    const result = new BigNumber(options).multipliedBy(strike).toNumber()

    return {
      options,
      collateral: _.isFinite(result)
        ? toSignificantInput(result, BigNumber.ROUND_DOWN)
        : ''
    }
  } else if (!_.isNil(collateral)) {
    const result = new BigNumber(collateral).dividedBy(strike).toNumber()

    return {
      collateral,
      options: _.isFinite(result)
        ? toSignificantInput(result, BigNumber.ROUND_UP)
        : ''
    }
  }
}

function Unwind () {
  const { isLoading: isOptionLoading } = useOwnInvestment()
  const {
    item: option,
    tokenUnderlying,
    tokenCollateral,
    strike,
    version
  } = useOptionData()

  const {
    value: balanceOptions,
    isLoading: isBalanceOptionsLoading
  } = useOwnBalance(_.get(option, 'address'))

  const {
    value: mintedOptions,
    isLoading: isBalanceMintedOptionsLoading
  } = useOwnMintedOptions(option)

  const unwindableOptions = useMemo(() => {
    return Math.min(mintedOptions, balanceOptions)
  }, [mintedOptions, balanceOptions])

  const { elements, state, dispatch } = reducers.unwind.useReducer()
  const machine = machines.unwind.useMachine()

  /**
   *
   * Form setters and state updaters
   *
   */

  const onChangeAmount = useCallback(
    (options = null, collateral = null) => {
      const values = safeCompute({ options, collateral, strike })

      dispatch([
        elements.options,
        {
          value: values.options,
          warning:
            _.toString(values.options) !== ''
              ? guards.isAmountValid({
                value: values.options,
                max: unwindableOptions
              })
              : null
        }
      ])

      dispatch([
        elements.collateral,
        {
          value: values.collateral
        }
      ])
    },
    [dispatch, elements, unwindableOptions, strike]
  )

  const onTransact = useCallback(() => {
    analytics.track(e => e.transactionUnwindTrigger)
    machine.send(machine.events.save, {
      payload: {
        state,
        option
      }
    })
  }, [machine, state, option])

  /**
   *
   * User interface setters and state updaters
   *
   */

  const onCollateralRefocus = useCallback(
    isCollateralPrimary => {
      if (isCollateralPrimary && _.get(state, 'collateral.isPrimary')) return

      dispatch([
        elements.collateral,
        {
          isPrimary: isCollateralPrimary
        }
      ])

      dispatch([
        elements.options,
        {
          isPrimary: !isCollateralPrimary
        }
      ])
    },
    [dispatch, elements, state]
  )

  /**
   *
   * Automated effects
   *
   */

  useEffect(() => {
    if (
      !isOptionLoading &&
      !isBalanceOptionsLoading &&
      !isBalanceMintedOptionsLoading
    ) {
      initialize({
        strike,
        unwindableOptions,
        elements,
        dispatch,
        tokenUnderlying,
        tokenCollateral,
        version
      })
    }
  }, [
    strike,
    unwindableOptions,
    elements,
    dispatch,
    tokenUnderlying,
    tokenCollateral,
    version,

    isOptionLoading,
    isBalanceOptionsLoading,
    isBalanceMintedOptionsLoading
  ])

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  return (
    <Structure hash={tabs.pool.unwind}>
      <Wrapper>
        <Section
          title='Manage position'
          isContained
          isLoading={isOptionLoading}
          isDisabled={[
            machine.states.validate,
            machine.states.process
          ].includes(machine.current.value)}
        >
          <Form>
            <Step>
              <Label>Step 1. Collateral to unlock</Label>
              <Input.Amount
                {...state.options}
                placeholder='Enter amount'
                isLoading={
                  isBalanceOptionsLoading || isBalanceMintedOptionsLoading
                }
                onChange={e => onChangeAmount(_.get(e, 'target.value'), null)}
                onClick={() => onCollateralRefocus(false)}
              />

              <Step>
                <Info style={{ whiteSpace: 'nowrap' }}>Will unlock</Info>
                <Input.Amount
                  {...state.collateral}
                  placeholder='0'
                  onChange={e => onChangeAmount(null, _.get(e, 'target.value'))}
                  onClick={() => onCollateralRefocus(true)}
                />
              </Step>
            </Step>

            <Summary
              index={2}
              context={tabs.pool.unwind}
              data={{
                tokenUnderlying,
                tokenCollateral
              }}
              allow={null}
              transact={
                <SpecialButton.Transact
                  action={types.action.invest}
                  token={_.get(option, 'address')}
                  title='Unmint'
                  isDisabled={!isFormValid}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </Section>
      </Wrapper>
    </Structure>
  )
}

export default Unwind
