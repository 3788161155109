import { useMemo, useCallback, useEffect, useState } from 'react'
import { useAccount } from './web3'

/**
 *
 * @param {Option} option - Option instance
 * @param {String} wallet - Address of the owner
 */
export function useOwnLockedCollateral (option, wallet = null) {
  const { address } = useAccount()
  const owner = useMemo(() => wallet || address, [wallet, address])

  const [balance, setBalance] = useState({
    value: null,
    isLoading: true
  })

  const fetchBalance = useCallback(async ({ option, owner }) => {
    setBalance({
      value: null,
      isLoading: true
    })
    const value = await option.getLockedCollateral(owner)

    setBalance({
      value,
      isLoading: false
    })
    return value
  }, [])

  useEffect(() => {
    if (option && owner) {
      fetchBalance({ option, owner })
    }
  }, [option, owner, fetchBalance])

  return {
    value: balance.value,
    isLoading: balance.isLoading,
    fetch: fetchBalance
  }
}

export function useOwnMintedOptions (option, wallet = null) {
  const { address } = useAccount()
  const owner = useMemo(() => wallet || address, [wallet, address])

  const [balance, setBalance] = useState({
    value: null,
    isLoading: true
  })

  const fetchBalance = useCallback(async ({ option, owner }) => {
    setBalance({
      value: null,
      isLoading: true
    })
    const value = await option.getMintedOptions(owner)

    setBalance({
      value,
      isLoading: false
    })

    return value
  }, [])

  useEffect(() => {
    if (option && owner) {
      fetchBalance({ option, owner })
    }
  }, [option, owner, fetchBalance])

  return {
    value: balance.value,
    isLoading: balance.isLoading,
    fetch: fetchBalance
  }
}

/**
 *
 * @param {*} option
 * @returns {object} result = {value: [ option:number, token:number ], isLoading: boolean}
 */
export function useOwnWithdrawbleAmount (option, wallet = null) {
  const { address } = useAccount()
  const owner = useMemo(() => wallet || address, [wallet, address])

  const [amount, setAmount] = useState({
    value: null,
    isLoading: true
  })

  const fetchAmount = useCallback(async ({ option, owner }) => {
    setAmount({
      value: null,
      isLoading: true
    })

    try {
      const value = await option.getSellerWithdrawAmounts(owner)

      setAmount({
        value,
        isLoading: false
      })

      return value
    } catch (e) {
      console.error('Withdrawable', e)
      setAmount({
        value: [0, 0],
        isLoading: false
      })
    }

    return null
  }, [])

  useEffect(() => {
    if (option && owner) {
      fetchAmount({ option, owner })
    }
  }, [option, owner, fetchAmount])

  return {
    value: amount.value,
    isLoading: amount.isLoading,
    fetch: fetchAmount
  }
}
