import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import IconArrow from '@material-ui/icons/ArrowBackRounded'
import IconArrowKey from '@material-ui/icons/KeyboardArrowRight'
import Account from '../../Account'
import Notice from '../../Notice'
import { pages } from '../../../../constants'
import { useBackLogic } from '../../../../hooks'
import { findPageByRoute } from '../../../../utils'

const WrapperPartial = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: calc(${props => props.theme.sizes.layoutEdge});
`

const Content = styled.div`
  flex: 1;
`

const Back = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  transition: box-shadow 150ms, background-color 150ms, border 150ms;
  cursor: pointer;
  flex-shrink: 0;

  & > svg {
    font-size: 16pt;
    color: ${props => props.theme.colors.dark};
    transition: color 150ms, transform 150ms;
  }

  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.dark};
    border: 1px solid ${props => props.theme.colors.dark};
    box-shadow: ${props => props.theme.styles.boxShadowHover};
    transition: box-shadow 150ms, background-color 150ms, border 150ms;

    & > svg {
      color: ${props => props.theme.colors.white};
      transition: color 150ms, transform 150ms;
    }
  }
`
const Info = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: ${props => props.theme.sizes.layoutEdge};
  padding-right: ${props => props.theme.sizes.edge};
`

const Title = styled.p`
  font-size: 16pt;
  font-weight: 700;
  color: ${props => props.theme.colors.dark};
  margin: 0 0 4px 0;
  line-height: 1.3;
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.medium} {
    width: 100%;
    align-items: center;
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
    min-height: ${props => props.theme.sizes.navHeight};
    background-color: ${props => props.theme.colors.white};
    border-bottom: 1px solid ${props => props.theme.colors.border};

    & > ${Content} {
      display: none;
    }

    & > ${Info} {
      padding-left: ${props => props.theme.sizes.layoutEdgeMedium};
      padding-right: calc(${props => props.theme.sizes.edge} * 1);
      padding-top: 4px;
      margin: 0 auto;
      & > ${Title} {
        font-size: 13pt;
      }
    }
  }
`

const BreadcrumbsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: calc(${props => props.theme.sizes.edge} * 0.3);

  &:last-child {
    padding: 0;
  }

  & > p {
    position: relative;
    font-size: 11pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0;

    &:after {
      opacity: 0;
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -3px;
      left: 0;
      background-color: ${props => props.theme.colors.dark};
    }
  }

  & > svg {
    font-size: 11pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin-left: calc(${props => props.theme.sizes.edge} * 0.3);
  }

  &:not([data-active='false']) {
    cursor: pointer;
    & > p {
      &:hover,
      &:active {
        color: ${props => props.theme.colors.dark};
        &:after {
          opacity: 1;
        }
      }
    }
  }
`

const Breadcrumbs = styled.div`
  display: flex;
  ${props => props.theme.medias.medium} {
    display: none;
  }
`

function ToolbarTransaction ({ className, children, parent, title, subtitle }) {
  const { goBack, parent: toBack } = useBackLogic(parent ? parent.route : '/')

  return (
    <Wrapper className={className}>
      <Back onClick={() => goBack()}>
        <IconArrow />
      </Back>
      <Info>
        <Title title={subtitle}>{title}</Title>
        <Breadcrumbs>
          <BreadcrumbsItem onClick={() => goBack()}>
            <p>{_.get(findPageByRoute(toBack), 'title')}</p>
            <IconArrowKey />
          </BreadcrumbsItem>
          <BreadcrumbsItem data-active='false'>
            <p>Transaction</p>
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Info>
      <Content>{children}</Content>
      <Notice />
      <Account />
    </Wrapper>
  )
}

ToolbarTransaction.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  parent: PropTypes.shape({}),
  title: PropTypes.string,
  subtitle: PropTypes.string
}

ToolbarTransaction.defaultProps = {
  className: null,
  children: null,
  parent: pages.dashboard,
  title: '',
  subtitle: ''
}

export default ToolbarTransaction
