import React from 'react'
import styled from 'styled-components'
import Base from '../../../shared/Modal'
import { modals } from '../../../../constants'
import { ReactComponent as AssetLogo } from '../../../../assets/logo/logo_gradient.svg'

const Modal = styled(Base)`
  & > div[data-component='card'] {
    max-width: 500px;
    border-radius: ${props => props.theme.sizes.edge};
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: calc(${props => props.theme.sizes.edge} * 1);
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  padding-top: calc(${props => props.theme.sizes.edge} * 2);
  padding-bottom: calc(${props => props.theme.sizes.edge} * 1);

  border-top: 1px solid ${props => props.theme.colors.grayBlueLight};
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  &:last-child {
    margin-bottom: 0;
  }
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  & > p {
    color: ${props => props.theme.colors.dark};
    font-size: 13pt;
    font-weight: 600;
    line-height: 1.5;
    margin: 0 0 10px 0;
  }
`

const Description = styled.div`
  width: 100%;
  text-align: center;
`

const Paragraph = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: 11pt;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: center;

  a {
    color: ${props => props.theme.colors.middle};
    font-weight: 600;
  }
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  & > svg {
    height: 48px;
    width: auto;
  }
`

function Risk () {
  const id = modals.risk

  return (
    <Modal id={id} title=''>
      <Content>
        <Logo>
          <AssetLogo />
        </Logo>
        <Info>
          <Section>
            <Title>
              <p>Risk acknowledgement</p>
            </Title>
            <Description>
              <Paragraph>
                The current live version of the contracts isn't fully audited.
                Use this software at your own risk.
              </Paragraph>
            </Description>
          </Section>
        </Info>
      </Content>
    </Modal>
  )
}

export default Risk
