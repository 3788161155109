import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'
import IconResell from '@material-ui/icons/LowPriorityRounded'
import IconWrite from '@material-ui/icons/SwapVerticalCircleRounded'
import IconWithdraw from '@material-ui/icons/GetAppRounded'
import Helmet from '../../../Helmet'
import * as Tabs from '../../Tabs/Invest'

import { tabs } from '../../../../../constants'
import { Bar, Tab, Loader, Missing } from '../../../../shared/Tabs'
import { useOptionData } from '../../../../../hooks'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding-bottom: ${props => props.theme.sizes.edge};
  padding-top: calc(${props => props.theme.sizes.edge} * 1 / 2);

  ${props => props.theme.medias.medium} {
    padding-bottom: 0;
    padding-top: 2px;
    border-radius: 0;
  }
`

const Content = styled.div`
  width: 100%;
`

function TabsInvest () {
  const { addToast, removeAllToasts } = useToasts()
  const {
    isLoading,
    times,
    item,
    tokenUnderlying,
    tokenCollateral,
    strike
  } = useOptionData()
  const { isExercised, isExpired } = times

  const [locked, fallback] = useMemo(() => {
    if (isExercised) {
      // after exercise window ( exercise < X )
      return [[tabs.invest.write, tabs.invest.resell], tabs.invest.withdraw]
    }
    if (isExpired) {
      // exercise window ( expiration < X < exercise)
      return [[tabs.invest.write, tabs.invest.resell], tabs.invest.withdraw]
    }

    return [[tabs.invest.withdraw], tabs.invest.write] // before  (X < expiration < exercise)
  }, [isExercised, isExpired])

  return (
    <Wrapper>
      <Helmet.TransactionInvest
        data={{ tokenUnderlying, tokenCollateral, strike, times }}
      />
      <Bar
        fallback={fallback}
        supported={Object.values(tabs.invest)}
        locked={locked}
      >
        <Tab
          Icon={IconWrite}
          title='Write'
          hash={tabs.invest.write}
          isLocked={locked.includes(tabs.invest.write)}
          onLockedClick={() => {
            removeAllToasts()
            addToast('Selling as allowed only before the expiration date.', {
              appearance: 'warning',
              autoDismiss: true,
              autoDismissTimeout: 5000
            })
          }}
        />

        <Tab
          Icon={IconResell}
          title='Resell'
          hash={tabs.invest.resell}
          isLocked={locked.includes(tabs.invest.resell)}
          onLockedClick={() => {
            removeAllToasts()
            addToast(
              'Reselling is available only before the expiration date.',
              {
                appearance: 'warning',
                autoDismiss: true,
                autoDismissTimeout: 5000
              }
            )
          }}
        />

        <Tab
          Icon={IconWithdraw}
          title='Withdraw'
          hash={tabs.invest.withdraw}
          onLockedClick={() => {
            removeAllToasts()
            addToast(
              'Withdrawing your collateral is available only after the exercise window.',
              {
                appearance: 'warning',
                autoDismiss: true,
                autoDismissTimeout: 5000
              }
            )
          }}
          isLocked={locked.includes(tabs.invest.withdraw)}
        />
      </Bar>
      <Content>
        {!isLoading && _.isNil(item) ? (
          <Missing />
        ) : (
          <>
            {!locked.includes(tabs.invest.write) && <Tabs.Sell />}
            {!locked.includes(tabs.invest.resell) && <Tabs.Resell />}
            {!locked.includes(tabs.invest.withdraw) && <Tabs.Withdraw />}
          </>
        )}
      </Content>
      <Loader isActive={isLoading} />
    </Wrapper>
  )
}

export default TabsInvest
