import { css } from "styled-components";

const ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const extensions = {
  ellipsis,
};

export default extensions;
