import { useState, useCallback } from 'react'

export function useRefresh () {
  const [version, setVersion] = useState(0)
  const refresh = useCallback(() => {
    setVersion(prev => prev + 1)
  }, [setVersion])
  return {
    refresh,
    version
  }
}
