import namehash from 'eth-ens-namehash'
import _ from 'lodash'

export async function resolveAddressToENS (address, web3Instance) {
  try {
    const sanitize = _.toString(address).toLowerCase()
    const lookup = `${
      sanitize.startsWith('0x') ? sanitize.substr(2) : sanitize
    }.addr.reverse`
    const resolver = await web3Instance.eth.ens.resolver(lookup)
    const hash = namehash.hash(lookup)
    const name = await resolver.methods.name(hash).call()
    return name
  } catch (e) {
    return null
  }
}
