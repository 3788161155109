import React from 'react'
import ReactDOM from 'react-dom'
import App from './pages/App'
import * as vendors from './vendors'
import { _config } from './utils'

_config()

/**
 * The Watcher component will activate special page-level events,
 * while the atomic .track() will do aomtic events
 */
vendors.initialize()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
