import React from 'react'
import styled from 'styled-components'
import Section from '../../../shared/Section'
import RestrictedNetwork from '../RestrictedNetwork'

const Content = styled.div`
  width: 100%;
`

function Dashboard () {
  return (
    <Section title='Account Overview'>
      <Content>
        <RestrictedNetwork />
      </Content>
    </Section>
  )
}

export default Dashboard
