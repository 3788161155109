import _ from 'lodash'
import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Helper } from 'shared'
import SpecialButton from '../../../../../shared/SpecialButton'
import { Structure, Section, Summary, Extra } from '../../../../../shared/Tabs'
import {
  Form as FormPartial,
  Step,
  Label,
  Input
} from '../../../../../shared/Form'
import { tabs, tokens } from '../../../../../../constants'
import reducers from '../../../../../../reducers'
import machines, { guards } from '../../../../../../machines'
import {
  useOwnBalance,
  useOptionData,
  useOptionSellPrice,
  useFormValidator,
  useFormAssetAllowance,
  useTokenAllowanceForLater
} from '../../../../../../hooks'
import { analytics } from '../../../../../../vendors'
import { toSignificantInput, toNumeralPrice } from '../../../../../../utils'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Form = styled(FormPartial)``

const Cell = styled.div`
  display: flex;
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  &[data-step='2'] {
    & > ${Cell} {
      &:first-child {
        padding-right: ${props => props.theme.sizes.edge};
        width: 174px;
        p,
        input {
          text-align: left !important;
          overflow-x: hidden;
        }
      }
      &:last-child {
        flex: 1;
      }
    }
  }

  ${props => props.theme.medias.small} {
    &[data-step='2'] {
      & > ${Cell} {
        &:first-child,
        &:last-child {
          width: auto !important;
          flex: 1;
        }
      }
    }
  }
`

const Info = styled.p`
  white-space: nowrap;
  ${props => props.theme.medias.medium} {
    white-space: normal;
    font-size: 9pt;
  }
`

function initialize ({
  balance,
  tokenUnderlying,
  tokenCollateral,
  elements,
  dispatch,
  tokenPremium
}) {
  dispatch([], 'RESET', [elements.allowance])
  dispatch([
    elements.underlying,
    {
      token: _.get(tokenUnderlying, 'symbol')
    }
  ])
  dispatch([
    elements.collateral,
    {
      token: _.get(tokenCollateral, 'symbol'),
      max: balance
    }
  ])
  dispatch([
    elements.premiumToken,
    {
      value: _.get(tokenPremium, 'symbol')
    }
  ])
}

function safeCompute ({ option, underlying = null, collateral = null }) {
  if (
    (_.isNil(underlying) || _.isEmpty(_.toString(underlying))) &&
    (_.isNil(collateral) || _.isEmpty(_.toString(collateral)))
  ) {
    return [null, null]
  }

  if (
    _.isNil(option) ||
    _.isNil(option.getOptionAmount) ||
    _.isNil(option.getStrikeAmount)
  ) {
    console.error('Misconfigured option model.')
    return [null, null]
  }

  if (underlying === null) {
    return {
      underlying: toSignificantInput(
        option.getOptionAmount(collateral) || 0,
        BigNumber.ROUND_DOWN
      ),
      collateral
    }
  } else {
    return {
      underlying,
      collateral: toSignificantInput(
        option.getStrikeAmount(underlying) || 0,
        BigNumber.ROUND_UP
      )
    }
  }
}

function Sell () {
  const {
    tokenUnderlying,
    tokenCollateral,
    tokenPremium,
    formattedStrike,
    times,
    item: option,
    isLoading: isOptionLoading,
    version
  } = useOptionData()

  const {
    value: balance,
    isLoading: isBalanceCollateralLoading
  } = useOwnBalance(_.get(option, 'strikeAsset'))
  const { check: safeComputePremium } = useOptionSellPrice()
  const { elements, state, dispatch } = reducers.sell.useReducer()
  const machine = machines.sell.useMachine()
  const setup = useTokenAllowanceForLater()

  /**
   *
   * Form setters and state updaters
   *
   */

  const doAllowanceUpdate = useCallback(
    ({ isAllowed, isLoading }) =>
      dispatch([elements.allowance, { token: isAllowed, isLoading }]),
    [elements, dispatch]
  )

  const doPremiumUpdate = useCallback(
    _.debounce(({ values, elements, dispatch, option, safeComputePremium }) => {
      ;(async () => {
        dispatch([elements.premiumCalculated, { value: null, isLoading: true }])
        if (values === [null, null]) return

        const premium = await safeComputePremium(
          option,
          new BigNumber(values.underlying).toNumber()
        )

        const value = _.get(premium, 'value')
        const fees = _.get(premium, 'fees')

        dispatch([
          elements.premiumCalculated,
          {
            value: toSignificantInput(value || 0, BigNumber.ROUND_DOWN),
            isLoading: false,
            warning:
              _.toString(value) !== ''
                ? guards.isPremiumValid({
                  value
                })
                : null
          }
        ])

        dispatch([elements.fees, { value: fees }])
      })()
    }, 300),
    []
  )

  const onChangeAmount = useCallback(
    (underlying = null, collateral = null) => {
      const values = safeCompute({ option, underlying, collateral })

      dispatch([
        elements.collateral,
        {
          value: values.collateral,
          warning:
            _.toString(values.collateral) !== ''
              ? guards.isAmountValid({
                value: values.collateral,
                max: balance
              })
              : null
        }
      ])
      dispatch([elements.underlying, { value: values.underlying }])
      doPremiumUpdate({
        values,
        elements,
        dispatch,
        option,
        state,
        safeComputePremium
      })
    },
    [
      dispatch,
      elements,
      balance,
      option,
      doPremiumUpdate,
      state,
      safeComputePremium
    ]
  )

  const onPrimaryRefocus = useCallback(
    isCollateralPrimary => {
      if (
        isCollateralPrimary &&
        _.get(state, `${elements.collateral}.isPrimary`) === true
      ) {
        return
      }
      dispatch([
        elements.collateral,
        {
          isPrimary: isCollateralPrimary
        }
      ])

      dispatch([
        elements.underlying,
        {
          isPrimary: !isCollateralPrimary
        }
      ])
    },
    [dispatch, elements, state]
  )

  const onTransact = useCallback(() => {
    analytics.track(e => e.transactionSellTrigger)
    machine.send(machine.events.save, {
      payload: {
        state,
        option,
        collateralTokenAddress: _.get(tokenCollateral, 'address'),
        premiumTokenAddress: _.get(tokenPremium, 'address'),
        setup
      }
    })
  }, [machine, state, option, tokenPremium, tokenCollateral, setup])

  /**
   *
   * Automated effects
   *
   */

  const {
    doApprove: doAllowanceApprove,
    doRefresh: doAllowanceRefresh
  } = useFormAssetAllowance({
    amount: _.get(state, 'collateral.value'),
    tokenAddress: _.get(option, 'strikeAsset'),
    onUpdate: doAllowanceUpdate
  })

  useEffect(() => {
    if (!isOptionLoading && !isBalanceCollateralLoading) {
      initialize({
        balance,
        elements,
        dispatch,
        tokenCollateral,
        tokenUnderlying,
        tokenPremium
      })
      doAllowanceRefresh()
    }
  }, [
    balance,
    tokenUnderlying,
    tokenCollateral,
    tokenPremium,
    elements,
    dispatch,
    version,
    doAllowanceRefresh,

    isOptionLoading,
    isBalanceCollateralLoading
  ])

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  return (
    <Structure hash={tabs.invest.write}>
      <Wrapper>
        <Section
          title='Sell options by locking collateral'
          isContained
          isLoading={isOptionLoading}
          isDisabled={[
            machine.states.validate,
            machine.states.process
          ].includes(machine.current.value)}
        >
          <Form>
            <Step>
              <Label>Step 1. Collateral to lock</Label>
              <Input.Amount
                {...state.collateral}
                placeholder='Enter amount'
                onChange={e => onChangeAmount(null, _.get(e, 'target.value'))}
                onClick={() => onPrimaryRefocus(true)}
              />
              <Step>
                <Info>Willing to buy</Info>
                <Input.Amount
                  placeholder='Enter amount'
                  {...state.underlying}
                  onChange={e => onChangeAmount(_.get(e, 'target.value'), null)}
                  onClick={() => onPrimaryRefocus(false)}
                />
              </Step>
            </Step>
            <Step>
              <Label>Step 2. Expected premium</Label>
              <Row data-step='2'>
                <Cell>
                  <Input.Amount
                    placeholder='0'
                    value={state.premiumCalculated.value}
                    warning={state.premiumCalculated.warning}
                    isLoading={state.premiumCalculated.isLoading}
                    isViewOnly
                  />
                </Cell>
                <Cell>
                  <Input.Token
                    source={Object.values(tokens.keys)}
                    value={state.premiumToken.value}
                    warning={state.premiumToken.warning}
                    isViewOnly
                    placeholder='Premium token'
                    onChange={() => {}}
                  />
                </Cell>
              </Row>
              <Extra>
                <Helper value='The fees will be paid to the liquidity providers, based on the size and price impact of your trade. This amount is included in the premium value.'>
                  <div data-component='pill'>
                    <p>Included fees: </p>
                    <span>
                      {!_.isNaN(_.get(state, 'fees.value')) &&
                        !_.isNil(_.get(state, 'fees.value')) &&
                        toNumeralPrice(_.get(state, 'fees.value'))}
                    </span>
                  </div>
                </Helper>
              </Extra>
            </Step>
            <Summary
              index={3}
              context={tabs.invest.write}
              data={{ state, strike: formattedStrike, times }}
              allow={
                <SpecialButton.AllowToken
                  amount={_.get(state, 'collateral.value')}
                  title={`Allow ${_.get(tokenCollateral, 'symbol')}`}
                  isAllowed={_.get(state, 'allowance.token')}
                  isLoading={_.get(state, 'allowance.isLoading')}
                  onApprove={doAllowanceApprove}
                />
              }
              transact={
                <SpecialButton.Transact
                  title='Sell Options'
                  isDisabled={!isFormValid}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </Section>
      </Wrapper>
    </Structure>
  )
}

export default Sell
