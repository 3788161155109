import _ from 'lodash'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from 'shared'
import { useInfo } from '../../../context'
import { pages } from '../../../../../../constants'
import { useToken } from '../../../../../../hooks'
import { TokenMultiDisplay } from '../../../../../atoms'

import IconSend from '@material-ui/icons/SendRounded'
import { TransactionType } from '../../../../../../models/Transaction'

const WrapperPartial = styled.div`
  grid-column: span 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 70px;
  border-radius: 6px;
  overflow: hidden;
  margin-right: calc(${props => props.theme.sizes.edge} * 1);
  background-color: ${props => props.theme.colors.platform};
  & > svg {
    color: ${props => props.theme.colors.contentMedium};
    font-size: 14pt;
  }
`

const Data = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const Title = styled.p`
  ${props => props.theme.styles.tableParagraph};
  font-size: 12pt;
  font-weight: 600;
`

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
  p {
    font-size: 10pt !important;
    font-weight: 600 !important;
    color: ${props => props.theme.colors.contentMedium} !important;
    margin: 0;
  }
  img {
    height: 18px !important;
    width: 18px !important;
    &:not(:first-child) {
      margin-left: -6px !important;
    }
  }

  & > p {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    &:before {
      content: '-';
      display: inline-flex;
      margin: auto 4px;
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  &[data-theme="${Theme.constants.table.theme.historyLight}"]{
    ${IconWrapper}{
      display: none;
    }
    ${Subtitle} > p{
    display: none;
    }
  }




`

function TransactionHeader ({ data, column }) {
  const { theme } = useInfo()
  const { value: tokens } = useToken([
    _.get(data, 'underlyingAssetSymbol'),
    _.get(data, 'strikeAssetSymbol'),
    _.get(data, 'stableAssetSymbol')
  ])

  const Icon = useMemo(() => {
    switch (_.get(data, 'type')) {
      case TransactionType.Buy:
      case TransactionType.Exercise:
        return pages.hedge.Icon
      case TransactionType.Sell:
      case TransactionType.Withdraw:
        return pages.invest.Icon
      case TransactionType.AddLiquidity:
      case TransactionType.RemoveLiquidity:
      case TransactionType.Mint:
      case TransactionType.Unmint:
        return pages.pool.Icon
      default:
        return IconSend
    }
  }, [data])

  return (
    <Wrapper size={_.get(column, 'weight')} data-theme={theme}>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <Data>
        <Title>{_.get(data, 'title')}</Title>
        <Subtitle>
          <TokenMultiDisplay
            tokens={tokens.slice(0, 2)}
            isMini
            isSelfPadded={false}
          />
          <p>{_.get(data, 'strikePrice')}</p>
          <p>{_.get(data, 'expiration')}</p>
        </Subtitle>
      </Data>
    </Wrapper>
  )
}

TransactionHeader.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    underlyingAssetSymbol: PropTypes.string,
    strikeAssetSymbol: PropTypes.string,
    stablecoinAssetSymbol: PropTypes.string,
    strikePrice: PropTypes.string,
    expiration: PropTypes.string
  }),
  column: PropTypes.shape({
    size: PropTypes.number
  })
}

TransactionHeader.defaultProps = {
  data: {
    value: null,
    type: null,
    title: null,
    underlyingAssetSymbol: null,
    strikeAssetSymbol: null,
    stablecoinAssetSymbol: null,
    strikePrice: null,
    expiration: null
  },
  column: {
    size: 1
  }
}

export default TransactionHeader
