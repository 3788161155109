import _ from 'lodash'
import BigNumber from 'bignumber.js'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useToasts } from 'react-toast-notifications'
import { ERC20ABI } from '../contracts'
import { ETH_DECIMALS, MATIC_DECIMALS, MAX_UINT } from '../constants'
import { useWeb3, useAccount } from './web3'
import { useOptionsVersion } from './option'
import { useAddresses } from './utility'
import {
  approveTransfer,
  getAllowance,
  isEth,
  isEthereumChain,
  isMatic,
  isPolygonChain
} from '../utils'

export function useApproveTokenAllowance ({ spender, owner, setIsLoading }) {
  const web3 = useWeb3()
  const { addToast, removeAllToasts } = useToasts()

  const approve = useCallback(
    async ({ amount, tokenAddress, onUpdate }) => {
      try {
        if (amount && tokenAddress) {
          setIsLoading(true)

          addToast('Allowance update in progress...', {
            appearance: 'warning',
            autoDismiss: true,
            autoDismissTimeout: 30000
          })

          await approveTransfer({
            web3,
            tokenAddress,
            owner,
            spender
          })
          onUpdate(true)

          removeAllToasts()

          addToast('Allowance update successful!', {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 5000
          })
        }
      } catch (error) {
        removeAllToasts()

        addToast('Issue when updating allowance.', {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })

        console.error('Allowance', { error })
      } finally {
        setIsLoading(false)
      }
    },
    [owner, web3, addToast, removeAllToasts, spender, setIsLoading]
  )

  return approve
}

/**
 *
 * Fetch allowance on "tokenAddress" (and spender/owner) updates, check against it on "amount" updates.
 *
 * @param {object} props
 * @param {number} amount Amount to be checked or approved
 * @param {string} tokenAddress Token address
 * @param {string} spender Spender address e.g. the user wallet or the exchange
 * @param {string} owner Forced owner of the tokens - defaults to user wallet
 */
export function useTokenAllowance ({
  amount,
  tokenAddress,
  spender: forceSpender,
  owner: forceOwner
}) {
  const web3 = useWeb3()
  const version = useOptionsVersion()
  const { address, networkId } = useAccount()
  const { optionHelper } = useAddresses()
  const owner = useMemo(() => forceOwner || address, [forceOwner, address])
  const spender = useMemo(() => forceSpender || optionHelper, [
    forceSpender,
    optionHelper
  ])

  const [isLoading, setIsLoading] = useState(false)

  const approve = useApproveTokenAllowance({ owner, spender, setIsLoading })

  const [data, setData] = useState({
    allowance: new BigNumber(0),
    decimals: 0
  })

  const fetchAllowance = useCallback(
    async ({ tokenAddress }) => {
      let value = null
      let decimals = null
      try {
        if (isEth(tokenAddress, networkId) && isEthereumChain(networkId)) {
          return { allowance: MAX_UINT, decimals: ETH_DECIMALS }
        }
        if (isMatic(tokenAddress, networkId) && isPolygonChain(networkId)) {
          return { allowance: MAX_UINT, decimals: MATIC_DECIMALS }
        }

        if (_.isNil(tokenAddress) || _.isNil(owner)) return {}

        setIsLoading(true)

        const contract = new web3.eth.Contract(ERC20ABI, tokenAddress)

        decimals = await contract.methods.decimals().call()
        value = await getAllowance({
          web3,
          tokenAddress,
          owner,
          spender
        })

        setData({ allowance: value, decimals })
      } catch (error) {
        console.error('Allowance', error)
      } finally {
        setIsLoading(false)
      }

      return { allowance: value, decimals }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3, networkId, spender, owner]
  )

  useEffect(() => {
    if (tokenAddress && spender && owner) {
      fetchAllowance({ tokenAddress })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAddress, spender, owner, fetchAllowance, version])

  const checkIsAllowed = useCallback(
    ({ amount, allowance, decimals, tokenAddress }) => {
      if (
        _.isNil(tokenAddress) ||
        _.isNil(amount) ||
        _.isNilOrEmptyString(amount) ||
        _.isNaN(amount)
      ) {
        return false
      }

      if (isEth(tokenAddress, networkId) && isEthereumChain(networkId)) {
        return true
      }
      if (isMatic(tokenAddress, networkId) && isPolygonChain(networkId)) {
        return true
      }

      const check = new BigNumber(amount).multipliedBy(10 ** parseInt(decimals))

      if (
        (check.isZero() || check.isNaN()) &&
        !(allowance.isZero() || allowance.isNaN())
      ) {
        return true
      }

      return allowance.isGreaterThanOrEqualTo(check)
    },
    [networkId]
  )

  const isAllowed = useMemo(() => {
    const { allowance, decimals } = data
    const result = checkIsAllowed({ amount, allowance, decimals, tokenAddress })
    return result
  }, [amount, data, tokenAddress, checkIsAllowed])

  /**
   * External check method for special non-hook cases
   */

  const externalCheck = useCallback(
    async ({ amount, tokenAddress }) => {
      const value = await fetchAllowance({ tokenAddress })
      const { allowance, decimals } = value
      const result = checkIsAllowed({
        amount,
        allowance,
        decimals,
        tokenAddress
      })

      return result
    },
    [checkIsAllowed, fetchAllowance]
  )

  const refresh = useCallback(() => fetchAllowance({ tokenAddress }), [
    fetchAllowance,
    tokenAddress
  ])

  return {
    approve,
    check: externalCheck,
    refresh,
    value: isAllowed,
    isLoading: isLoading
  }
}

export async function getTokenAllowance ({
  tokenAddress,
  owner,
  spender,
  web3,
  amount = null
}) {
  try {
    const networkId = await web3.eth.net.getId()

    if (isEth(tokenAddress, networkId) && isEthereumChain(networkId)) {
      return { allowance: MAX_UINT, decimals: ETH_DECIMALS, isAllowed: true }
    }
    if (isMatic(tokenAddress, networkId) && isPolygonChain(networkId)) {
      return { allowance: MAX_UINT, decimals: MATIC_DECIMALS, isAllowed: true }
    }

    if (_.isNil(tokenAddress) || _.isNil(owner)) return null

    const contract = new web3.eth.Contract(ERC20ABI, tokenAddress)

    const decimals = await contract.methods.decimals().call()
    const value = await getAllowance({
      web3,
      tokenAddress,
      owner,
      spender
    })

    if (!_.isNil(amount)) {
      const check = new BigNumber(amount).multipliedBy(10 ** parseInt(decimals))
      let isAllowed = false
      if (
        (check.isZero() || check.isNaN()) &&
        !(value.isZero() || value.isNaN())
      ) {
        isAllowed = true
      } else isAllowed = value.isGreaterThanOrEqualTo(check)

      return { allowance: value, decimals, isAllowed }
    }

    return { allowance: value, decimals, isAllowed: undefined }
  } catch (error) {
    console.error('Allowance', error)
    return null
  }
}

export function useTokenAllowanceForLater () {
  const web3 = useWeb3()
  const { address: owner } = useAccount()
  const { optionHelper: spender } = useAddresses()

  return {
    web3,
    owner,
    spender,
    getTokenAllowance
  }
}
