import _ from 'lodash'
import { useRef, useEffect, useMemo, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { networks, tokens } from '../constants'
import { useNetworkId } from './web3'
import { useUIContext } from '../contexts/UI'
import { getAddresses } from '../utils'

export function useUI () {
  return useUIContext()
}

export function useUIModals () {
  const { modals } = useUIContext()
  return modals
}

export function useAddresses () {
  const networkId = useNetworkId()
  const list = useMemo(() => getAddresses(networkId), [networkId])
  return list
}

/**
 * If an array is received, it will try to return all the matching tokens
 * @param {String|Array} key
 */
export function useToken (key) {
  const networkId = useNetworkId()

  const builder = useCallback(
    _item => {
      const item = _.toString(_item).toUpperCase()
      const token = _.get(tokens[networkId], item)
      const tokenMainnet = _.get(tokens[networks.mainnet], item)

      if (_.isNil(token)) return null

      const icon = _.get(token, 'icon')
        ? _.get(token, 'icon')
        : _.get(tokenMainnet, 'address')
          ? `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${_.get(
            tokenMainnet,
            'address'
          )}/logo.png`
          : null

      return {
        ...token,
        icon,
        key: item
      }
    },
    [networkId]
  )

  const result = useMemo(() => {
    if (_.isNil(key)) return null

    if (_.isString(key)) {
      return builder(key, networkId)
    }

    if (_.isArray(key)) {
      return key
        .map(item => builder(item, networkId))
        .filter(item => !_.isNil(item))
    }

    return null
  }, [key, networkId, builder])

  return { value: result, get: builder }
}

/**
 * If an array is received, it will try to return all the matching tokens
 * @param {String|Array} key
 */
export function useTokenByAddress (key) {
  const networkId = useNetworkId()

  const getSymbolByAddress = useCallback(
    address => {
      if (_.isNil(address)) return null
      if (_.isNil(tokens) || _.isNil(tokens[networkId])) return null

      const found = Object.values(tokens[networkId]).find(
        token =>
          String(_.get(token, 'address')).toLowerCase() ===
          String(address).toLowerCase()
      )

      if (_.isNil(found)) return null

      return _.get(found, 'symbol')
    },
    [networkId]
  )

  const query = useMemo(() => {
    if (!_.isNil(key)) {
      if (_.isString(key)) {
        return getSymbolByAddress(key)
      } else if (_.isArray(key)) {
        return key
          .map(item => getSymbolByAddress(item))
          .filter(item => !_.isNil(item))
      }
    }

    return null
  }, [key, getSymbolByAddress])

  const result = useToken(query)

  return result
}

export function useOnClickOutside (handler, reference = null) {
  const temp = useRef()
  const ref = reference || temp

  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) return
      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])

  return [ref]
}

export function useBackLogic (fallback = '/') {
  const history = useHistory()
  const { entry } = useUI()

  const parent = useMemo(() => {
    if (!_.isNil(entry) && _.has(entry, 'pathname')) {
      return _.get(entry, 'pathname')
    }
    return fallback
  }, [entry, fallback])

  const goBack = useCallback(() => {
    try {
      history.push(parent)
    } catch (e) {
      console.error('Back button', e)
    }
  }, [history, parent])

  return { goBack, parent }
}

export function useWindowSize () {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    function handleResize () {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export function useModal (id) {
  const { list, send } = useUIModals()

  const modal = useMemo(() => list.find(item => item.id === id), [list, id])

  const { isOpen, data: modalData } = useMemo(
    () => modal || { id, isOpen: false, data: null },
    [modal, id]
  )

  const setOpen = useCallback(
    (state = true, data = null) => {
      if (state) {
        send('open', { id, data })
      } else {
        send('close', { id, data })
      }
    },
    [send, id]
  )

  const setData = useCallback(
    data => {
      send('set', { id, data })
    },
    [send, id]
  )

  const updateData = useCallback(
    data => {
      send('update', { id, data })
    },
    [send, id]
  )

  return {
    isOpen,
    setOpen,
    setData,
    updateData,
    data: modalData
  }
}

export function useSupportedNetwork () {
  const networkId = useNetworkId()
  return networks._supported.includes(networkId) || _.isNil(networkId)
}

export function useLocalStorage (key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.error('Local storage', error)
      return initialValue
    }
  })

  const setValue = useCallback(
    value => {
      try {
        setStoredValue(value)
        if (_.isNil(value)) window.localStorage.removeItem(key)
        window.localStorage.setItem(key, JSON.stringify(value))
      } catch (error) {
        console.error('Local storage', error)
      }
    },
    [key]
  )

  return [storedValue, setValue]
}
