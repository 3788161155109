import _ from 'lodash'
import React from 'react'
import { Card, Title, Description, Content } from '../atoms'

import Input from '../../../../Form/Input'

function Expiration ({ reducer }) {
  return (
    <Card>
      <Title>Expiration Date</Title>
      <Description>
        Choose a date. Hint: options usually expire on Thursdays.
      </Description>
      <Content>
        <Input.Dater
          {...reducer.state.expiration}
          placeholder='Any | Pick date ...'
          onChange={e => {
            reducer.dispatch([
              reducer.elements.expiration,
              {
                value: _.get(e, 'target.value'),
                milliseconds: _.get(e, 'target.value')
                  ? Date.parse(_.get(e, 'target.value'))
                  : null
              }
            ])
          }}
        />
      </Content>
    </Card>
  )
}

export default Expiration
