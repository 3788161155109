import useAtomicReducer from './atoms'

const initial = {
  options: {
    value: null,
    exposure: null,
    max: null,
    token: null,
    warning: null,
    isPrimary: true
  },
  collateral: {
    value: null,
    exposure: null,
    max: null,
    token: null,
    warning: null,
    isPrimary: true
  }
}

export default {
  useReducer: () => useAtomicReducer(initial)
}
