import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import SectionBase from '../../../../shared/Section'
import { ReactComponent as Decorator } from '../../../../../assets/decorators/oval_group_small.svg'
import { tokens, networks, chains } from '../../../../../constants'
import { useToken } from '../../../../../hooks'

import LogoPolygon from '../../../../../assets/images/logo_polygon.png'
import LogoEthereum from '../../../../../assets/images/logo_ethereum.png'

import Asset from './Asset'
import Network from './Network'

const WrapperPartial = styled.div`
  padding: 0 ${props => props.theme.sizes.layoutEdge};
  margin-top: calc(${props => props.theme.sizes.edge} * 2);
  margin-bottom: calc(${props => props.theme.sizes.layoutEdge} * 1);
`
const Card = styled.div`
  background: ${props => props.theme.colors.dark};
  border-radius: ${props => props.theme.sizes.edge};
  padding: ${props => props.theme.sizes.edge};
  overflow: hidden;
  position: relative;

  margin-bottom: calc(${props => props.theme.sizes.edge} * 2);
  &:last-child {
    margin-bottom: 0;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ${props => props.theme.sizes.layoutEdge};
  color: white;
`

const Section = styled(SectionBase)`
  position: relative;
  z-index: 200;
  *[data-component='header'] > {
    *[data-component='title'] > p,
    *[data-component='description'] {
      color: ${props => props.theme.colors.white};
    }
  }
`

const Underlay = styled.div`
  position: absolute;
  z-index: 100;
  top: 32px;
  right: 32px;

  & > svg {
    height: 256px;
    width: 256px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: ${props => props.theme.sizes.edge};
  width: 100%;

  &[data-network='true'] {
    grid-template-columns: repeat(4, 1fr);
  }
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.large} {
    ${Grid} {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  ${props => props.theme.medias.medium} {
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
    margin-bottom: 0;
    ${Card} {
      padding: calc(${props => props.theme.sizes.edge} * 1 / 2);
      border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
    }
    ${Grid} {
      padding: calc(${props => props.theme.sizes.edge} * 1 / 2);
      grid-template-columns: repeat(2, 1fr);
    }
    ${Content} {
      padding: 0;
    }
  }

  ${props => props.theme.medias.medium} {
    ${Grid} {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`

const networkList = [
  {
    id: networks.mainnet,
    icon: LogoEthereum,
    isAdded: true
  },
  {
    id: networks.matic,
    icon: LogoPolygon,
    main: chains.formatter(chains[networks.matic])
  }
]

function Assets () {
  const { value: list } = useToken([
    tokens.keys.LINK,
    tokens.keys.WMATIC,
    tokens.keys.USDC
  ])

  return (
    <Wrapper>
      <Card>
        <Section
          title='Networks'
          description="We're bringing the power of hedging closer to your! Switch between Ethereum's Mainnet and Polygon's Matic."
        >
          <Content>
            <Grid data-network='true'>
              {networkList.map(item => (
                <Network key={_.get(item, 'id')} {...item} />
              ))}
            </Grid>
          </Content>
        </Section>
      </Card>
      <Card>
        <Section
          title='Assets'
          description="Check the available options for the following assets. Don't forget to put your assets to work for you by using smart collateral."
        >
          <Content>
            <Grid>
              {list.map(item => (
                <Asset key={_.get(item, 'symbol')} {...item} />
              ))}
            </Grid>
          </Content>
        </Section>
        <Underlay>
          <Decorator />
        </Underlay>
      </Card>
    </Wrapper>
  )
}

export default Assets
