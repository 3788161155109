import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import Theme from 'shared'
import Chart from './Chart'
import { useMarketPrices, useOptionData } from '../../../../../hooks'

const Wrapper = styled.div`
  width: 100%;

  ${props => props.theme.medias.small} {
    padding: 0 ${props => props.theme.sizes.layoutEdgeMedium};
  }
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${props => props.theme.sizes.edge};
`

function Charts () {
  const { historical, list: spot } = useMarketPrices()
  const { tokenCollateral, tokenUnderlying, isLoading, item } = useOptionData()

  return (
    <Wrapper>
      <Grid>
        <Chart
          token={tokenUnderlying}
          color={Theme.colors.green}
          spot={_.get(spot, _.get(tokenUnderlying, 'symbol'))}
          dataset={_.get(historical, _.get(tokenUnderlying, 'symbol')) || []}
          isRestricted={!isLoading && _.isNil(item)}
        />
        <Chart
          token={tokenCollateral}
          color={Theme.colors.yellow2}
          spot={1}
          dataset={[1, 1]}
          isConstant
          isRestricted={!isLoading && _.isNil(item)}
        />
      </Grid>
    </Wrapper>
  )
}

export default Charts
