import _ from 'lodash'
import { useMemo } from 'react'
import Theme from 'shared'
import { modals } from '../../constants'
import { layout } from '../../components/shared/Table'
import Transaction from '../../models/Transaction'
import { useNetworkId, useModal, useTransactionHistory } from '../../hooks'

export default function useHistoryTable () {
  const networkId = useNetworkId()
  const { setOpen } = useModal(modals.historyOptions)
  const {
    transactions: source,
    isFinished,
    isLoading,
    more
  } = useTransactionHistory()

  const footer = useMemo(
    () =>
      isFinished || (isLoading && !source.length)
        ? null
        : isLoading
          ? 'more-loading'
          : 'more',
    [isFinished, isLoading, source]
  )

  const transactions = useMemo(
    () =>
      source.map(item => {
        const transaction = new Transaction(item, networkId)
        return transaction
      }),
    [networkId, source]
  )

  const columns = useMemo(
    () => [
      {
        title: 'Transaction',
        subtitle: 'Details',
        layout: layout.TransactionHeader,
        weight: 3
      },
      {
        title: 'Amount',
        layout: layout.TransactionAmount,
        weight: 2
      },
      {
        title: 'Date',
        layout: layout.Text
      },
      {
        title: '',
        layout: layout.TransactionActions
      }
    ],
    []
  )

  const instructions = useMemo(
    () => ({
      onRowClick: params => {
        const position = _.get(params, 'position')
        const transaction = _.get(transactions, `[${position}]`)

        if (!_.isNil(transaction)) {
          setOpen(true, { transaction })
        }
      },
      onLoadMore: () => more()
    }),
    [setOpen, more, transactions]
  )
  const rows = useMemo(
    () =>
      transactions.map(transaction => {
        return {
          id: _.get(transaction, 'id'),
          cells: [
            {
              type: _.get(transaction, 'data.type'),
              title: _.get(transaction, 'preview.title'),
              value: _.get(transaction, 'preview.title'),

              underlyingAssetSymbol: _.get(
                transaction,
                'preview.underlyingAssetSymbol'
              ),
              strikeAssetSymbol: _.get(
                transaction,
                'preview.strikeAssetSymbol'
              ),
              stableAssetSymbol: _.get(
                transaction,
                'preview.stableAssetSymbol'
              ),

              strikePrice: _.get(transaction, 'preview.strikePrice'),
              expiration: _.get(transaction, 'preview.expiration')
            },
            {
              value: _.get(transaction, 'preview.amountTransacted'),
              subtitle: _.get(transaction, 'preview.amountTransactedInfo')
            },
            {
              value: _.get(transaction, 'preview.timestamp')
            },
            {
              value: ''
            }
          ]
        }
      }),
    [transactions]
  )

  return {
    data: {
      columns,
      rows,
      instructions,
      /** Cosmetics */
      isLoading: _.isNil(networkId) || (isLoading && !source.length),
      expected: 2,
      empty: 4,
      footer,
      theme: Theme.constants.table.theme.history
    }
  }
}
