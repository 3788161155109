import React, { useMemo } from 'react'
import styled from 'styled-components'
import { usePoolsTable, useSearchFilter } from '../../../../../hooks'
import Section from '../../../../shared/Section'
import TablePartial from '../../../../shared/Table'

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;

  & > div {
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent;
    overflow-x: auto;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb,
    &::-webkit-slider-thumb,
    &::-moz-scrollbar {
      display: none !important;
      height: 0 !important;
      width: 0 !important;
      background: transparent !important;
    }
  }
  & > div::-webkit-scrollbar {
    display: none !important;
    height: 0 !important;
    width: 0 !important;
    background: transparent !important;
  }
`

const TableWrapper = styled.div`
  width: 100%;
`

const Table = styled(TablePartial)`
  min-width: calc(1000px + 2 * ${props => props.theme.sizes.layoutEdge});
  padding: 0 ${props => props.theme.sizes.layoutEdge};

  div[data-component='body'] {
    min-height: 86px;
  }

  ${props => props.theme.medias.medium} {
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
  }
`

function LiquidityPools () {
  const filter = useSearchFilter()
  const { data, info } = usePoolsTable({ filter })

  const title = useMemo(() => {
    let result = 'Liquidity Pools'
    if (info.total !== info.filtered) {
      result += ` (Filtered ${info.filtered}/${info.total})`
    }
    return result
  }, [info])

  /**
   * Figure out the clipboard url.
   * Make sure the link is refreshed after every update.
   */
  const copy = useMemo(() => {
    return window.location.href
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info])

  return (
    <Section
      title={title}
      description='Pools of options and tokens you can deposit into.'
      copy={copy}
    >
      <Content>
        <TableWrapper>
          <Table data={data} />
        </TableWrapper>
      </Content>
    </Section>
  )
}

export default LiquidityPools
