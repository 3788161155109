import useAtomicReducer from './atoms'

const initial = {
  context: {
    value: null,
    source: [],
    warning: null,
    isViewOnly: true
  },
  underlying: {
    value: null,
    source: null,
    warning: null,
    isViewOnly: false
  },
  collateral: {
    value: null,
    source: null,
    warning: null,
    isViewOnly: false
  },
  strike: {
    value: null,
    source: null,
    warning: null,
    isPrimary: true,
    isViewOnly: false
  },
  expiration: {
    value: null,
    milliseconds: null,
    warning: null,
    isViewOnly: false
  },
  placeholder: {
    value: null,
    isLocked: false
  }
}

export default {
  useReducer: () => useAtomicReducer(initial)
}
