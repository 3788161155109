import _ from 'lodash'
import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import useAtomicMachine from './atoms'
import * as guards from './guards'
import { useToasts } from 'react-toast-notifications'
import { useHardRefresh, useModal } from '../hooks'
import { modals } from '../constants'

export function useMachine () {
  const { addToast, removeAllToasts } = useToasts()
  const { refresh } = useHardRefresh()
  const { setOpen, updateData } = useModal(modals.transaction)

  const onPrepare = useCallback(async ({ context }) => {
    const state = _.get(context, 'payload.state')

    const form = await guards.booleanize(() =>
      guards.isFormValid({ value: state, soft: true })
    )

    if (!form) return false

    return true
  }, [])

  const onValidate = useCallback(
    async ({ context }) => {
      const state = _.get(context, 'payload.state')

      const form = await guards.interpret(
        () =>
          guards.isFormValid({
            value: state
          }),
        addToast
      )

      if (form[0] === false) throw new Error(form[1])

      const tokenB = await guards.interpret(
        () =>
          guards.isAmountValid({
            value: _.get(state, 'tokenB.value'),
            max: _.get(state, 'tokenB.max'),
            min: null
          }),
        addToast
      )

      if (tokenB[0] === false) throw new Error(tokenB[1])

      const tokenA = await guards.interpret(
        () =>
          guards.isAmountValid({
            value: _.get(state, 'tokenA.value'),
            max: _.get(state, 'tokenA.max'),
            min: null
          }),
        addToast
      )

      if (tokenA[0] === false) throw new Error(tokenA[1])

      const zeros = await guards.interpret(() => {
        if (
          new BigNumber(_.get(state, 'tokenB.value')).isZero() &&
          new BigNumber(_.get(state, 'tokenA.value')).isZero()
        ) {
          return guards.policy.system.values()
        }
        return null
      }, addToast)

      if (zeros[0] === false) throw new Error(zeros[1])
    },
    [addToast]
  )

  const onProcess = useCallback(
    async ({ context }) => {
      const state = _.get(context, 'payload.state')
      const pool = _.get(context, 'payload.pool')
      const option = _.get(context, 'payload.option')
      const setup = _.get(context, 'payload.setup')

      const amountTokenA = new BigNumber(
        _.get(state, 'tokenA.value')
      ).toNumber()
      const amountTokenB = new BigNumber(
        _.get(state, 'tokenB.value')
      ).toNumber()

      const liquidityLabel = `${amountTokenA} option${
        amountTokenA !== 1 ? 's' : ''
      } and ${amountTokenB} ${_.get(state, 'tokenB.token')}`

      try {
        setOpen(true, {
          state: 'loading',
          tx: null,
          info: `Preparing for adding liquidity with ${liquidityLabel}. Checking allowances...`
        })

        let isAllowed = true

        const allowanceA = await setup.getTokenAllowance({
          ...setup,
          tokenAddress: _.get(option, 'address'),
          amount: _.get(state, 'tokenA.value')
        })

        const allowanceB = await setup.getTokenAllowance({
          ...setup,
          tokenAddress: _.get(option, 'strikeAsset'),
          amount: _.get(state, 'tokenA.value')
        })

        if (_.isNil(allowanceA) || _.get(allowanceA, 'isAllowed') === false) {
          isAllowed = false
        }
        if (_.isNil(allowanceB) || _.get(allowanceB, 'isAllowed') === false) {
          isAllowed = false
        }

        if (!isAllowed) {
          updateData({
            state: 'warning',
            info:
              'It seems that our app is having trouble figuring out your ERC20 allowance. Please reload the page to fix this problem. Thank you for your help and sorry for the inconvenince!'
          })
          return
        }

        updateData({
          info: `Adding liquidity with ${liquidityLabel}.`
        })

        let rejected = null

        const transaction = pool.addLiquidity(
          _.get(option, 'address'),
          amountTokenA,
          amountTokenB,
          (error, transactionHash) => {
            if (error) rejected = error
            updateData({
              tx: transactionHash
            })
          }
        )
        await transaction

        if (!_.isNil(rejected)) throw rejected

        removeAllToasts()
        refresh()

        const success = `Liquidity (${liquidityLabel}) successfully added!`

        updateData({
          state: 'success',
          info: success
        })

        addToast(success, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
      } catch (e) {
        removeAllToasts()

        updateData({
          state: 'error',
          info: `Attempted to add liquidity with ${liquidityLabel}.`
        })

        addToast('Transaction cancelled', {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
        throw e
      }
    },
    [addToast, removeAllToasts, refresh, setOpen, updateData]
  )

  const machine = useAtomicMachine({
    id: 'liquidityAdd',
    onPrepare,
    onValidate,
    onProcess
  })

  return machine
}

export default {
  useMachine
}
