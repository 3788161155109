import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import IconAdd from '@material-ui/icons/PlaylistAddCheckRounded'
import { Button } from 'shared'
import {
  useOnClickOutside,
  useWalletTokenRequest
} from '../../../../../../hooks'
import { analytics } from '../../../../../../vendors'

const WrapperPartial = styled.div`
  position: relative;
  grid-column: span 1;
  border-radius: calc(${props => props.theme.sizes.edge} * 0.75);
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.sizes.edge};
  min-height: 100%;
  overflow: hidden;
`

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  z-index: 100;
  position: relative;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.styles.boxShadowInset};
  flex-shrink: 0;
  transition: box-shadow 150ms;
  & > img {
    height: 42px;
    width: 42px;
    object-fit: contain;
    border-radius: 50%;
    overflow: hidden;
    background-color: ${props => props.theme.colors.platform};
    &[src=''],
    &:not([src]) {
      opacity: 0;
    }
  }
`

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: calc(${props => props.theme.sizes.edge} * 1) 0;

  & > svg {
    font-size: 16pt;
    margin-left: 8px;
    color: ${props => props.theme.colors.dark};
    transform: translateX(0px);
    transition: transform 150ms;
  }
`

const Title = styled.p`
  font-size: 14pt;
  font-weight: 700;
  color: ${props => props.theme.colors.dark};
  margin: 0;
  line-height: 1.5;
`

const Tags = styled.p`
  font-size: 11pt;
  margin: 0 0 6px;
  color: ${props => props.theme.colors.dark};
  font-weight: 600;
  & > span[data-tag='smart'] {
    color: ${props => props.theme.colors.middle};
  }
`

const Overlay = styled.div`
  position: absolute;
  z-index: 200;
  pointer-events: none;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: opacity 250ms;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${props => props.theme.sizes.edge};
  background-color: ${props => props.theme.colors.white};
  transform: translateY(250px);
  transition: transform 250ms;
  & > * {
    margin-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-left: 16px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.styles.boxShadowInset};
  transition: background-color 200ms;
  cursor: pointer;
  transition: background-color 150ms;

  & > svg {
    font-size: 16pt;
    color: ${props => props.theme.colors.dark};
    transform: translateX(0px);
    transition: transform 200ms;
  }

  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.platform};
    transition: background-color 150ms;
  }

  &[data-dim='true'] {
    & > svg {
      color: ${props => props.theme.colors.content};
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  &[data-active='false'] {
    &:hover,
    &:active {
      ${Main} > svg {
        transform: translateX(5px);
        transition: transform 250ms;
      }
    }
  }

  &[data-active='true'] {
    ${Overlay} {
      opacity: 1;
      pointer-events: all;
      transition: opacity 250ms;
      & > ${Inner} {
        transform: translateY(0);
      }
    }
  }

  ${props => props.theme.medias.medium} {
    border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
    ${Inner} {
      margin: 0;
      height: 100%;
    }
  }
`

function Asset ({ symbol, icon, tag, address, decimals }) {
  const [isActive, setIsActive] = useState(false)

  const request = useWalletTokenRequest({
    tokenAddress: address,
    tokenSymbol: symbol,
    tokenDecimals: decimals
  })

  const [tagline] = useMemo(() => {
    if (tag === 'smart') {
      return ['Smart Collateral', 'Invest with it', 'Hedge with it']
    }
    if (tag === 'underlying') {
      return ['Underlying Asset', 'Invest in it', 'Protect it']
    }
    return ['Collateral', 'Invest with it', 'Hedge with it']
  }, [tag])

  const [ref] = useOnClickOutside(() => setIsActive(false))

  return (
    <Wrapper data-active={isActive} ref={ref}>
      <Content
        onClick={() => {
          setIsActive(true)
          analytics.track(e => e.expandDashboardAsset, { asset: symbol })
        }}
      >
        <ImageWrapper>
          <img src={icon} alt='' />
        </ImageWrapper>
        <Main>
          <Title>{symbol}</Title>
          <IconWrapper>
            <IconAdd />
          </IconWrapper>
        </Main>
        <Tags>
          <span data-tag={tag}>{tagline}</span>
        </Tags>
      </Content>
      <Overlay>
        <Inner>
          <Button
            isFullWidth
            appearance={a => a.solid}
            accent={a => a.metamask}
            title='Add to Metamask'
            type={t => t.button}
            onClick={() => {
              request()
            }}
          />
        </Inner>
      </Overlay>
    </Wrapper>
  )
}

export default Asset
