import gql from 'graphql-tag'
import { macros } from '../constants'

export const USER_ACTIONS = gql`
  query userActions($user: Bytes!, $pagination: Int!) {
    actions(
      orderBy: timestamp
      orderDirection: desc
      first: ${macros.HISTORY_PAGINATOR},
      where: { user: $user, timestamp_lt: $pagination }
    ) {
      id
      type
      timestamp
      hash
      inputTokenA
      inputTokenB
      outputTokenA
      outputTokenB
      option {
        id
        underlyingAsset
        underlyingAssetDecimals
        strikeAsset
        strikeAssetDecimals
        strikePrice
        expiration
        pool {
          id
          tokenA
          tokenADecimals
          tokenB
          tokenBDecimals
        }
      }
    }
  }
`

export const USER_OPTION_ACTIONS = gql`
  query userActions($user: Bytes!, $option: Bytes!) {
    actions(
      orderBy: timestamp
      orderDirection: desc
      first: 3
      where: { user: $user, option: $option }
    ) {
      id
      type
      timestamp
      hash
      inputTokenA
      inputTokenB
      outputTokenA
      outputTokenB
      option {
        id
        underlyingAsset
        underlyingAssetDecimals
        strikeAsset
        strikeAssetDecimals
        strikePrice
        expiration
        pool {
          id
          tokenA
          tokenADecimals
          tokenB
          tokenBDecimals
        }
      }
    }
  }
`
