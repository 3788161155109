import useAtomicReducer from './atoms'

const initial = {
  options: {
    max: null,
    value: null,
    token: null,
    warning: null,
    isPrimary: true
  },
  premiumCalculated: {
    value: null,
    warning: null,
    isLoading: false
  },
  premiumToken: {
    value: null,
    warning: null
  },
  fees: {
    value: null,
    warning: null,
    isLoading: false
  },
  allowance: {
    options: false,
    isLoading: false
  }
}

export default {
  useReducer: () => useAtomicReducer(initial)
}
