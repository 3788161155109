import _ from 'lodash'
import React, { useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import SpecialButton from '../../../../../shared/SpecialButton'
import { Structure, Section, Summary } from '../../../../../shared/Tabs'
import {
  Form as FormPartial,
  Step,
  Label,
  Input
} from '../../../../../shared/Form'

import { types, tabs } from '../../../../../../constants'
import reducers from '../../../../../../reducers'
import machines, { guards } from '../../../../../../machines'
import {
  useOptionData,
  useOptionPoolPosition,
  useFormValidator
} from '../../../../../../hooks'
import { analytics } from '../../../../../../vendors'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Form = styled(FormPartial)`
  max-width: 538px;
  div[data-step='actions'] {
    max-width: 100%;
    & > div:first-child {
      min-width: 226px;
    }
  }
`

function computeExposure ({ value: unsanitized, total }) {
  try {
    const value = new BigNumber(unsanitized).toFixed(0)
    if (_.isNil(value) || parseInt(value) === 0) return 0
    if (parseInt(value) === 100) return total

    return new BigNumber(total).multipliedBy(value).dividedBy(100)
  } catch (e) {
    console.error('Exposure', e)
  }
  return 0
}

function initialize ({ elements, dispatch, tokenUnderlying, tokenCollateral }) {
  dispatch([], 'RESET')
  dispatch([
    elements.options,
    {
      value: 0
    }
  ])
  dispatch([
    elements.collateral,
    {
      value: 0,
      token: _.get(tokenCollateral, 'symbol')
    }
  ])
}

function Remove () {
  const {
    tokenUnderlying,
    tokenCollateral,
    item: option,
    isLoading: isOptionLoading,
    times,
    version
  } = useOptionData()

  const { value: position } = useOptionPoolPosition(option)

  const pool = useMemo(
    () => (!isOptionLoading && option ? option.getPool() : null),
    [option, isOptionLoading]
  )

  const balanceOptions = useMemo(() => _.get(position, '[0]') || 0, [position])
  const balanceCollateral = useMemo(() => _.get(position, '[1]') || 0, [
    position
  ])

  const isWithoutPosition = useMemo(
    () =>
      (_.isNil(balanceOptions) && _.isNil(balanceCollateral)) ||
      (new BigNumber(balanceOptions).isZero() &&
        new BigNumber(balanceCollateral).isZero()),
    [balanceOptions, balanceCollateral]
  )

  const { elements, state, dispatch } = reducers.liquidityRemove.useReducer()
  const machine = machines.liquidityRemove.useMachine()

  const isZeroSelected = useMemo(
    () =>
      (_.isNil(_.get(state, 'options.value')) &&
        _.isNil(_.get(state, 'collateral.value'))) ||
      (new BigNumber(_.get(state, 'options.value')).isZero() &&
        new BigNumber(_.get(state, 'collateral.value')).isZero()),
    [state]
  )
  /**
   *
   * Automated effects
   *
   */

  useEffect(() => {
    if (!isOptionLoading) {
      initialize({
        elements,
        dispatch,
        tokenUnderlying,
        tokenCollateral,
        version
      })
    }
  }, [
    elements,
    dispatch,
    tokenUnderlying,
    tokenCollateral,
    isOptionLoading,
    version
  ])

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  /**
   *
   * Reducer state updaters: Form values
   *
   */

  const onChangeOptions = useCallback(
    value => {
      dispatch([
        elements.options,
        {
          value: value || 0,
          exposure: computeExposure({ value, total: balanceOptions }),
          warning:
            _.toString(value) !== ''
              ? guards.isAmountValid({
                value: value,
                max: 100,
                min: null
              })
              : null
        }
      ])

      dispatch([
        elements.collateral,
        {
          value: value || 0,
          exposure: computeExposure({ value, total: balanceCollateral }),
          warning:
            _.toString(value) !== ''
              ? guards.isAmountValid({
                value: value,
                max: 100,
                min: null
              })
              : null
        }
      ])
    },
    [dispatch, elements, balanceOptions, balanceCollateral]
  )

  const onTransact = useCallback(() => {
    analytics.track(e => e.transactionRemoveLiquidityTrigger)
    machine.send(machine.events.save, {
      payload: {
        state,
        option,
        pool
      }
    })
  }, [machine, state, option, pool])

  return (
    <Structure hash={tabs.pool.remove}>
      <Wrapper>
        <Section
          title='Remove liquidity'
          isContained
          isDisabled={isWithoutPosition}
        >
          <Form>
            <Step>
              <Label>Step 1. Remove shares (options and tokens)</Label>
              <Input.Slider
                {...state.options}
                min={0}
                max={100}
                step={1}
                placeholder='How many shares?'
                onChange={e => onChangeOptions(_.get(e, 'target.value'))}
              />
            </Step>
            <Summary
              isForced
              index={2}
              context={tabs.pool.remove}
              data={{
                tokenUnderlying,
                tokenCollateral,
                position,
                state,
                times
              }}
              transact={
                <SpecialButton.Transact
                  action={types.action.pool}
                  title='Remove Liquidity'
                  isDisabled={!isFormValid || isZeroSelected}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </Section>
      </Wrapper>
    </Structure>
  )
}

export default Remove
