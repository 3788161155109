import _ from 'lodash'
import { useCallback } from 'react'
import useAtomicMachine from './atoms'
import * as guards from './guards'
import { useToasts } from 'react-toast-notifications'
import { useHardRefresh, useModal } from '../hooks'
import { modals, macros } from '../constants'
import BigNumber from 'bignumber.js'

const PREMIUM_SLIPPAGE = 1 + macros.DEFAULT_SLIPPAGE
const UNDERLYING_SLIPPAGE = 1 - macros.DEFAULT_SLIPPAGE

export function useMachine () {
  const { addToast, removeAllToasts } = useToasts()
  const { refresh } = useHardRefresh()
  const { setOpen, updateData } = useModal(modals.transaction)

  const onPrepare = useCallback(async ({ context }) => {
    const state = _.get(context, 'payload.state')

    const form = await guards.booleanize(() =>
      guards.isFormValid({ value: state, soft: true })
    )

    if (!form) return false

    return true
  }, [])

  const onValidate = useCallback(
    async ({ context }) => {
      const state = _.get(context, 'payload.state')

      const form = await guards.interpret(
        () =>
          guards.isFormValid({
            value: state
          }),
        addToast
      )

      if (form[0] === false) throw new Error(form[1])

      const underlying = await guards.interpret(
        () =>
          guards.isAmountValid({
            value: _.get(state, 'underlying.value'),
            max: _.get(state, 'underlying.max'),
            context: 'liquidity'
          }),
        addToast
      )

      if (underlying[0] === false) throw new Error(underlying[1])

      const premium = await guards.interpret(
        () =>
          guards.isAmountValid({
            value: _.get(state, 'premium.value'),
            max: _.get(state, 'premium.max')
          }),
        addToast
      )

      if (premium[0] === false) throw new Error(premium[1])
    },
    [addToast]
  )

  const onProcess = useCallback(
    async ({ context }) => {
      const option = _.get(context, 'payload.option')
      const setup = _.get(context, 'payload.setup')
      const state = _.get(context, 'payload.state')
      const premiumTokenAddress = _.get(context, 'payload.premiumTokenAddress')

      let optionsValue = new BigNumber(
        _.get(state, 'underlying.value')
      ).toNumber()
      let premiumValue = new BigNumber(_.get(state, 'premium.value')).toNumber()
      const exact = _.get(state, 'exact.value')

      const optionLabel = `${optionsValue} ${
        optionsValue === 1 ? 'option' : 'options'
      }`
      const modalLabel = `${optionLabel} of ${_.get(
        option,
        'underlyingAssetSymbol'
      ).toUpperCase()}:${_.get(option, 'strikeAssetSymbol').toUpperCase()} Put`

      try {
        setOpen(true, {
          state: 'loading',
          tx: null,
          info: `Preparing to buy ${modalLabel}. Checking allowances...`
        })

        const allowance = await setup.getTokenAllowance({
          ...setup,
          tokenAddress: premiumTokenAddress,
          amount: _.get(state, 'premium.value')
        })

        if (_.isNil(allowance) || _.get(allowance, 'isAllowed') === false) {
          updateData({
            state: 'warning',
            info:
              'It seems that our app is having trouble figuring out your ERC20 allowance. Please reload the page to fix this problem. Thank you for your help and sorry for the inconvenince!'
          })
          return
        }

        updateData({
          info: `Buying ${modalLabel}.`
        })

        let rejected = null

        if (exact === 'tokenA') {
          premiumValue = premiumValue * PREMIUM_SLIPPAGE
          await option
            .getPool()
            .buyExactOptions(
              optionsValue,
              premiumValue,
              null,
              (error, transactionHash) => {
                if (error) rejected = error
                updateData({
                  tx: transactionHash
                })
              }
            )
        } else {
          optionsValue = optionsValue * UNDERLYING_SLIPPAGE
          await option
            .getPool()
            .buyEstimatedOptions(
              premiumValue,
              optionsValue,
              null,
              (error, transactionHash) => {
                if (error) rejected = error
                updateData({
                  tx: transactionHash
                })
              }
            )
        }

        if (!_.isNil(rejected)) throw rejected

        refresh()
        updateData({
          state: 'success',
          info: `Bought ${modalLabel}.`
        })

        addToast(`${optionLabel} successfully bought!`, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
      } catch (e) {
        removeAllToasts()

        updateData({
          state: 'error',
          info: `Attempted to buy ${modalLabel}.`
        })

        addToast('Transaction cancelled', {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
        throw e
      }
    },
    [addToast, removeAllToasts, refresh, setOpen, updateData]
  )

  const machine = useAtomicMachine({
    id: 'buy',
    onPrepare,
    onValidate,
    onProcess
  })

  return machine
}

export default {
  useMachine
}
