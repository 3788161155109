import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo
} from 'react'
import { getClient } from '../../apollo/client'
import { USER_ACTIONS, USER_OPTION_ACTIONS } from '../../apollo/queries'

import { useRefresh } from '../../hooks/atoms'
import { useAccount, useNetworkId } from '../../hooks'
import _ from 'lodash'
import BigNumber from 'bignumber.js'
import { macros } from '../../constants'

export const TransactionHistoryContext = createContext(undefined)

export default function Provider ({ children }) {
  const { refresh, version } = useRefresh()

  const historyByAccount = useTransactionHistory(version)
  const historyListByOption = useTransactionHistoryByOption(version)

  return (
    <TransactionHistoryContext.Provider
      value={{ historyByAccount, historyListByOption, version, refresh }}
    >
      {children}
    </TransactionHistoryContext.Provider>
  )
}

export function useTransactionHistoryContext () {
  return useContext(TransactionHistoryContext)
}

function useTransactionHistory (version) {
  const networkId = useNetworkId()
  const { address } = useAccount()
  const [isFinished, setIsFinished] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [pagination, setPagination] = useState(null)
  const [transactions, setTransactions] = useState([])

  const fetchHistory = useCallback(
    async ({ address, pagination }) => {
      try {
        if (_.isNil(address)) return
        setIsLoading(true)
        const query = await getClient(networkId).query({
          query: USER_ACTIONS,
          variables: {
            user: String(address).toLowerCase(),
            pagination
          },
          fetchPolicy: 'no-cache'
        })

        const extra = _.toArray(_.get(query, 'data.actions'))
        if (!_.isNil(_.get(query, 'data.actions')) && extra.length) {
          setTransactions(prev => prev.concat(extra))
          setPagination(
            BigNumber(_.get(extra[extra.length - 1], 'timestamp')).toNumber()
          )
          if (extra.length < macros.HISTORY_PAGINATOR) setIsFinished(true)
        } else {
          setIsFinished(true)
        }
        setIsLoading(false)
      } catch (error) {
        console.error('TransactionHistory', error)
        setIsLoading(false)
      }
    },
    [networkId]
  )

  const more = useCallback(() => {
    if (!_.isNil(address) && !_.isNil(pagination)) {
      fetchHistory({ address, pagination })
    }
  }, [address, pagination, fetchHistory])

  useEffect(() => {
    setTransactions([])
    setIsFinished(false)
    const base = Math.floor(Date.now() / 1000) + 1
    setPagination(base)
    fetchHistory({ address, pagination: base })
  }, [address, version, fetchHistory])

  const result = useMemo(
    () => ({
      transactions,
      more,
      isFinished,
      isLoading
    }),
    [transactions, isFinished, isLoading, more]
  )

  return result
}

function useTransactionHistoryByOption (version) {
  const networkId = useNetworkId()
  const { address } = useAccount()
  const [isLoading, setIsLoading] = useState(true)
  const [transactions, setTransactions] = useState([])

  useEffect(() => {
    setTransactions([])
  }, [version])

  const fetchHistoryForOption = useCallback(
    async ({ address, option }) => {
      try {
        if (_.isNil(address) || _.isNil(option)) return
        setIsLoading(true)
        setTransactions(prev => {
          prev[option] = {
            transactions: [],
            isFinished: false
          }
          return prev
        })

        const query = await getClient(networkId).query({
          query: USER_OPTION_ACTIONS,
          variables: {
            user: String(address).toLowerCase(),
            option: String(option).toLowerCase()
          },
          fetchPolicy: 'no-cache'
        })

        const list = _.toArray(_.get(query, 'data.actions'))

        setTransactions(prev => {
          prev[option] = {
            transactions:
              !_.isNil(_.get(query, 'data.actions')) && list.length ? list : [],
            isFinished: true
          }
          return prev
        })

        setIsLoading(false)
      } catch (error) {
        console.error('TransactionHistory', error)
        setTransactions(prev => {
          prev[option] = {
            transactions: [],
            isFinished: true
          }
          return prev
        })
        setIsLoading(false)
      }
    },
    [networkId]
  )

  const request = useCallback(
    option => {
      fetchHistoryForOption({ address, option })
    },
    [address, fetchHistoryForOption]
  )

  const result = useMemo(
    () => ({
      transactions,
      request,
      isLoading
    }),
    [transactions, request, isLoading]
  )

  return result
}
