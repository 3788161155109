import _ from 'lodash'
import React, { useMemo } from 'react'
import numeral from 'numeral'
import BigNumber from 'bignumber.js'
import IconMarket from '@material-ui/icons/TimelineRounded'
import { useHistory } from 'react-router-dom'
import { layout } from '../../components/shared/Table'
import { types, pages } from '../../constants'
import { useOwnInvestments } from '../option'
import { useMarketPrices } from '../price'
import { interpretTimeWindows, sortOptionsTableByExpiration } from '../../utils'

export default function useOwnInvestTable () {
  const history = useHistory()

  const { list: options, isLoading } = useOwnInvestments()
  const { list: prices } = useMarketPrices()

  const columns = useMemo(
    () => [
      {
        title: 'Option',
        layout: layout.PodPair,
        subtitle: 'Underlying:Collateral',
        weight: 3
      },

      {
        title: 'Strike Price',
        layout: layout.Price,
        subtitle: (
          <>
            Market Price <IconMarket />
          </>
        ),
        weight: 2
      },
      {
        title: 'Position',
        layout: layout.TextDeck,
        subtitle: 'Options sold',
        weight: 2
      },
      {
        title: 'Expiration',
        layout: layout.Timestamp,
        weight: 2
      },
      {
        title: 'Type',
        layout: layout.Text,
        weight: 2
      },
      {
        title: 'Actions',
        layout: layout.Actions,
        weight: 3
      }
    ],
    []
  )

  const instructions = useMemo(
    () => ({
      onRowClick: params => {
        if (_.get(params, 'id')) {
          history.push(pages.transactionInvest.builder(_.get(params, 'id')))
        }
      }
    }),
    [history]
  )

  const rows = useMemo(
    () =>
      options
        .map(option => {
          const underlying = _.get(
            option,
            'underlyingAssetSymbol'
          ).toUpperCase()
          const collateral = _.get(option, 'strikeAssetSymbol').toUpperCase()

          const market = _.get(prices, underlying)

          const amount = _.get(
            options.find(
              investment =>
                _.get(investment, 'address') === _.get(option, 'address')
            ),
            'lockedBalance'
          )
          const times = interpretTimeWindows(option)

          return {
            id: _.get(option, 'address'),
            cells: [
              {
                value: [underlying, collateral],
                type: types.action.invest
              },
              {
                value: numeral(_.get(option, 'strikePrice')).format(
                  '$0.[0000]'
                ),
                market: numeral(market).format('$0.[00]')
              },
              {
                value: `${numeral(amount).format('0,0.[0000]')} ${collateral}`,
                subtitle: `${new BigNumber(amount)
                  .dividedBy(new BigNumber(_.get(option, 'strikePrice')))
                  .toFixed(4)} options`
              },
              {
                value: _.get(option, 'expiration')
              },
              {
                value: 'Put'
              },
              {
                times,
                value: _.get(option, 'address'),
                expiration: _.get(option, 'expiration'),
                amount: new BigNumber(
                  _.get(option, 'lockedBalance')
                ).toNumber(),
                type: types.action.invest,
                source: pages.dashboard.builder()
              }
            ]
          }
        })
        .sort(sortOptionsTableByExpiration),
    [options, prices]
  )

  return {
    data: {
      columns,
      rows,
      instructions,
      /** Cosmetics */
      isLoading,
      empty: 1
    },
    info: {}
  }
}
