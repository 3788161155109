import "normalize.css/normalize.css";
import createMediaQueries from "./util/media-query";

export const sizes = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1280,
};

export const media = createMediaQueries(sizes);
