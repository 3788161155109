import IconDashboard from '@material-ui/icons/DashboardRounded'
import IconInvest from '@material-ui/icons/SwapVerticalCircleRounded'
import IconHedge from '@material-ui/icons/SecurityRounded'
import IconHistory from '@material-ui/icons/HistoryRounded'
import IconPool from '@material-ui/icons/CategoryRounded'
import IconFaucet from '@material-ui/icons/AccountBalanceRounded'

export const tabs = {
  invest: {
    write: 'write',
    resell: 'resell',
    withdraw: 'withdraw'
  },
  hedge: {
    buy: 'buy',
    exercise: 'exercise',
    overview: 'overview'
  },
  pool: {
    add: 'add',
    remove: 'remove',
    mint: 'mint',
    unwind: 'unwind'
  }
}

export const pages = {
  dashboard: {
    route: '/',
    builder: () => '/',
    title: 'Dashboard',
    Icon: IconDashboard,
    depth: 0
  },
  hedge: {
    route: '/buy',
    builder: () => '/buy',
    title: 'Buy',
    Icon: IconHedge,
    depth: 0
  },
  invest: {
    route: '/sell',
    builder: () => '/sell',
    title: 'Sell',
    Icon: IconInvest,
    depth: 0
  },
  history: {
    route: '/activity',
    builder: () => '/activity',
    title: 'Activity',
    Icon: IconHistory,
    depth: 0
  },
  pool: {
    route: '/pool',
    builder: () => '/pool',
    title: 'Pool',
    Icon: IconPool,
    depth: 0
  },
  faucet: {
    route: '/utils',
    builder: () => '/utils',
    title: 'Utilities',
    Icon: IconFaucet
  },
  restricted: {
    route: '/restricted',
    builder: () => '/restricted',
    title: 'Terms of Service restrictions'
  },
  transactionHedge: {
    route: '/buy/:id/',
    builder: (id, tab = tabs.hedge.buy) => `/buy/${id}/#${tab}`,
    title: 'Transaction - Buy',
    Icon: null
  },
  transactionInvest: {
    route: '/sell/:id/',
    builder: (id, tab = tabs.invest.write) => `/sell/${id}/#${tab}`,
    title: 'Transaction - Sell',
    Icon: null
  },
  transactionPool: {
    route: '/pool/:id/',
    builder: (id, tab = tabs.pool.add) => `/pool/${id}/#${tab}`,
    title: 'Transaction - Liquidity Pool',
    Icon: null
  }
}
