import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import IconBuy from '@material-ui/icons/SecurityRounded'
import IconExercise from '@material-ui/icons/FlashOnRounded'
import IconOverview from '@material-ui/icons/UpdateRounded'
import Helmet from '../../../Helmet'
import * as Tabs from '../../Tabs/Hedge'

import { tabs } from '../../../../../constants'
import { Bar, Tab, Loader, Missing } from '../../../../shared/Tabs'
import { useOptionData } from '../../../../../hooks'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding-bottom: ${props => props.theme.sizes.edge};
  padding-top: calc(${props => props.theme.sizes.edge} * 1 / 2);

  ${props => props.theme.medias.medium} {
    padding-bottom: 0;
    padding-top: 2px;
    border-radius: 0;
  }
`

const Content = styled.div`
  width: 100%;
`

function TabsHedge () {
  const { addToast, removeAllToasts } = useToasts()
  const {
    isLoading,
    times,
    item,
    tokenUnderlying,
    tokenCollateral,
    strike
  } = useOptionData()
  const { isExercised, isExpired } = times

  const [locked, fallback] = useMemo(() => {
    if (isExercised) {
      return [[tabs.hedge.buy, tabs.hedge.exercise], tabs.hedge.overview]
    }
    if (!isExercised && isExpired) {
      return [[tabs.hedge.buy, tabs.hedge.overview], tabs.hedge.exercise]
    }
    return [[tabs.hedge.exercise, tabs.hedge.overview], tabs.hedge.buy]
  }, [isExercised, isExpired])

  return (
    <Wrapper>
      <Helmet.TransactionHedge
        data={{ tokenUnderlying, tokenCollateral, strike, times }}
      />
      <Bar
        fallback={fallback}
        supported={Object.values(tabs.hedge)}
        locked={locked}
      >
        <Tab
          Icon={IconBuy}
          title='Buy'
          hash={tabs.hedge.buy}
          isLocked={locked.includes(tabs.hedge.buy)}
          onLockedClick={() => {
            removeAllToasts()
            addToast('Buying is available only before the expiration date.', {
              appearance: 'warning',
              autoDismiss: true,
              autoDismissTimeout: 5000
            })
          }}
        />
        <Tab
          Icon={IconExercise}
          title='Exercise'
          subtitle='After expiration'
          hash={tabs.hedge.exercise}
          isLocked={locked.includes(tabs.hedge.exercise)}
          onLockedClick={() => {
            removeAllToasts()
            addToast(
              'Exercising is available only for 24h after the expiration date.',
              {
                appearance: 'warning',
                autoDismiss: true,
                autoDismissTimeout: 5000
              }
            )
          }}
        />
        {isExercised && (
          <Tab
            Icon={IconOverview}
            title='Overview'
            hash={tabs.hedge.overview}
          />
        )}
      </Bar>
      <Content>
        {!isLoading && _.isNil(item) ? (
          <Missing />
        ) : (
          <>
            {!locked.includes(tabs.hedge.buy) && <Tabs.Buy />}
            {!locked.includes(tabs.hedge.exercise) && <Tabs.Exercise />}
            {!locked.includes(tabs.hedge.overview) && <Tabs.Overview />}
          </>
        )}
      </Content>
      <Loader isActive={isLoading} />
    </Wrapper>
  )
}

export default TabsHedge
