import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import numeral from 'numeral'
import { Emoji } from 'shared'
import {
  useOptionData,
  useOptionPoolPosition,
  useMarketPrices,
  useOwnBalance,
  useOptionABPrice,
  useOptionPoolPriceProperties
} from '../../../../../hooks'
import Item from './Item'
import { TokenMultiDisplay } from '../../../../atoms'
import { macros, pages } from '../../../../../constants'
import { toQuantity } from '../../../../../utils'

const WrapperPartial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding: calc(${props => props.theme.sizes.edge} * 1.5) 0;
  padding-bottom: 0;
`

const Header = styled.div`
  width: 100%;
  padding: calc(${props => props.theme.sizes.edge} * 1.5)
    calc(${props => props.theme.sizes.edge} * 1);
  padding-top: 0;
  padding-bottom: ${props => props.theme.sizes.edge};
`

const Title = styled.p`
  margin: 0;
  font-size: 16pt;
  font-weight: 700;
  color: ${props => props.theme.colors.dark};
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1.5);
`

const Body = styled.div`
  width: 100%;
  padding: 0 calc(${props => props.theme.sizes.edge} * 1);

  &[data-loading='true'] {
    div[data-component='content'] {
      position: relative;
      & > * {
        opacity: 0;
      }
      &:before {
        content: '';
        width: 100px;
        height: 17px;
        position: absolute;
        right: 0;
        z-index: 0;
        border-radius: 4px;
        background-image: linear-gradient(
          -90deg,
          ${props => props.theme.colors.platform} 50%,
          ${props => props.theme.colors.white}
        );
      }
    }
  }
`

const Accented = styled.p`
  & > b {
    color: ${props => props.theme.colors.middle};
    font-weight: 700;
  }
`
const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1px;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.border};
  background: ${props => props.theme.colors.border};
  border-radius: 2px;
  & > * {
    background: ${props => props.theme.colors.white};
  }
`

const Missing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 430px;
  font-size: 40pt;
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.small} {
    padding: calc(${props => props.theme.sizes.edge} * 1) 0;
    border-radius: 0;
  }
`

function InfoMissing () {
  return (
    <Wrapper>
      <Header>
        <Title>Your Position</Title>
      </Header>
      <Body>
        <Missing>
          <Emoji symbol='🛸' label='UFO' />
        </Missing>
      </Body>
    </Wrapper>
  )
}

function PoolInfo () {
  const {
    poolAddress,
    tokenUnderlying,
    tokenCollateral,
    labelStrike,
    liquidity,
    item: option,
    times,
    isLoading
  } = useOptionData()

  const { value: balanceOptions } = useOwnBalance(_.get(option, 'address'))
  const { value: balanceCollateral } = useOwnBalance(
    _.get(tokenCollateral, 'address')
  )

  const { value: optionPrice } = useOptionABPrice({ option })

  const {
    value: position,
    isLoading: isPositionLoading
  } = useOptionPoolPosition(option)

  const { value: priceProperties } = useOptionPoolPriceProperties(option)

  const { list: prices } = useMarketPrices()

  const [providedOptions, providedCollateral] = [
    numeral(_.get(position, '[0]') || 0).format('0,0.[0000]'),
    numeral(_.get(position, '[1]') || 0).format('0,0.[0000]')
  ]

  const restrictedPrice = useMemo(
    () => [macros.RESTRICTED_PREMIUM, '0'].includes(String(optionPrice)),
    [optionPrice]
  )

  const market = _.get(prices, _.get(tokenUnderlying, 'symbol'))

  const available = useMemo(() => {
    const options = toQuantity(
      numeral(balanceOptions).format('0,0.[0000]'),
      'option'
    )
    const collateral = `${numeral(balanceCollateral).format(
      '0,0.[0000]'
    )} ${_.get(tokenCollateral, 'symbol')}`
    return `${options} and ${collateral}`
  }, [tokenCollateral, balanceCollateral, balanceOptions])

  const provided = useMemo(() => {
    const options = toQuantity(providedOptions, 'option')
    const collateral = `${providedCollateral} ${_.get(
      tokenCollateral,
      'symbol'
    )}`
    return (
      <>
        {options} <b>and</b> {collateral}
      </>
    )
  }, [tokenCollateral, providedOptions, providedCollateral])

  const pooled = useMemo(() => {
    const options = `${numeral(liquidity[0]).format('0,0.[0000]')} options`
    const collateral = `${numeral(liquidity[1]).format('0,0.[0000]')} ${_.get(
      tokenCollateral,
      'symbol'
    )}`
    return (
      <>
        {options} <b>and</b> {collateral}
      </>
    )
  }, [tokenCollateral, liquidity])

  const poolSize = useMemo(
    () =>
      _.attempt(() => {
        return new BigNumber(liquidity[1])
          .plus(
            new BigNumber(liquidity[0]).multipliedBy(
              new BigNumber(restrictedPrice ? 0 : optionPrice)
            )
          )
          .toNumber()
      }),
    [liquidity, optionPrice, restrictedPrice]
  )

  if (!isLoading && _.isNil(option)) return <InfoMissing />

  return (
    <Wrapper>
      <Section>
        <Header>
          <Title
            data-option={_.get(option, 'address') || ''}
            data-pool={String(poolAddress || '')}
          >
            Position
          </Title>
        </Header>
        <Body data-loading={isLoading || isPositionLoading}>
          <Table>
            <Item
              columns={2}
              Icon={pages.hedge.Icon}
              title='Available for providing'
              value={available}
              help='Idle funds you have in your wallet that are eligible for the pool.'
            />
            <Item
              columns={2}
              Icon={pages.pool.Icon}
              title='Your pool position'
              help='The amount of options and tokens you own in the pool. These are earning fees for you. Due to trading, these amounts will differ from what you initially provided.'
              hasAccent
            >
              <Accented>{provided}</Accented>
            </Item>
          </Table>
        </Body>
      </Section>
      <Section>
        <Header>
          <Title>Pool Info</Title>
        </Header>
        <Body data-loading={isLoading}>
          <Table>
            <Item
              title='Option Type'
              value='PUT'
              help='This option type gives buyers the right to sell underlying assets at an agreed strike price.'
              hasDim
            />
            <Item
              title='Asset Pair'
              help='A pair of underlying asset and collateral assets.'
              hasDim
            >
              <TokenMultiDisplay
                tokens={isLoading ? [] : [tokenUnderlying, tokenCollateral]}
                isSelfPadded={false}
                isMini
              />
            </Item>
            <Item
              title='Exercise Window'
              help='After expiry, during this window, you will be able to exercise your options.'
              value={_.get(times, 'windowTime')}
              hasDim
            />
            <Item
              title='Expiration Date'
              help={`At this moment, the exercise window will open for buyers. Expiration moment: ${_.get(
                times,
                'expirationFromNow'
              )}.`}
              value={_.get(times, 'expiration')}
              hasDim
            />
            <Item
              title='Strike Price'
              value={labelStrike}
              help='Price agreed upon for the underlying asset.'
              hasDim
            />
            <Item
              title={`Market Price (${
                tokenUnderlying ? _.get(tokenUnderlying, 'symbol') : '~'
              })`}
              help='The current spot price of the asset.'
              value={numeral(market).format('$0.[00]')}
              hasDim
            />
            <Item
              columns={1}
              title='Option Price'
              help={
                restrictedPrice
                  ? 'Due to price being close to 0, some actions will be unavailable (Buying, Selling, Providing, Minting and Unminting)'
                  : 'Selling price (premium) for 1 option.'
              }
              hasDim
            >
              {_.get(times, 'isExercised') ? (
                <p>
                  <i>Series ended</i>
                </p>
              ) : restrictedPrice ? (
                <p>
                  <i>Price too close to $0</i>
                </p>
              ) : (
                <p>{numeral(optionPrice).format('$0.[0000]')} / option</p>
              )}
            </Item>
            <Item
              title='Implied Volatility'
              help='The current implied volatility.'
              value={`${new BigNumber(_.get(priceProperties, 'currentIV'))
                .dividedBy(10 ** 16)
                .toFixed(3)}%`}
              hasDim
            />
            <Item
              columns={2}
              title='Pool liquidity'
              help={numeral(poolSize).format('$0,0.[000]')}
              isWrapper
              hasDim
            >
              <p>{pooled}</p>
            </Item>
          </Table>
        </Body>
      </Section>
    </Wrapper>
  )
}

export default PoolInfo
