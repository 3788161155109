const fallback =
  '-apple-system, BlinkMacSystemFont, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, Arial, sans-serif';

const primary = `proxima-nova, ${fallback}`;
const secondary = `proxima-nova, ${fallback}`;

const fonts = {
  fallback,
  primary,
  secondary,
};

export default fonts;
