import { links } from '../constants';

export async function sendContactForm({ email }) {
  const endpoint = new URL(`${links.newsletter.endpoint}`);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      email,
    }),
  };

  return fetch(endpoint, requestOptions);
}
