import _ from 'lodash'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import IconSearch from '@material-ui/icons/SearchRounded'
import IconClear from '@material-ui/icons/ClearRounded'
import reducers from '../../../reducers'
import { analytics } from '../../../vendors'
import { useSearchQuery } from '../../../hooks'

import Dropdown from './Dropdown'
import QueryLabel from './QueryLabel'

const WrapperPartial = styled.div`
  position: relative;
  flex: 1;
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: auto;
  height: 56px;
  max-width: 440px;
  width: 100%;
  border-radius: 28px;
  padding-left: calc(${props => props.theme.sizes.edge} * 1);
  padding-right: calc(${props => props.theme.sizes.edge} * 1 / 2);
  background-color: ${props => props.theme.colors.white};
  margin-right: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  transition: box-shadow 250ms, border 150ms;
  cursor: pointer;

  overflow: hidden;

  & > svg {
    font-size: 18pt;
    color: ${props => props.theme.colors.content};
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: ${props => props.theme.sizes.edge};
  flex: 1;
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: calc(${props => props.theme.sizes.edge} * 1 / 2);
  & > svg {
    color: ${props => props.theme.colors.contentMedium};
    font-size: 16pt;
  }
`

const Clear = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  border-radius: 22px;
  background-color: ${props => props.theme.colors.platformMedium};
  border: 1px solid ${props => props.theme.colors.content};
  position: absolute;
  right: 6px;
  z-index: 100;
  cursor: pointer;
  & > svg {
    color: ${props => props.theme.colors.dark};
    font-size: 14pt;
  }

  &:hover,
  &:active {
    border: 1px solid ${props => props.theme.colors.dark};
  }
`

const Wrapper = styled(WrapperPartial)`
  &[data-active='true'] {
    & > ${Box} {
      border: 1px solid ${props => props.theme.colors.content};
      transition: box-shadow 250ms, border 150ms;
    }
  }

  &[data-active='false'] {
    &:hover,
    &:active {
      & > ${Box} {
        box-shadow: ${props => props.theme.styles.boxShadowHover};
        transition: box-shadow 250ms;
      }
    }
  }

  ${props => props.theme.medias.medium} {
    flex: none;
    &[data-active='true'] {
      & > ${Box} {
        border: none;
      }
    }
    ${Clear} {
      display: none;
    }
    & > ${Box} {
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 50px;
      border-radius: 25px;
      margin: 4px;

      border: none;
      padding: 0;
      min-width: 0;
      box-shadow: none !important;

      & > ${Content} {
        display: none;
        padding: 0;
      }

      ${Icon} {
        margin: 0;
      }
    }
  }
`

function Search () {
  const reducer = reducers.search.useReducer()
  const { modelFrom, modelTo, refresh } = useSearchQuery()
  const [isActive, setIsActive] = useState(false)

  const { travel, search: query } = useMemo(() => modelFrom(reducer), [
    reducer,
    modelFrom
  ])

  const onSave = useCallback(() => {
    if (!_.isNil(travel)) _.attempt(() => travel())
    setIsActive(false)
    analytics.track(e => e.searchTrigger, { query })
  }, [travel, query, setIsActive])

  const onRefresh = useCallback(() => {
    refresh(reducer)
    setIsActive(false)
    analytics.track(e => e.searchClose)
  }, [refresh, reducer, setIsActive])

  /**
   *
   * Initialize the reducer state from the URL Query String
   * [componentDidMount] - trigger only at first render
   *
   */
  useEffect(() => {
    modelTo(reducer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper data-component='search' data-active={isActive}>
      <Box
        onClick={() => {
          setIsActive(true)
          analytics.track(e => e.searchOpen)
        }}
      >
        <Icon>
          <IconSearch />
        </Icon>
        <Content>
          <QueryLabel state={reducer.state} />
        </Content>
        {(isActive || !_.isNilOrEmptyString(query)) && (
          <Clear
            onClick={e => {
              onRefresh()
              e.stopPropagation()
            }}
          >
            <IconClear />
          </Clear>
        )}
      </Box>
      <Dropdown
        isActive={isActive}
        reducer={reducer}
        actions={{ onRefresh, onSave: !_.isNil(travel) ? onSave : null }}
      />
    </Wrapper>
  )
}

export default Search
