import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import numeral from 'numeral'
import BigNumber from 'bignumber.js'
import Section from '../../../../shared/Section'
import Card from './Card'

import { useOwnPools } from '../../../../../hooks'

const ContentPartial = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ${props => props.theme.sizes.layoutEdge};
`

const Cards = styled.div`
  grid-column: span 4;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${props => props.theme.sizes.edge};
  width: 100%;
`
const Content = styled(ContentPartial)`
  ${props => props.theme.medias.medium} {
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});

    ${Cards} {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`

function Overview () {
  const { list: options } = useOwnPools()
  // const { list: investments } = useOwnInvestments()

  const locked = useMemo(() => {
    return options
      .map(option => {
        // const investment = investments.find(
        //   i => _.get(i, 'address') === _.get(option, 'address')
        // )

        // const balance = new BigNumber(_.get(investment, 'lockedBalance') || 0)
        const strike = new BigNumber(_.get(option, 'strikePrice') || 0)
        const position = [
          new BigNumber(_.get(option, 'poolData.position[0]') || 0),
          new BigNumber(_.get(option, 'poolData.position[1]') || 0)
        ]

        const result = new BigNumber(0)
          .plus(position[0].multipliedBy(strike))
          .plus(position[1])

        return result
      })
      .reduce((acc, cur) => acc.plus(cur), new BigNumber(0))
  }, [options])

  return (
    <Section title='Overview'>
      <Content>
        <Cards>
          <Card
            title='Provided capital'
            description='Total value of capital locked and provided as liquidity.'
            value={numeral(locked).format('$0,0.[0000]')}
            isLoading={false}
          />
        </Cards>
      </Content>
    </Section>
  )
}

export default Overview
