import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo
} from 'react'
import Web3 from 'web3'
import * as config from './config'
import { useLocalStorage } from '../../hooks'
import { macros } from '../../constants'

export const Context = createContext({
  account: null,
  modal: null,
  provider: null,
  connect: () => {},
  web3: null
})

export default function Provider ({ children }) {
  const [modal, setModal] = useState()
  const [provider, setProvider] = useState()
  const [web3Instance, setWeb3Instance] = useState(new Web3())
  const [account, setAccount] = useState()

  const [cached, setForcedCached] = useLocalStorage(
    macros.WEB3_MODAL_IDENTIFIER,
    null
  )

  const reset = useCallback(() => {
    setAccount(null)
    setProvider(null)
    setWeb3Instance(null)
    setForcedCached(null)
  }, [setProvider, setWeb3Instance, setAccount, setForcedCached])

  config.useWeb3Modal(setModal)
  config.useWeb3Provider(modal, setProvider, setWeb3Instance, reset)
  config.useWeb3Account(web3Instance, setAccount)

  const context = useMemo(
    () => ({
      account,
      modal,
      provider,
      connect: () =>
        config.attemptToConnect(modal, setProvider, setWeb3Instance, reset),
      disconnect: () => config.attemptToDisconnect(modal, reset),
      web3: web3Instance,
      cached
    }),
    [account, modal, provider, web3Instance, cached, reset]
  )

  return <Context.Provider value={context}>{children}</Context.Provider>
}

export function useWeb3Context () {
  return useContext(Context)
}
