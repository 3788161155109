import _ from 'lodash'
import BigNumber from 'bignumber.js'
import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import Position from '../../../../../shared/Position'
import PositionStatus from '../../../../../shared/PositionStatus'
import SpecialButton from '../../../../../shared/SpecialButton'
import { Structure, Section, Summary } from '../../../../../shared/Tabs'
import {
  Form as FormPartial,
  Step as StepPartial,
  Label,
  Input
} from '../../../../../shared/Form'

import { tabs, types } from '../../../../../../constants'
import reducers from '../../../../../../reducers'
import machines, { guards } from '../../../../../../machines'
import {
  useOwnBalance,
  useOptionData,
  useFormValidator,
  useFormAssetAllowance,
  useTokenAllowanceForLater
} from '../../../../../../hooks'
import { analytics } from '../../../../../../vendors'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${props => props.theme.medias.minSmall} {
    div[data-step='actions'] {
      & > div:first-child {
        min-width: 238px;
      }
    }
  }
`
const Form = styled(FormPartial)`
  max-width: 500px;
`

const Info = styled.p``

const Step = styled(StepPartial)`
  ${Info} {
    min-width: 110px;
  }
`
function initialize ({
  elements,
  dispatch,
  balanceOptions,
  balanceUnderlying,
  tokenUnderlying,
  tokenCollateral
}) {
  const max = BigNumber.min(balanceOptions, balanceUnderlying)

  dispatch([], 'RESET', [elements.allowance])
  dispatch([
    elements.options,
    {
      max: max.isGreaterThan(0.000001) ? max.toNumber() : 0,
      token: [
        _.get(tokenUnderlying, 'symbol'),
        _.get(tokenCollateral, 'symbol')
      ]
    }
  ])

  dispatch([elements.underlying, { token: _.get(tokenUnderlying, 'symbol') }])

  dispatch([elements.collateral, { token: _.get(tokenCollateral, 'symbol') }])
}

function Exercise () {
  const {
    tokenUnderlying,
    tokenCollateral,
    strike,
    version,
    item: option,
    isLoading: isOptionLoading
  } = useOptionData()

  const {
    value: balanceOptions,
    isLoading: isBalanceOptionsLoading
  } = useOwnBalance(_.get(option, 'address'))

  const {
    value: balanceUnderlying,
    isLoading: isBalanceUnderlyingLoading
  } = useOwnBalance(_.get(option, 'underlyingAsset'))

  const { elements, state, dispatch } = reducers.exercise.useReducer()
  const machine = machines.exercise.useMachine()
  const setup = useTokenAllowanceForLater()

  /**
   *
   * Reducer state updaters: Form values
   *
   */

  const doAllowanceUpdate = useCallback(
    ({ isAllowed, isLoading }) =>
      dispatch([elements.allowance, { token: isAllowed, isLoading }]),
    [elements, dispatch]
  )

  const onChangeAmount = useCallback(
    options => {
      dispatch([
        elements.options,
        {
          value: options,
          warning:
            _.toString(options) !== ''
              ? guards.isAmountValid({
                value: options,
                max: balanceOptions
              })
              : null
        }
      ])

      dispatch([
        elements.underlying,
        {
          value: options
        }
      ])

      dispatch([
        elements.collateral,
        {
          value: new BigNumber(options).multipliedBy(strike).toNumber() || null
        }
      ])
    },
    [dispatch, elements, balanceOptions, strike]
  )

  const onTransact = useCallback(() => {
    analytics.track(e => e.transactionExerciseTrigger)
    machine.send(machine.events.save, {
      payload: {
        state,
        option,
        setup
      }
    })
  }, [machine, state, option, setup])

  /**
   *
   * Automated effects
   *
   */

  const {
    doApprove: doAllowanceApprove,
    doRefresh: doAllowanceRefresh
  } = useFormAssetAllowance({
    amount: _.get(state, 'underlying.value'),
    tokenAddress: _.get(option, 'underlyingAsset'),
    onUpdate: doAllowanceUpdate,
    spender: option
  })

  useEffect(() => {
    if (
      !isOptionLoading &&
      !isBalanceOptionsLoading &&
      !isBalanceUnderlyingLoading
    ) {
      initialize({
        balanceOptions,
        balanceUnderlying,
        elements,
        dispatch,
        tokenUnderlying,
        tokenCollateral,
        version
      })

      doAllowanceRefresh()
    }
  }, [
    balanceOptions,
    balanceUnderlying,
    elements,
    dispatch,
    tokenUnderlying,
    tokenCollateral,
    version,

    doAllowanceRefresh,

    isOptionLoading,
    isBalanceOptionsLoading,
    isBalanceUnderlyingLoading
  ])

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  return (
    <Structure hash={tabs.hedge.exercise}>
      <Wrapper>
        <Section title='Your position'>
          <Position action={types.action.hedge} hideIfEmpty />
        </Section>
        <Section title='Status'>
          <PositionStatus action={types.action.hedge} />
        </Section>
        <Section
          title='Position'
          isContained
          isLoading={isOptionLoading}
          isDisabled={[
            machine.states.validate,
            machine.states.process
          ].includes(machine.current.value)}
        >
          <Form>
            <Step>
              <Label>Step 1. Amount to exercise</Label>
              <Input.Amount
                {...state.options}
                placeholder='Enter amount of options'
                onChange={e => onChangeAmount(_.get(e, 'target.value'))}
              />
              <Step>
                <Info style={{ whiteSpace: 'nowrap' }}>Enable selling of</Info>
                <Input.Amount
                  {...state.underlying}
                  placeholder='0'
                  isViewOnly
                />
              </Step>
              <Step>
                <Info style={{ whiteSpace: 'nowrap' }}>In exchange of</Info>
                <Input.Amount
                  {...state.collateral}
                  placeholder='0'
                  isViewOnly
                />
              </Step>
            </Step>
            <Summary
              index={2}
              context={tabs.hedge.exercise}
              data={{
                tokenUnderlying,
                tokenCollateral,
                strike,
                max: balanceOptions
              }}
              allow={
                <SpecialButton.AllowToken
                  amount={_.get(state, 'underlying.value')}
                  title={`Allow ${_.get(tokenUnderlying, 'symbol')}`}
                  isAllowed={_.get(state, 'allowance.token')}
                  isLoading={_.get(state, 'allowance.isLoading')}
                  onApprove={doAllowanceApprove}
                />
              }
              transact={
                <SpecialButton.Transact
                  action={types.action.hedge}
                  title='Exercise Options'
                  isDisabled={!isFormValid}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </Section>
      </Wrapper>
    </Structure>
  )
}

export default Exercise
