import React from 'react'
import styled from 'styled-components'
import Section from '../../../shared/Section'
import RestrictedNetwork from '../RestrictedNetwork'

const Content = styled.div`
  width: 100%;
`

function Invest () {
  return (
    <Section
      title='Sell Options'
      description='Sell European, fully collateralized options. Please note that (for certain options series) it will be possible to use aTokens as collateral in order to increase your earnings.'
    >
      <Content>
        <RestrictedNetwork />
      </Content>
    </Section>
  )
}

export default Invest
